import React, { useEffect } from 'react'
import { useState } from 'react'
import * as Notify from '../../../components/Notification/Notifications'
import { connect } from 'react-redux'
import Notification from '../../../components/Notification/Notifications'
import moment from "moment";
import ProfileTable from '../ProductProfileTable/ProfileTable'
import { PROFILE_PAGE } from '../../../constants/routeConstants'
import ColumnFilter from '../../UserManagement/ColumnFilter'
import { updateArticleName } from "../../../utils/filterLevelMapping"
import NumberRangeColumnFilter from '../../../components/Filters/NumberRangeColumnFilter'
import { numberUSFormatting } from '../../../utils/formatters/valueFormatters'

function SelectProducts(props) {

    const [RTinstance, setRTinstance] = useState(null)
    const [showButton, setShowButton] = useState(true)
    const [baseTableData, setBaseTableData] = useState([])
    const [levelFilters,setLevelFilters] = useState({}) 
    // const [isEditing, setIsEditing] = useState(false)
    // const [selectedRows, setSelectedRows] = useState({})

    useEffect(() => {
        if(Object.keys(props.existingData).length && props.isEdit){
            let existingData = props.existingData 
            let savedData  = props.existingData?.savedData
            setBaseTableData(existingData?.articleData) 
            setLevelFilters({
                profileName:savedData?.profileName,
                profileDescription:savedData?.profileName,
                level1:savedData?.level1,
                level2:savedData?.level2,
                days:savedData?.days,
                min_date: savedData?.min_date ? moment(savedData?.min_date).format('YYYY-MM-DD') : null,
                max_date: savedData?.max_date ? moment(savedData?.max_date).format('YYYY-MM-DD') : null,
                static_dynamic: savedData?.static_dynamic,
                min_price: savedData?.min_price,
                max_price: savedData?.max_price,
                color_description: (savedData?.color_description?.length ? savedData?.color_description?.map(val => ({
                    label:val,
                    value:val
                })):[]),
                season_code: savedData?.season_code,
                level3: savedData?.level3,
                level4: savedData?.level4,
                sales_attribute: savedData?.sales_attribute
               })
        }
        else{
            setBaseTableData([])
            setLevelFilters({})
        }
    },[props.existingData,props.isEdit])

    useEffect(() => {
        if(props?.selectProductsData?.length >= 0){
            setBaseTableData(props?.selectProductsData)
        }    
    },[props?.selectProductsData])

    // useEffect(() => {
    //     // if(props.isEdit){
    //         setIsEditing(false)
    //     // }
    // },[props.isEdit])

    const prepareReq = () => {
        let l_product_code = []
        let l_product_code_row = []
        Object.keys(RTinstance.state.selectedRowIds).forEach((rowId) => {
            l_product_code.push(RTinstance.preFilteredRowsById[rowId].original.article)
            l_product_code_row.push(RTinstance.preFilteredRowsById[rowId].original)
          });
        if(l_product_code.length){
        //    setSelectedRows(RTinstance?.state?.selectedRowIds)
           setShowButton(false)
        //    setIsEditing(false)
           props.prepareReq({
            ...levelFilters,
            product_code: l_product_code,
            articleData: l_product_code_row
           },'selectProduct')
            console.log(l_product_code)
        }
        else{
            Notify.error("Select Atleast one Product!!");
        }
    }
    

    const maxWidth = '100%'
    const userCreatedProfileColumns = React.useMemo(() => [
        {
            Header: `${updateArticleName(false)} ID`,
            accessor: 'article',
            Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
            filter: 'bulkFilterCommaSeperated',
        },
        {
            Header: 'Style ID',
            accessor: 'style_code',
            Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
            filter: 'bulkFilterSpaceSeperated',  
        },
        {
            Header: 'Color ID',
            accessor: 'color_code',
            Filter: ColumnFilter
        },
        {
            Header: 'Style Description',
            accessor: 'style_name',
            width:300,
            Filter: ColumnFilter
        },
        {
            Header: 'Global Color',
            accessor: 'color_description',
            width:200,
            Filter: ColumnFilter
        },
        {
            Header: "Style Color",
            accessor: "original_color",
            width: 250,
            Filter: ColumnFilter
        },
        {
            id: "contribution",
            Header: 'Contribution',
            accessor: row => Number(row.contribution)?(Number(row.contribution)*100).toFixed(1) + ' %': '-',
            Filter: ColumnFilter
        },
        {
            Header: 'Sales Qty',
            accessor: 'qty',
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        }
    ])
    
    const marginStyle = {
        minWidth:'50rem', 
        maxWidth:'fit-content',
        margin: '4rem auto'
      }

    // const shallowEqual = (object1, object2) => {
    //     const keys1 = Object.keys(object1);
    //     const keys2 = Object.keys(object2);
      
    //     if (keys1.length !== keys2.length) {
    //       return false;
    //     }
      
    //     for (let key of keys1) {
    //       if (object1[key] !== object2[key]) {
    //         return false;
    //       }
    //     }
      
    //     return true;
    // }

    const handleInstanceChange = (instance) => {
        setRTinstance(instance) 
        // setIsEditing(true) 
        // console.log(selectedRows,'old')
        // console.log(instance?.state?.selectedRowIds,'new')
        // let compare = shallowEqual(selectedRows,instance?.state?.selectedRowIds)
        // console.log(compare)
        // if(!compare){
        //     props.onChanged('selectProduct')   
        // }
    }

    return (
        <React.Fragment>
        {/* <Notification /> */}
             <div className="container__header">
                        <h1 className="fnt-lg fnt-bold">Select Products</h1>
              </div>
                    <div className="container__body">
                    <div className="select__products">
                        <ProfileTable
                            style={marginStyle}
                            baseTableColumns = {userCreatedProfileColumns}
                            baseTableData = {baseTableData}
                            baseTableError = {props.selectProductsError}
                            baseTableloading = {props.selectProductsLoading}
                            keyRT="ProductProfileTable"
                            features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                            sortBy={[{ id: 'contribution', desc: true }]}
                            getInstanceOnMount={handleInstanceChange}
                        />
                    </div>
                    </div>
                    {(showButton) ? <div style= {{margin:'auto', marginTop:'3rem'}} className="create__edit_product_profile_proceed__button">
                        <button onClick = {prepareReq} className="btn btn-primary" title="Proceed">Proceed</button>
                        <button onClick = {() => props.isProductConfig ? props.goBackToCreateConfig() : props.history.push(`${PROFILE_PAGE}`)} className="btn btn-primary" title="Back">Back</button>
                        </div>: null}
        </React.Fragment>
    )
}

const mapStateToProps = ({ productProfile }) => ({
    selectProductsData: productProfile.productListContributionData,
    selectProductsLoading: productProfile.productListContributionLoading,
    selectProductsError: productProfile.productListContributionError
  });


export default connect(mapStateToProps, null)(SelectProducts)
