import * as actionTypes from "../Actions/ActionTypes"
import { productStatusInitialState } from "./InitialState"
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export default {
    productStatus: handleActions(
        {
            [actionTypes.FETCH_FILTER_SUCCESS]: (state, payload) => {
                switch (payload.key) {
                    case "department":
                        return immutable(state, {
                            departmentOptions: {
                                $set:
                                    payload && payload.data.departmentOptions
                                        ? payload.data.departmentOptions
                                        : state.departmentOptions,
                            },
                            genderOptions: [],
                            rbuOptions: [],
                            dcsOptions: [],
                            level5Options: [],
                            level6Options: [],
                            level7Options: [],
                            styleOptions: [],
                            colorOptions: [],
                            sizeOptions: [],
                            productTypeOptions: [],
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "gender":
                        return immutable(state, {
                            genderOptions: {
                                $set:
                                    payload && payload.data.genderOptions
                                        ? payload.data.genderOptions
                                        : state.genderOptions,
                            },
                            rbuOptions: {
                                $set:
                                    payload && payload.data.rbuOptions
                                        ? payload.data.rbuOptions
                                        : state.rbuOptions,
                            },
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            sizeOptions: {
                                $set:
                                    payload && payload.data.sizeOptions
                                        ? payload.data.sizeOptions
                                        : state.sizeOptions,
                            },
                            productTypeOptions: {
                                $set:
                                    payload && payload.data.productTypeOptions
                                        ? payload.data.productTypeOptions
                                        : state.productTypeOptions,
                            },

                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "sub_category":
                        return immutable(state, {
                            rbuOptions: {
                                $set:
                                    payload && payload.data.rbuOptions
                                        ? payload.data.rbuOptions
                                        : state.rbuOptions,
                            },
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            sizeOptions: {
                                $set:
                                    payload && payload.data.sizeOptions
                                        ? payload.data.sizeOptions
                                        : state.sizeOptions,
                            },
                            productTypeOptions: {
                                $set:
                                    payload && payload.data.productTypeOptions
                                        ? payload.data.productTypeOptions
                                        : state.productTypeOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "dcs":
                        return immutable(state, {
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            sizeOptions: {
                                $set:
                                    payload && payload.data.sizeOptions
                                        ? payload.data.sizeOptions
                                        : state.sizeOptions,
                            },

                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "l10":
                            return immutable(state, {
                              genderOptions: {
                                $set:
                                  payload && payload.data.genderOptions
                                    ? payload.data.genderOptions
                                    : state.genderOptions,
                              },
                              filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                            });
                    case "l11":
                            return immutable(state, {
                              rbuOptions: {
                                $set:
                                  payload && payload.data.rbuOptions
                                    ? payload.data.rbuOptions
                                    : state.rbuOptions,
                              },
                              filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                            });
                    case "level5":
                        return immutable(state, {
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            sizeOptions: {
                                $set:
                                    payload && payload.data.sizeOptions
                                        ? payload.data.sizeOptions
                                        : state.sizeOptions,
                            },

                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "level6":
                        return immutable(state, {
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            sizeOptions: {
                                $set:
                                    payload && payload.data.sizeOptions
                                        ? payload.data.sizeOptions
                                        : state.sizeOptions,
                            },

                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "level7":
                        return immutable(state, {
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            sizeOptions: {
                                $set:
                                    payload && payload.data.sizeOptions
                                        ? payload.data.sizeOptions
                                        : state.sizeOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });        
                    case "style_name":
                        return immutable(state, {
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },


                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "color":
                        return immutable(state, {
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },

                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "product_type":
                        return immutable(state, {
                            productTypeOptions: {
                                $set:
                                    payload && payload.data.productTypeOptions
                                        ? payload.data.productTypeOptions
                                        : state.productTypeOptions,
                            },

                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                }
            },

            [actionTypes.GET_PRODUCT_STATUS_DATA]: (state) => {
                return immutable(state, {
                    dataLoading: { $set: true },
                    data: { $set: [] },
                    dataError: { $set: null },
                    // articleData: { $set: [] },
                    // articleDataLoading: { $set: false },
                    // articleDataError: { $set: null }
                })
            },
            [actionTypes.GET_PRODUCT_STATUS_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    dataLoading: { $set: false },
                    data: { $set: (payload.data && payload.data) || [] },
                    dataError: { $set: null },
                })
            },
            [actionTypes.GET_PRODUCT_STATUS_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    dataLoading: { $set: false },
                    data: { $set: [] },
                    dataError: { $set: (payload && payload.message) || null },
                })
            },

            [actionTypes.GET_ARTICLE_CONFIG_DATA]: (state) => {
                return immutable(state, {
                    articleDataLoading: { $set: true },
                    createConfigDataSuccess: {$set: false },
                    dcDataLoading:{$set:[]},
                    articleData: { $set: [] },
                    articleDataError: { $set: null },
                    productConfigNextIndex: { $set: 0 },
                    checkAllNextIndex: { $set: 0 },
                    checkAllTotalCount: { $set: 0 }
                })
            },
            [actionTypes.GET_ARTICLE_CONFIG_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    articleDataLoading: { $set: false },
                    createConfigDataSuccess: {$set: false },
                    articleData: { $set: (payload.data && payload.data) || [] },
                    articleDataError: { $set: null },
                    productConfigTotalCount: { $set: (payload && payload.totalCount) || 0 },
                    productConfigNextIndex: { $set: (payload && payload.nextIndex) || 0 }
                })
            },
            [actionTypes.GET_ARTICLE_CONFIG_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    articleDataLoading: { $set: false },
                    articleData: { $set: [] },
                    articleDataError: { $set: (payload && payload.message) || null },
                })
            },

            [actionTypes.GET_STORE_GROUP_DATA]: (state) => {
                return immutable(state, {
                    storeGroupDataLoading: { $set: true },
                    createConfigDataSuccess:{$set:false},
                    storeGroupData: { $set: [] },
                    storeGroupDataError: { $set: null },
                })
            },
            [actionTypes.GET_STORE_GROUP_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    storeGroupDataLoading: { $set: false },
                    storeGroupData: { $set: (payload.data && payload.data) || [] },
                    storeGroupDataError: { $set: null },
                })
            },
            [actionTypes.GET_STORE_GROUP_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    storeGroupDataLoading: { $set: false },
                    storeGroupData: { $set: [] },
                    storeGroupDataError: { $set: (payload && payload.message) || null },
                })
            },

            [actionTypes.DELETE_CONFIG]: (state, payload) => {
                return immutable(state, {
                    deleteConfigDataLoading: { $set: true },
                    deleteConfigData: { $set: {} },
                    deleteConfigDataError: { $set: null },
                })
            },
            [actionTypes.DELETE_CONFIG_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    deleteConfigDataLoading: { $set: false },
                    deleteConfigData: { $set: (payload.data && payload.data) || {} },
                    deleteConfigDataError: { $set: null },
                })
            },
            [actionTypes.DELETE_CONFIG_ERROR]: (state, payload) => {
                return immutable(state, {
                    deleteConfigDataLoading: { $set: false },
                    deleteConfigData: { $set: {} },
                    deleteConfigDataError: { $set: (payload && payload.error) || null },
                })
            },

            [actionTypes.GET_MISSING_CONFIG]: (state, payload) => {
                return immutable(state, {
                    noConfigLoading: { $set: true },
                    noConfigData: { $set: [] },
                    noConfigError: { $set: null },
                })
            },
            [actionTypes.GET_MISSING_CONFIG_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    noConfigLoading: { $set: false },
                    noConfigData: { $set: (payload.data && payload.data) || [] },
                    noConfigError: { $set: null },
                })
            },
            [actionTypes.GET_MISSING_CONFIG_ERROR]: (state, payload) => {
                return immutable(state, {
                    noConfigLoading: { $set: false },
                    noConfigData: { $set: [] },
                    noConfigError: { $set: (payload && payload.error) || null },
                })
            },

            [actionTypes.CREATE_CONFIG]: (state, payload) => {
                return immutable(state, {
                    createConfigDataLoading: { $set: true },
                    createConfigDataSuccess:{$set:false},
                    createConfigData: { $set: {} },
                    createConfigDataError: { $set: null },
                    noArticleError: { $set: null }
                })
            },
            [actionTypes.CREATE_CONFIG_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    createConfigDataLoading: { $set: false },
                    createConfigDataSuccess:{$set:true},
                    createConfigData: { $set: (payload.data && payload.data) || {} },
                    createConfigDataError: { $set: null },
                    noArticleError: { $set: null }
                })
            },
            [actionTypes.CREATE_CONFIG_ERROR]: (state, payload) => {
                return immutable(state, {
                    createConfigDataLoading: { $set: false },
                    createConfigDataSuccess:{$set:false},
                    createConfigData: { $set: {} },
                    createConfigDataError: { $set: (payload && payload.error) || null },
                })
            },


            [actionTypes.SAVE_FILTER]: (state, payload) => {
                return immutable(state, {
                    selectedFilters: { $set: payload.payload },
                })
            },

            [actionTypes.RESET_FILTERS_DATA]: (state) => {
                return immutable(state, {
                    dataLoading: { $set: false },
                    data: { $set: [] },
                    dataError: { $set: null },
                    articleData: { $set: [] },
                    articleDataLoading: { $set: false },
                    articleDataError: { $set: null },
                    storeGroupData: { $set: {} },
                    storeGroupDataLoading: { $set: false },
                    storeGroupDataError: { $set: null },
                    genderOptions: { $set: [] },
                    rbuOptions: { $set: [] },
                    dcsOptions: { $set: [] },
                    level5Options: { $set: [] },
                    level6Options: { $set: [] },
                    level7Options: { $set: [] },
                    styleOptions: { $set: [] },
                    colorOptions: { $set: [] },
                    sizeOptions: { $set: [] },
                    productTypeOptions: { $set: [] },
                })
            },

            [actionTypes.RESET_CREATE_CONFIG_DATA]: (state) => {
                return immutable(state, {
                    createConfigData: { $set: {} },
                    createConfigDataLoading: { $set: false },
                    createConfigDataSuccess:{$set:false},
                    createConfigDataError: { $set: null },
                    deleteConfigDataLoading: { $set: false },
                    deleteConfigData: { $set: {} },
                    deleteConfigDataError: { $set: null }
                })
            },

            [actionTypes.GET_CHECK_ALL_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    checkAllData: { $set: payload && payload.data || [] },
                    checkAllNextIndex: { $set: payload && payload.nextIndex || 0 },
                    checkAllTotalCount: { $set: payload && payload.totalCount || 0 },
                });
            },

            [actionTypes.RESET_ALL]: (state) => {
                return immutable(state, {
                    dataLoading: { $set: false },
                    data: { $set: [] },
                    dataError: { $set: null },
                    articleData: { $set: [] },
                    articleDataLoading: { $set: false },
                    articleDataError: { $set: null },
                    storeGroupData: { $set: {} },
                    storeGroupDataLoading: { $set: false },
                    storeGroupDataError: { $set: null },
                    departmentOptions: { $set: [] },
                    genderOptions: { $set: [] },
                    rbuOptions: { $set: [] },
                    dcsOptions: { $set: [] },
                    level5Options: { $set: [] },
                    level6Options: { $set: [] },
                    level7Options: { $set: [] },
                    styleOptions: { $set: [] },
                    colorOptions: { $set: [] },
                    sizeOptions: { $set: [] },
                    productProfilesData: { $set: {} },
                    productProfilesDataLoading: { $set: false },
                    productProfilesDataError: { $set: null },
                    storeSizeContributionData: { $set: {} },
                    storeSizeContributionDataLoading: { $set: false },
                    storeSizeContributionDataError: { $set: null },
                    dcData: { $set: {} },
                    dcDataLoading: { $set: false },
                    dcDataError: { $set: null },
                    createConfigData: { $set: {} },
                    createConfigDataSuccess:{$set:false},
                    createConfigDataLoading: { $set: false },
                    createConfigDataError: { $set: null },
                    deleteConfigDataLoading: { $set: false },
                    deleteConfigData: { $set: {} },
                    deleteConfigDataError: { $set: null },
                    selectedFilters: { $set: null },
                    productConfigTotalCount: { $set: 0 },
                    productConfigNextIndex: { $set: 0 },
                    checkAllData: { $set: [] },
                    checkAllNextIndex: { $set: 0 },
                    checkAllTotalCount: { $set: 0 },
                    // noConfigLoading: { $set: true },
                    // noConfigData: { $set: [] },
                    // noConfigError: { $set: null },
                })
            },
            [actionTypes.RESET_DOWNLOAD_INDEX]: (state, payload) => {
                return immutable(state, {
                    downloadExcelData: { $set: [] },
                    downloadNextIndex: { $set: 0 },
                    downloadTotalCount: { $set: 0 },
                    downloadExcelError: { $set: null },
                    downloadLoading: { $set: false },
                    checkAllData: { $set: [] },
                    checkAllNextIndex: { $set: 0 },
                    checkAllTotalCount: { $set: 0 },
                });
            },
            [actionTypes.DOWNLOAD_EXCEL_DATA]: (state, { payload }) => {
                return immutable(state, {
                    downloadExcelData: { $set: (payload && payload.data) || null },
                    downloadExcelError: { $set: null },
                    downloadLoading: { $set: true }
                });
            },
            [actionTypes.GET_DOWNLOAD_EXCEL_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    downloadExcelData: { $set: (payload && state.downloadLoading && payload.data) || null },
                    downloadNextIndex: { $set: (payload && state.downloadLoading && payload.nextIndex) || 0 },
                    downloadTotalCount: { $set: (payload && state.downloadLoading && payload.totalCount) || 0 },
                    downloadExcelError: { $set: null }
                });
            },
            [actionTypes.GET_DOWNLOAD_EXCEL_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    downloadExcelData: { $set: [] },
                    downloadNextIndex: { $set: 0 },
                    downloadTotalCount: { $set: 0 },
                    downloadExcelError: { $set: payload.error }
                });
            },
            [actionTypes.UPDATE_PRODUCT_CONFIG]: (state, payload) => {
                return immutable(state, {
                    updateAutoAllocationLoading: { $set: true },
                    updateAutoAllocationSuccess:{ $set: false},
                    updateAutoAllocationError: { $set: null },
                })
            },
            [actionTypes.UPDATE_PRODUCT_CONFIG_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    updateAutoAllocationLoading: { $set: false },
                    updateAutoAllocationSuccess: { $set: true},
                    updateAutoAllocationError: { $set: null },
                })
            },
            [actionTypes.UPDATE_PRODUCT_CONFIG_ERROR]: (state, payload) => {
                return immutable(state, {
                    updateAutoAllocationLoading: { $set: false },
                    updateAutoAllocationSuccess:{ $set: false},
                    updateAutoAllocationError: { $set: (payload && payload.error) || null },
                })
            },
        },
        productStatusInitialState
    )
}