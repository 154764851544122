import * as actionTypes from "../Actions/ActionTypes"
import { initState } from "./InitState"
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export default {
    excessReport: handleActions(
        {
            [actionTypes.FETCH_FILTER_SUCCESS]: (state, payload) => {
                switch (payload.key) {
                    case "department":
                        return immutable(state, {
                            departmentOptions: {
                                $set:
                                    payload && payload.data.departmentOptions
                                        ? payload.data.departmentOptions
                                        : state.departmentOptions,
                            },
                            genderOptions: [],
                            rbuOptions: [],
                            dcsOptions: [],
                            level5Options: [],
                            level6Options: [],
                            level7Options: [],
                            styleOptions: [],
                            colorOptions: [],
                            articleOptions: [],
                            storeOptions: [],
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "gender":
                        return immutable(state, {
                            // departmentOptions: {
                            //     $set:
                            //         payload && payload.data.departmentOptions
                            //             ? payload.data.departmentOptions
                            //             : state.departmentOptions,
                            // },
                            genderOptions: {
                                $set:
                                    payload && payload.data.genderOptions
                                        ? payload.data.genderOptions
                                        : state.genderOptions,
                            },
                            rbuOptions: {
                                $set:
                                    payload && payload.data.rbuOptions
                                        ? payload.data.rbuOptions
                                        : state.rbuOptions,
                            },
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            articleOptions: {
                                $set:
                                    payload && payload.data.articleOptions
                                        ? payload.data.articleOptions
                                        : state.articleOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "sub_category":
                        return immutable(state, {
                            rbuOptions: {
                                $set:
                                    payload && payload.data.rbuOptions
                                        ? payload.data.rbuOptions
                                        : state.rbuOptions,
                            },
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            articleOptions: {
                                $set:
                                    payload && payload.data.articleOptions
                                        ? payload.data.articleOptions
                                        : state.articleOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "dcs":
                        return immutable(state, {
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },

                            articleOptions: {
                                $set:
                                    payload && payload.data.articleOptions
                                        ? payload.data.articleOptions
                                        : state.articleOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "l10":
                            return immutable(state, {
                                genderOptions: {
                                    $set:
                                        payload && payload.data.genderOptions
                                            ? payload.data.genderOptions
                                            : state.genderOptions,
                                },
                                filterlabels: {
                                    $set:
                                        payload && payload.data.filterMapping
                                            ? payload.data.filterMapping
                                            : state.filterMapping
                                }
                            });
                    case "l11":
                            return immutable(state, {
                                rbuOptions: {
                                    $set:
                                        payload && payload.data.rbuOptions
                                            ? payload.data.rbuOptions
                                            : state.rbuOptions,
                                },
                                filterlabels: {
                                    $set:
                                        payload && payload.data.filterMapping
                                            ? payload.data.filterMapping
                                            : state.filterMapping
                                }
                            });
                    case "level5":
                        return immutable(state, {
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },

                            articleOptions: {
                                $set:
                                    payload && payload.data.articleOptions
                                        ? payload.data.articleOptions
                                        : state.articleOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "level6":
                        return immutable(state, {
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },

                            articleOptions: {
                                $set:
                                    payload && payload.data.articleOptions
                                        ? payload.data.articleOptions
                                        : state.articleOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "level7":
                        return immutable(state, {
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },

                            articleOptions: {
                                $set:
                                    payload && payload.data.articleOptions
                                        ? payload.data.articleOptions
                                        : state.articleOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "style":
                        return immutable(state, {
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },

                            articleOptions: {
                                $set:
                                    payload && payload.data.articleOptions
                                        ? payload.data.articleOptions
                                        : state.articleOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "color":
                        return immutable(state, {
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            articleOptions: {
                                $set:
                                    payload && payload.data.articleOptions
                                        ? payload.data.articleOptions
                                        : state.articleOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "article":
                        return immutable(state, {

                            articleOptions: {
                                $set:
                                    payload && payload.data.articleOptions
                                        ? payload.data.articleOptions
                                        : state.articleOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "store":
                        return immutable(state, {
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                }
            },
            [actionTypes.GET_EXCESS_DATA]: (state, { payload }) => {
                return immutable(state, {
                    tableDataLoading: { $set: true },
                    // excessTableData: { $set: [] },
                    tableDataError: { $set: null },
                    nextIndex: { $set: 0 },
                    totalCount: { $set: 0 },
                    sum: { $set: {} },
                });
            },
            [actionTypes.GET_EXCESS_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    tableDataLoading: { $set: false },
                    tableDataError: { $set: null },
                    excessTableData: { $set: (payload && payload.tableData) || [] },
                    totalCount: { $set: (payload && payload.totalCount) || 0 },
                    sum: { $set: (payload && payload.sum) || {} },
                    nextIndex: { $set: (payload && payload.nextIndex) || 0 },
                    tableDataSuccess: { $set: true }
                });
            },
            [actionTypes.GET_EXCESS_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    tableDataError: { $set: (payload && payload.error) || null },
                    tableDataLoading: { $set: false },
                    excessTableData: { $set: [] },
                    tableDataSuccess: { $set: false },
                    sum: { $set: {} },
                });
            },
            [actionTypes.GENERATE_EXCEL]: (state, { payload }) => {
                return immutable(state, {
                    excelLoading: { $set: true },
                });
            },
            [actionTypes.GENERATE_EXCEL_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    excelData: { $set: (payload && payload.excelData) || [] },
                    excelLoading: { $set: false },
                    excelError: { $set: false },
                    excelSuccess: { $set: true },
                    totalCountExcel: { $set: (payload && payload.totalCountExcel) || 0 },
                    nextIndexExcel: { $set: (payload && payload.nextIndexExcel) || 0 }
                });
            },
            [actionTypes.GENERATE_EXCEL_ERROR]: (state, payload) => {
                return immutable(state, {
                    excelData: { $set: [] },
                    excelLoading: { $set: false },
                    excelError: { $set: true },
                    excelSuccess: { $set: false },
                    totalCountExcel: { $set: 0 },
                    nextIndexExcel: { $set: 0 },
                });
            },
            [actionTypes.GET_EXCESS_GRAPH_DATA]: (state, payload) => {
                return immutable(state, {
                    excessTableData: { $set: [] },
                    tableDataLoading: { $set: true },
                    graphDataLoading: { $set: true },
                    excessGraphData: { $set: {} },
                    graphDataError: { $set: null },
                    weeks: { $set: [] },
                    yearData: { $set: [] },
                });
            },
            [actionTypes.GET_EXCESS_GRAPH_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    graphDataLoading: { $set: false },
                    graphDataError: { $set: null },
                    excessGraphData: { $set: (payload && payload.graphData) || {} },
                    graphDataSuccess: { $set: true },
                    weeks: { $set: (payload && payload.weeks) || [] },
                    yearData: { $set: (payload && payload.yearData) || [] }
                });
            },
            [actionTypes.GET_EXCESS_GRAPH_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    graphDataError: { $set: (payload && payload.error) || {} },
                    graphDataLoading: { $set: false },
                    excessGraphData: { $set: {} },
                    graphDataSuccess: { $set: false },
                    weeks: { $set: [] },
                    yearData: { $set: [] },
                });
            },
            [actionTypes.RESET_ALL]: (state) => {
                return immutable(state, {
                    tableDataError: { $set: null },
                    graphDataError: { $set: null },
                    tableDataLoading: { $set: false },
                    grpahDataLoading: { $set: false },
                    excessTableData: { $set: [] },
                    excessGraphData: { $set: {} },
                    tableDataSuccess: { $set: false },
                    graphDataSuccess: { $set: false },
                    excelData: { $set: [] },
                    nextIndex: { $set: 0 },
                    totalCount: { $set: 0 },
                    weeks: { $set: [] },
                    yearData: { $set: [] },
                    genderOptions: { $set: [] },
                    rbuOptions: { $set: [] },
                    dcsOptions: { $set: [] },
                    level5Options: { $set: [] },
                    level6Options: { $set: [] },
                    level7Options: { $set: [] },
                    styleOptions: { $set: [] },
                    colorOptions: { $set: [] },
                    articleOptions: { $set: [] },
                    storeOptions: { $set: [] },
                    excelLoading: { $set: false},
                    totalCountExcel: { $set: 0 },
                    nextIndexExcel: { $set: 0 },
                    excelError: { $set: false },
                    excelSuccess: { $set: false },
                });
            },
            // [actionTypes.RESET_FILTERS_DATA]: (state) => {
            //     return immutable(state, {
            //         genderOptions: { $set: [] },
            //         rbuOptions: { $set: [] },
            //         dcsOptions: { $set: [] },
            //         styleOptions: { $set: [] },
            //         colorOptions: { $set: [] },
            //         articleOptions: { $set: [] },
            //         storeOptions: { $set: [] },
            //     });
            // }
        },

        initState
    )
}