export const ContractPO_ALLOCATION_TABLE_HEADERS = {
  ALLOCATION_NAME: "Allocation Name",
  PO_ID: "PO ID",
  VENDOR_ID: "Vendor ID",
  STYLE_COUNT: "# Style colors",
  CREATION_DATE: "Creation Date",
  VALIDITY_START: "Validity Start",
  VALIDITY_END: "Validity End",
  OH_OO: "OH+IT+OO",
  ECOM_SALES_UNIT: "Ecomm Sales Units",
  LW_SALES_UNIT: "LW Sales Units",
  TARGET_QUANTITY: "Target Quantity",
  OPEN_TARGET_QUANTITY: "Open Target Quantity",
  ALLOCATED_QTY: "Allocated Qty",
  PRODUCT_TYPE: "Product Type",
  STYLE_COLOR_ID: "Style Color ID",
  STYLE_ID: "Style ID",
  COLOR_ID: "Color ID",
  STYLE_DESC: "Style Description",
  COLOR_DESC: "Color Description",
  STORE_GROUP: "Store Group",
  ASSORTMENT_INDICATOR: "Assortment Indicator",
};
