import { forEach, isEmpty } from "lodash";
import moment from "moment";

export const getKeyOnSearchedterm = (p_searchTerm) => {
  let l_key = "";
  for (const value of Object.values(p_searchTerm)) {
    l_key = l_key + `${value.value}`;
  }
  return l_key.toLocaleLowerCase();
};

const getLowerCase = (p_string = "") => {
  return p_string.toLocaleLowerCase();
};

const getReq = (p_req) => {
  let l_req = {};
  for (let i in p_req) {
    if (
      Array.isArray(p_req[i]) &&
      i !== "sortColumn" &&
      i !== "default_store_groups" &&
      i !== "default_dcs" &&
      i !== "Inventory_Source_Filter" &&
      i !== "default_product_profiles"
    ) {
      l_req[i] = p_req[i]?.map((val) => val.value);
    } else if (
      !isEmpty(p_req[i]) &&
      typeof p_req[i] === "object" &&
      i !== "searchColumns" &&
      i !== "default_store_groups" &&
      i !== "default_dcs" &&
      i !== "Inventory_Source_Filter" &&
      i !== "default_product_profiles"
    ) {
      l_req[i] = p_req[i]?.value;
    } else if (p_req[i]) {
      l_req[i] = p_req[i];
    }
  }
  return l_req;
};

export const getArrayDifference = (a = [], b = []) => {
  try {
    return a.filter((val) => !b.includes(val));
  } catch {
    return [];
  }
};

export const getSetAllReqForNextSetOfData = (p_setAllParams) => {
  const {
    searchTermReq,
    sortReq,
    storeEligibiltyGroupSA,
    dcSA,
    inventorySourceSA,
    productProfileSA,
    demandTypeSA,
    userDefinedInvSA,
    plannedAPSSA,
    plannedWOSSA,
  } = p_setAllParams;
  let req = getReq(
    {
      searchColumns: searchTermReq,
      sortColumn: sortReq,
      default_store_groups: storeEligibiltyGroupSA,
      default_dcs: dcSA,
      Inventory_Source_Filter: inventorySourceSA,
      default_product_profiles: productProfileSA,
      Demand_Type_Filter_Default: demandTypeSA,
      edit_total_inventory: userDefinedInvSA,
      edit_total_inventory_units: "",
      article_aps: plannedAPSSA,
      article_wos: plannedWOSSA,
    },
    "xsxsxsxsx"
  );
  return req;
};

const applyFiltersForDataSet = (
  p_searchTermReq,
  p_checkedArticles,
  p_finalData
) => {
  for (let i in p_searchTermReq) {
    let l_updatedRows = !isEmpty(p_checkedArticles)
      ? p_checkedArticles
      : p_finalData;
    if (p_searchTermReq[i].type === "string") {
      // p_checkedArticles = l_updatedRows.filter(val => val[i] === p_searchTermReq[i].value)
      p_checkedArticles = l_updatedRows.filter((val) =>
        getLowerCase(val[i]).includes(getLowerCase(p_searchTermReq[i].value))
      );
    }
    if (p_searchTermReq[i].type === "range") {
      p_checkedArticles = l_updatedRows.filter(
        (val) =>
          val[i] >= (p_searchTermReq[i].value?.min || 0) &&
          val[i] <= (p_searchTermReq[i].value?.max || Math.min())
      );
    }
    if (
      p_searchTermReq[i].type === "array"
      // && i !== "article"
    ) {
      p_checkedArticles = l_updatedRows.filter((val) =>
        p_searchTermReq[i].values.includes(val[i])
      );
    }
  }
  return p_checkedArticles;
};

export const getFinalCheckedArticles = (p_checkAllConfig, p_finalData) => {
  let l_finalCheckedArticles = [];
  p_checkAllConfig.forEach((val) => {
    if (val.checkedRows?.length) {
      let checked = val.checkedRows;
      let l_checkedArticles = p_finalData
        .filter((value) => checked.includes(value.article))
        ?.map((val) => val.article);
      l_finalCheckedArticles = [
        ...l_finalCheckedArticles,
        ...l_checkedArticles,
      ];
    }
    if (val.unCheckedRows?.length) {
      let unchecked = val.unCheckedRows;
      let l_unCheckedArticles = p_finalData
        .filter((value) => unchecked.includes(value.article))
        ?.map((val) => val.article);
      l_finalCheckedArticles = l_finalCheckedArticles.filter(
        (val) => !l_unCheckedArticles.includes(val)
      );
    }
    if (val.checkAll) {
      let l_searchTermReq = val.searchTermReq,
        l_checkedArticles = [];
      if (!isEmpty(l_searchTermReq)) {
        l_checkedArticles = applyFiltersForDataSet(
          l_searchTermReq,
          l_checkedArticles,
          p_finalData
        );
      } else {
        l_checkedArticles = p_finalData;
      }
      let l_updatedArticlesWithFilters = l_checkedArticles?.map(
        (val) => val.article
      );
      l_finalCheckedArticles = [
        ...l_finalCheckedArticles,
        ...l_updatedArticlesWithFilters,
      ];
    }
    if (val.unCheckAll) {
      let l_searchTermReq = val.searchTermReq,
        l_checkedArticles = [];
      if (!isEmpty(l_searchTermReq)) {
        l_checkedArticles = applyFiltersForDataSet(
          l_searchTermReq,
          l_checkedArticles,
          p_finalData
        );
      } else {
        l_checkedArticles = p_finalData;
      }
      let l_updatedArticlesWithFilters = l_checkedArticles?.map(
        (val) => val.article
      );
      l_finalCheckedArticles = l_finalCheckedArticles.filter(
        (val) => !l_updatedArticlesWithFilters.includes(val)
      );
    }
  });
  return l_finalCheckedArticles;
};

export const getFinalCheckedRowsForCAData = (
  p_checkAllConfig,
  p_CAData,
  p_updatedRows,
  p_selectedArticles
) => {
  let l_checkedArticles = getFinalCheckedArticles(p_checkAllConfig, p_CAData);
  let l_checkedRows = p_CAData.filter((val) =>
    l_checkedArticles.includes(val.article)
  );
  let l_checkedArticlesObject = {};
  l_checkedRows.forEach((val) => {
    l_checkedArticlesObject[val.article] = val;
  });
  let l_updatedRowsObject = {};
  p_updatedRows.forEach((val) => {
    l_updatedRowsObject[val.article] = val;
  });

  let l_checkedUpdatedRowsObject = Object.keys(l_updatedRowsObject)
    .filter((c) => Object.keys(l_checkedArticlesObject).indexOf(c) !== -1)
    .reduce((a, b) => {
      let b1 = {};
      b1[b] = l_updatedRowsObject[b];
      return { ...a, ...b1 };
    }, {});
  let l_checkedArticleFromBatch = {
    ...l_checkedArticlesObject,
    ...l_checkedUpdatedRowsObject,
  };
  let l_existingArticles = Object.keys(p_selectedArticles);
  l_existingArticles.forEach((val) => {
    delete l_checkedArticleFromBatch[val];
  });
  return l_checkedArticleFromBatch;
};

export const getNewData = ({
  p_existingArticles,
  p_newData,
  p_updatedRows,
  p_searchTermReq,
  // p_checkedArticles, p_unCheckedArticles,
  p_checkAll,
  p_newBatchData,
}) => {
  console.log(p_checkAll, "cdcdc");
  let l_updatedRowsWithFilters = [],
    newData = [];
  if (!isEmpty(p_searchTermReq) && !isEmpty(p_updatedRows)) {
    try {
      l_updatedRowsWithFilters = applyFiltersForDataSet(
        p_searchTermReq,
        l_updatedRowsWithFilters,
        p_updatedRows
      );
      // for (let i in p_searchTermReq) {
      //     let l_updatedRows = !isEmpty(l_updatedRowsWithFilters) ? l_updatedRowsWithFilters : p_updatedRows
      //     if(p_searchTermReq[i].type === "string"){
      //         l_updatedRowsWithFilters = l_updatedRows.filter(val => val[i] === p_searchTermReq[i].value)
      //     }
      //     if(p_searchTermReq[i].type === "range") {
      //         l_updatedRowsWithFilters = l_updatedRows.filter(val => val[i] >= p_searchTermReq[i].value.min && val[i] <= p_searchTermReq[i].value.max)
      //     }
      //     if(p_searchTermReq[i].type === "array"
      //     // && i !== "article"
      //     ) {
      //         l_updatedRowsWithFilters = l_updatedRows.filter(val => p_searchTermReq[i].values.includes(val[i]))
      //     }
      // }
    } catch (e) {
      console.log(e);
      l_updatedRowsWithFilters = [];
    }
  } else if (!isEmpty(p_updatedRows)) {
    let removedArticle = (p_newData || [])
      ?.filter((val) => p_existingArticles.includes(val.article))
      ?.map((val) => val.article);
    l_updatedRowsWithFilters = p_updatedRows?.filter((val) =>
      removedArticle.includes(val.article)
    );
  }

  let l_existingArticles = l_updatedRowsWithFilters.map((val) => val.article);

  if (!isEmpty(l_existingArticles)) {
    newData = (p_newData || [])?.filter(
      (val) => !l_existingArticles.includes(val.article)
    );
  } else {
    newData = p_newData;
  }

  let finalData = [...l_updatedRowsWithFilters, ...newData];
  let finalCheckedRows = {};
  if (!isEmpty(p_checkAll)) {
    let l_finalCheckedArticles = getFinalCheckedArticles(p_checkAll, finalData);
    let initialCheckedRows = finalData.reduce(
      (m, e, i) => (l_finalCheckedArticles.includes(e.article) && m.push(i), m),
      []
    );
    initialCheckedRows.forEach((val) => (finalCheckedRows[val] = true));
  }
  return { finalData, finalCheckedRows };
};

export const getRecentUpdatesOnArticle = (
  p_existingUpdatesOnArticles,
  p_newUpdatesOnArticle
) => {
  let l_newArtclesId = p_newUpdatesOnArticle.map((val) => val.article);
  let l_oldUpdatesOnArticles = p_existingUpdatesOnArticles.filter(
    (val) => !l_newArtclesId.includes(val.article)
  );
  let l_finalList = [...l_oldUpdatesOnArticles, ...p_newUpdatesOnArticle];
  return l_finalList;
};

export const getNewArticleToBeUnchecked = (
  p_rowsById,
  p_uncheckableArtilces,
  p_checkesRows
) => {
  let l_uncheckableIds = [],
    l_checkedRows = { ...p_checkesRows };

  for (let i in p_rowsById) {
    if (p_uncheckableArtilces.includes(p_rowsById[i].original.article)) {
      l_uncheckableIds.push(p_rowsById[i].id);
    }
  }

  for (let i in p_checkesRows) {
    if (l_uncheckableIds.includes(i)) {
      delete l_checkedRows[i];
    }
  }
  return l_checkedRows;
};

export const getStoreAndDcRequestForNewSetOfArticles = (
  p_selectedArticles,
  p_storeCode
) => {
  const dcRequest = [];

  const request = Object.keys(p_selectedArticles)?.map((o) => {
    const size_lst = p_selectedArticles[o].size_desc?.map((o) => o?.value);
    const dc_codes = p_selectedArticles[o].default_dcs?.map((o) => o?.value);
    const selectedStores = p_storeCode
      ?.map((o) => o?.value)
      .filter((val) => val != "*");

    let demandType, productProfileCode, inventorySource;
    if (Array.isArray(p_selectedArticles[o].Demand_Type_Filter_Default)) {
      demandType = p_selectedArticles[o].Demand_Type_Filter_Default[0]?.value;
    } else {
      demandType = p_selectedArticles[o].Demand_Type_Filter_Default?.value;
    }

    if (Array.isArray(p_selectedArticles[o].product_profiles)) {
      productProfileCode =
        p_selectedArticles[o].product_profiles[0]?.value;
    } else {
      productProfileCode =
        p_selectedArticles[o].product_profiles?.value;
    }

    inventorySource = p_selectedArticles[o]?.default_inventory_sorce_filter;
    let inventoryMapping = {
      oo: "onorder",
      oh: "onhand",
      it: "intransit",
    };
    let selectedInvsource = []
    forEach(inventorySource, (source) => {
      if (inventoryMapping[source.value]) {
        selectedInvsource.push(inventoryMapping[source.value]);
      }
    });

    dcRequest.push({
      Size_List: size_lst,
      DC_Codes: dc_codes,
      Product_Code: p_selectedArticles[o].article,
      Inventory_Source: selectedInvsource,
    });

    return {
      Product_Profile_Code: productProfileCode,
      Product_Code: p_selectedArticles[o].article,
      Store_Group_Code: p_selectedArticles[
        o
      ].default_store_eligibility_default?.map((o) => o?.value),
      Size_List: size_lst,
      DC_Codes: dc_codes,
      Demand_Type: demandType,
      Delivery_Date: moment(p_selectedArticles[o]["delivery_date"]).format(
        "YYYY-MM-DD"
      ),
      Inventory_Source: selectedInvsource,
      Original_Total_Inventory: Number(p_selectedArticles[o].total_inventory),
      Planned_APS: Number(p_selectedArticles[o].article_aps),
      Planned_WOS: Number(p_selectedArticles[o].article_wos),
      User_Defined_Inventory: Number(
        p_selectedArticles[o].final_total_inventory
      ),
      hasEdited: isNaN(p_selectedArticles[o].final_total_inventory)
        ? false
        : true,
      User_Selected_Stores: selectedStores,
    };
  });

  return { request, dcRequest };
};

export const removeCheckedArticles = (p_checkedRows, p_searchTermReq) => {
  let l_checkedArticles = {};
  if (!isEmpty(p_searchTermReq)) {
    let l_unChecekdRows = applyFiltersForDataSet(
      p_searchTermReq,
      l_checkedArticles,
      Object.values(p_checkedRows)
    );
    let l_unCheckedArticles = l_unChecekdRows?.map((val) => val.article);
    l_unCheckedArticles.forEach((e) => delete p_checkedRows[e]);
    return p_checkedRows;
  } else {
    return {};
  }
};
