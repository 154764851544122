import { isEmpty } from "lodash";

export const getNewData = ({p_newData, p_checkAll}) => {
    let finalCheckedRows = {}
    if(!isEmpty(p_checkAll)) {
        let l_finalCheckedArticles = getFinalCheckedArticles(p_checkAll,p_newData)
        let initialCheckedRows = p_newData.reduce((m, e, i) => (l_finalCheckedArticles.includes(e.article) && m.push(i), m), []);
        initialCheckedRows.forEach(val => finalCheckedRows[val] = true)
    }
    return {p_newData,finalCheckedRows};
}


export const getFinalCheckedArticles = (p_checkAllConfig, p_finalData) => {
    let l_finalCheckedArticles =[]
    p_checkAllConfig.forEach(val => {
        if(val.checkedRows?.length) {
           let checked = val.checkedRows
           let l_checkedArticles = p_finalData.filter(value => checked.includes(value.article))?.map(val => val.article)
           l_finalCheckedArticles = [...l_finalCheckedArticles,...l_checkedArticles]
        }  
        if(val.unCheckedRows?.length) {
           let unchecked = val.unCheckedRows
           let l_unCheckedArticles = p_finalData.filter(value => unchecked.includes(value.article))?.map(val => val.article)
           l_finalCheckedArticles = l_finalCheckedArticles.filter(val => !l_unCheckedArticles.includes(val))
        }  
        if(val.checkAll) {
            let l_searchTermReq= val.searchTermReq, l_checkedArticles = []
            if(!isEmpty(l_searchTermReq)){
                l_checkedArticles = applyFiltersForDataSet(l_searchTermReq,l_checkedArticles,p_finalData)
            }
            else {
                l_checkedArticles = p_finalData
            }
            let l_updatedArticlesWithFilters = l_checkedArticles?.map(val => val.article)
            l_finalCheckedArticles = [...l_finalCheckedArticles,...l_updatedArticlesWithFilters]
        }
        if(val.unCheckAll) {
            let l_searchTermReq= val.searchTermReq, l_checkedArticles = []
            if(!isEmpty(l_searchTermReq)){
                l_checkedArticles = applyFiltersForDataSet(l_searchTermReq,l_checkedArticles,p_finalData)
            }
            else {
                l_checkedArticles = p_finalData
            }
            let l_updatedArticlesWithFilters = l_checkedArticles?.map(val => val.article)
            l_finalCheckedArticles = l_finalCheckedArticles.filter(val => !l_updatedArticlesWithFilters.includes(val))
        }            
                            
    })
    return l_finalCheckedArticles;
}

const applyFiltersForDataSet = (p_searchTermReq,p_checkedArticles,p_finalData) => {
    for (let i in p_searchTermReq) {
        let l_updatedRows = !isEmpty(p_checkedArticles) ? p_checkedArticles : p_finalData
        if(p_searchTermReq[i].type === "string"){
            // p_checkedArticles = l_updatedRows.filter(val => val[i] === p_searchTermReq[i].value)
            p_checkedArticles = l_updatedRows?.filter(val => {
                if(Array.isArray(val[i])) {
                    val[i] = val[i].join(", ")
                }
                return getLowerCase(val[i]).includes(getLowerCase(p_searchTermReq[i].value))
            })
        }
        if(p_searchTermReq[i].type === "range") {
            if(p_searchTermReq[i].value?.min !== undefined) {
                p_searchTermReq[i].value.min = Number(p_searchTermReq[i].value?.min)
            }
            if(p_searchTermReq[i].value?.max !== undefined) {
                p_searchTermReq[i].value.max = Number(p_searchTermReq[i].value?.max)
            }
            p_checkedArticles = l_updatedRows?.filter(val => val[i] >= (p_searchTermReq[i].value?.min || 0) && val[i] <= (p_searchTermReq[i].value?.max || Math.min()))
        }
        if(p_searchTermReq[i].type === "array" 
        // && i !== "article"
        ) {
            p_checkedArticles = l_updatedRows?.filter(val => p_searchTermReq[i].values.includes(val[i]))
        }
    }
    return p_checkedArticles;
}

const getLowerCase = (p_string = "") => {
    return p_string?.toLocaleLowerCase()
}

export const removeCheckedArticles = (p_checkedRows,p_searchTermReq) => {
    let l_checkedArticles = {}
        if(!isEmpty(p_searchTermReq)){
            let l_unChecekdRows = applyFiltersForDataSet(p_searchTermReq,l_checkedArticles,Object.values(p_checkedRows))
            let l_unCheckedArticles = l_unChecekdRows?.map(val => val.article)
            l_unCheckedArticles.forEach(e => delete p_checkedRows[e]);
            return p_checkedRows;
        }
        else {
            return {};
        }
}

export const getFinalCheckedRowsForCAData = (p_checkAllConfig,p_CAData,p_updatedRows,p_selectedArticles) => {
    let l_checkedArticles =  getFinalCheckedArticles(p_checkAllConfig,p_CAData)
    let l_checkedRows =  p_CAData.filter(val => l_checkedArticles.includes(val.article))
    let l_checkedArticlesObject = {}
    l_checkedRows.forEach(val => {
        l_checkedArticlesObject[val.article] = val
    })
    // let l_updatedRowsObject= {}
    // p_updatedRows.forEach(val => {
    //     l_updatedRowsObject[val.article] = val
    //   }) 

    // let l_checkedUpdatedRowsObject = Object.keys(l_updatedRowsObject).filter(c => Object.keys(l_checkedArticlesObject).indexOf(c) !== -1).reduce((a,b) => {let b1={}; b1[b]=l_updatedRowsObject[b]; return {...a, ...b1}}, {});
    // let l_checkedArticleFromBatch = {
    //     ...l_checkedArticlesObject,
    //     ...l_checkedUpdatedRowsObject
    // }
    // let l_existingArticles = Object.keys(p_selectedArticles)
    // l_existingArticles.forEach(val => {
    //     delete l_checkedArticleFromBatch[val]
    // })
    return l_checkedArticlesObject;
}