import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import * as Notify from '../../../../components/Notification/Notifications'
import { useEffect } from 'react';
import { levelFiltersLabelFormatter, updateArticleName } from '../../../../utils/filterLevelMapping';
import { CREATE_CONFIGURATION } from '../../../../constants/routeConstants';
import ProductStatusFilters from '../ProductStatusFilters';
import ColumnFilterServerSide from '../../../../components/Filters/ColumnFilterServerSide';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const StyleMappingTable = (props) => {

    const { data, getStyles, setRTinstance, initialSelectedRows, selectedFilters, fecthDataWithSorting, fecthDataWithSearchTerm,
        initialIndex, totalCount, fetchData, searchTermReq } = props

    const history = useHistory();
    const [showDCModal, setShowDCModal] = useState(false)

    const articleConfigColumns = [
        {
            Header: `${updateArticleName(false)} ID`,
            accessor: "article",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.article?.search} placeholder={"Search in bulk by ','"} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "array", ",")} />
            ),              
            filter: 'bulkFilterCommaSeperated',
            sticky: "left",
        },
        {
            Header: 'Style ID',
            accessor: 'style_code',
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            sticky: "left"
        },
        {
            Header: 'Color ID',
            accessor: 'color_code',
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ), 
            sticky: "left"
        },
        // {
        //     Header: "Description",
        //     accessor: "desc",
        //     Filter: ColumnFilter
        // },
        {
            Header: "Style",
            accessor: "style_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            width: 250
        },
        {
            Header: "Style Color",
            accessor: "color_desc",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_desc?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: "Global Color",
            accessor: "color",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ), 
        },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: "l2_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l2_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ), 
        },
        {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: "l3_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l3_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ), 
        },
        {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: "l4_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l4_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ), 
        },
        {
            Header: levelFiltersLabelFormatter("level5"),
            accessor: "l5_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l5_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ), 
        },
        {
            Header: levelFiltersLabelFormatter("level6"),
            accessor: "l6_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l6_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ), 
        },
        {
            Header: "Product Type",
            accessor: "product_type",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.product_type?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ), 
        }
    ]

    const callApis = () => {
        props.getProductStatusData(props?.filters)
        // props.getArticleConfigData()
    }

    useEffect(() => {
        return () => {
            // props.resetAll()
        }
    }, [])

    const setSelectedArticles = (instance) => {
        setRTinstance(instance)
    }

    const applyFilters= (filters) => {
        getStyles(filters)
    }

    return (
        <div>
            <div className="row">
                <ProductStatusFilters styleMapping selectedFilters={selectedFilters} applyFilters={applyFilters} />
            </div>
            <ReactTableWithPlugins
                shouldPagination
                totalRecordsLen={data?.length}
                data={data ? data : []}
                columns={articleConfigColumns}
                renderMarkup='TableMarkup'
                keyRT="productProfileMapping"
                features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                initialSelectedRows={initialSelectedRows}
                getInstanceOnMount={(instance) => {
                    setSelectedArticles(instance)
                }}
                tableId="product_config_style_mapping"
                tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                fetchData={(data) => fetchData(data)}
                getManualSortByOptions={(instance) => {
                    fecthDataWithSorting(instance)
                }}
                manualSortBy={true}
                initialPageIndex={initialIndex}
                totalCount={totalCount}
                hideDropdown
            />
        </div>
    )
}



export default StyleMappingTable
