import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import useSelectFilters from "../../customHooks/useSelectFilters";
import Filter from "../ProductProfile/ProductProfileTable/Filter";
import {
  fetchFilterData,
  getChartData,
  getStyleInventoryData,
  getRecommendedActionstData,
  resetFiltersOptions,
} from "./DashboardActions";
import * as Notify from "../../components/Notification/Notifications";
import Notification from "../../components/Notification/Notifications";
import { propTypes } from "react-bootstrap/esm/Image";
import { levelFiltersLabelFormatter } from "../../utils/filterLevelMapping"
import { getLevelFiltersForUserPreference,getUserPreferenceFilter, shouldHideForCurrentClient, setUserPreferenceFilter, getFilterForClients, getDefaultFiltersCondition, USER_PREFERENCE_FILTERS, currentClientName, getRequestForChartAPI, MANDATORY_SINGLE_SELECT_FILTERS, getRequestForChartAPIAfterReset, fetchFiltersOnReset, isFutureDate, flattenArray } from "../../utils/commonUtilities";
import { isEmpty } from "lodash";
import { DateRangePicker } from 'react-dates';
import moment from "moment";


function DashboardFilters({
  // level1Options,
  // level2Options,
  // level3Options,
  // level4Options,
  ...props
}) {
  const [isFirstCall, setIsFirstCall] = useState(true);
  const [level1Options, setlevel1Options] = useState(null);
  const [level2Options, setlevel2Options] = useState(null);
  const [level3Options, setlevel3Options] = useState(null);
  const [level4Options, setlevel4Options] = useState(null);
  const [level5Options, setlevel5Options] = useState(null);
  const [level6Options, setlevel6Options] = useState(null);
  const [level7Options, setlevel7Options] = useState(null);
  const [dcOptions, setDcOptions] = useState(null);
  const [factoryTypeOptions, setFactoryTypeOptions] = useState(null);
  const [min_date, setStartDate] = useState(null);
  const [max_date, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isEditing, setIsEditing] = useState(false)
  const [dateRangeLabel, setDateRangeLabel] = useState("");
  const [min_allocation_date, setAllocationStartDate] = useState(null);
  const [max_allocation_date, setAllocationEndDate] = useState(null);
  const [focusedInputAllocationDate, setFocusedInputAllocationDate] = useState(null);

  const [
    level1,
    level2,
    level3,
    level4,
    setLevel1,
    setLevel2,
    setLevel3,
    setLevel4,
    onLevel1Change,
    onLevel2Change,
    onLevel3Change,
    onLevel4Change,
    dynamicHeader,
    request,
    resestRequest,
    dc,
    setDc,
    onDcChange,
    level5,
    setLevel5,
    onLevel5Change,
    level6,
    setLevel6,
    onLevel6Change,
    level7,
    setLevel7,
    onLevel7Change,
    factoryType,
    setFactoryType,
    onFactoryTypeChange,
  ] = useSelectFilters(
    props.fetchFilterData,
    true,
    level2Options,
    level3Options,
    level4Options,
    setlevel2Options,
    setlevel3Options,
    setlevel4Options,
    dcOptions,
    setDcOptions,
    level5Options,
    setlevel5Options,
    level6Options,
    setlevel6Options,
    level7Options,
    setlevel7Options,
    factoryTypeOptions,
    setFactoryTypeOptions
  );


  useEffect(() => {
    if(props.isViewAllocation)
      setDateRangeLabel("Period");
    else
      setDateRangeLabel("Introduction Date")
  },[props.isViewAllocation])

  useEffect(() => {
    setlevel1Options(() => props.level1Options);
  }, [props.level1Options]);


  useEffect(() => {
    setlevel2Options(() => props.level2Options);
  }, [props.level2Options]);

  useEffect(() => {
    setlevel3Options(() => props.level3Options);
  }, [props.level3Options]);

  useEffect(() => {
    setlevel4Options(() => props.level4Options);
  }, [props.level4Options]);

  useEffect(() => {
    setlevel5Options(() => props.level5Options);
  }, [props.level5Options]);

  useEffect(() => {
    setlevel6Options(() => props.level6Options);
  }, [props.level6Options]);

  useEffect(() => {
    setlevel7Options(() => props.level7Options);
  }, [props.level7Options]);

  useEffect(() => {
    setDcOptions(() => props.dcOptions);
  }, [props.dcOptions]);

  useEffect(() => {
    setFactoryTypeOptions(() => props.factoryTypeOptions);
  }, [props.factoryTypeOptions]);

  useEffect(() => {
    if (props.isBackClicked) {
      setLevel1(props.viewAllocationFilters?.level1?.[0])
      setLevel2(props.viewAllocationFilters?.level2?.[0])
      setLevel3(props.viewAllocationFilters?.level3?.[0])
      setLevel4(props.viewAllocationFilters?.level4?.[0])
      setLevel5(props.viewAllocationFilters?.level5?.[0])
      setLevel6(props.viewAllocationFilters?.level6?.[0])
      setLevel7(props.viewAllocationFilters?.level7?.[0])
      props.setIsBackclicked(false)
    }
  }, [props.isBackClicked])

  const resetFilters = () => {
    let l_restrictResetForLevels =  USER_PREFERENCE_FILTERS[currentClientName] || ["l1_name"]
    // setLevel1(null);
    !l_restrictResetForLevels.includes("l2_name") && setLevel2(null);
    !l_restrictResetForLevels.includes("l3_name") && setLevel3(null);
    getDefaultFiltersCondition({setLevel2: setLevel2, setLevel3: setLevel3})
    setLevel4(null);
    setLevel5(null)
    setLevel6(null)
    setLevel7(null)
    setDc(null);
    setFactoryType(null);
    resestRequest();
    setStartDate(null)
    setEndDate(null)
    setAllocationStartDate(null);
    setAllocationEndDate(null);
    props.resetFiltersOptions([]);
    let req = getRequestForChartAPIAfterReset({l1_name:[level1], l2_name: [level2], l3_name: [level3]})
    let isSetLevel2 = false
    if (!props.isViewAllocation) {
      let prefer_l2_name = flattenArray([JSON.parse(localStorage.getItem("user_preference"))?.filters?.l2_name])
      let l2_name_value = flattenArray(req["l2_name"])
      if(!l2_name_value?.[0]?.value) {
        isSetLevel2 = true
        l2_name_value = [prefer_l2_name[0]]
        req["l2_name"] = l2_name_value
      }
      props.getChartData({ ...req, dc: props.dcOptions });
      props.getStyleInventoryData({...req, dc: props.dcOptions, row_index: 0})
      props.getRecommendedActionstData({ ...req, dc: props.dcOptions });

      fetchFiltersOnReset({l1_name:level1, l2_name: level2, l3_name: level3,onl1Change:onLevel1Change,onl2Change:onLevel2Change,onl3Change:onLevel3Change})
      if(isSetLevel2) {
        onLevel2Change(prefer_l2_name[0])
      }

      // props.fetchFilterData({ l1_name: [level1] }, { key: 'l2' });
      // props.getChartData({ l1_name: [level1], dc: props.dcOptions });
      // props.getRecommendedActionstData({ l1_name: [level1], dc: props.dcOptions });
    }
    else{
      setLevel1(null);
      setLevel2(null);
      setLevel3(null);
    }
    props?.resetFilters({ ...req, dc: props.dcOptions })
  };

  const prepReq = (...rest) => {
    let req = {};
    rest.forEach((val) => {
      for (let i in val) {
        if (val[i]) {
          if (Array.isArray(val[i])) {
            if (val[i].length) req[i] = [val[i]];
          } else {
            req[i] = [val[i]];
          }
        }
      }
    });
    return req;
  };

  const applyFilters = () => {
    if (getDefaultFiltersCondition({l1_name: level1, l2_name: level2, l3_name: level3})) {
  
      let req = prepReq({
        start_date: min_date ? moment(min_date).format('YYYY-MM-DD') : null,
        end_date: max_date ? moment(max_date).format('YYYY-MM-DD') : null,
        start_date_last_allocated: min_allocation_date ? moment(min_allocation_date).format('YYYY-MM-DD') : null,
        end_date_last_allocated: max_allocation_date ? moment(max_allocation_date).format('YYYY-MM-DD') : null,
        l1_name: level1,
        l2_name: request?.level2 || level2,
        l3_name: request?.level3 || level3,
        l4_name: request?.level4 || level4,
        l5_name: request?.level5 || level5,
        l6_name: request?.level6 || level6,
        l7_name: request?.level7 || level7,
        level2Label: props.filterLabels?.level2,
        level3Label: props.filterLabels?.level3,
        dc: request?.dc?.length ? request?.dc : props.dcOptions,
        factory_type : request?.factoryType || factoryType
      });
      setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id")}, getLevelFiltersForUserPreference({l1_name:level1,l2_name:level2}))
      if(min_date!==null && max_date===null && !props.isViewAllocation)
      {
        Notify.error("End Date is Mandatory!!");
      }
     else if(min_date==null && max_date!==null && !props.isViewAllocation)
      {
        Notify.error("Start Date is Mandatory!!");
      }
      if(min_allocation_date !== null && max_allocation_date === null && !props.isViewAllocation)
      {
        Notify.error("Allocation End Date is Mandatory!!");
      }
     else if(min_allocation_date == null && max_allocation_date !== null && !props.isViewAllocation)
      {
        Notify.error("Allocation Start Date is Mandatory!!");
      }
     else if (props.isViewAllocation) {
        let viewAllocationReq = prepReq({
          start_date: min_date ? moment(min_date).format('YYYY-MM-DD') : null,
          end_date: max_date ? moment(max_date).format('YYYY-MM-DD') : null,
          level1,
          level2: request?.level2 || level2,
          level3: request?.level3 || level3,
          level4: request?.level4 || level4,
          level5: request?.level5 || level5,
          level6: request?.level6 || level6,
          level7: request?.level7 || level7,
        });
        if(min_date!=null && max_date===null){
          Notify.error("End Date is Mandatory!!");
        }
        else if(min_date==null && max_date!==null)
        {
            Notify.error("Start Date is Mandatory!!");
        }
        else{
        props.getAllocations(viewAllocationReq)
        }
      }
      else {
        console.log('ss12',req)
        props.getChartData(req);
        props.getStyleInventoryData({...req, row_index: 0, "sortColumn":{"id":"lw_qty","desc":true}})
        props.getRecommendedActionstData(req);
      }
      props.getFilters(req)
    } else {
      Notify.error("Please Select Mandatory Option!!");
    }
  };

  useEffect(() => {
    getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") },  {onl1Change:onLevel1Change,onl2Change:onLevel2Change})
    props.fetchFilterData({}, { key: 'l1' });
    // if (!props.isViewAllocation) {
    //   props.fetchFilterData({ filter_type: "dc" }, { key: 'dc' });
    // }
  }, []);

  useEffect(() => {
    let getFilter = JSON.parse(localStorage.getItem("user_preference"))
    if (level1Options?.length && !props.isViewAllocation && isEmpty(getFilter?.filters)) {
      setLevel1(level1Options[0]);
      props.fetchFilterData({ l1_name: [level1Options[0]] }, { key: 'l2' });
      setIsFirstCall(false);
    }
    // else if(!props.isViewAllocation){
    //   setLevel1(null)
    // }
  }, [level1Options]);

  const setDates = (p_startDate, p_endDate, p_static) => {
    // if(isEdit){
    setIsEditing(true)
    // props.onChanged('levelFilter')
    // }
    setStartDate(p_startDate);
    setEndDate(p_endDate);
  }
  const isOutsideRange = day => {
    if(props.isViewAllocation)
      return day.isAfter(moment());
    else 
      return !day;
  };

  const setAllocationDates = (p_startDate, p_endDate, p_static) => {
    setIsEditing(true)
    setAllocationStartDate(p_startDate);
    setAllocationEndDate(p_endDate);
  }

  const viewAllocationPrep = [
    {
      id: 1,
      isRequired: true,
      label: props.filterLabels?.level1,
      name: "level1",
      value: level1,
      onChange: onLevel1Change,
      placeHolder: `Select ${props.filterLabels?.level1}`,
      options: level1Options?.length ? level1Options : [],
      style: { "marginBottom": "10px" },
    },
    getFilterForClients({
      id:2,
      label:props.filterLabels?.level2, 
      name: "level2", 
      dropdownValue: level2,
      onDropDownChange: onLevel2Change, 
      placeHolder: `Select ${props.filterLabels?.level2 ? props.filterLabels?.level2 : ""}`, 
      optionsArray: level2Options?.length ? level2Options : [],
      style: { "marginBottom": "10px" },
      isStandAlone: false,
      level: "l2_name"
    }, "viewpast"),
    getFilterForClients({
      id: 3,
      label: props.filterLabels?.level3,
      name: "level3",
      dropdownValue: level3,
      onDropDownChange: onLevel3Change,
      placeHolder: `Select ${props.filterLabels?.level3 ? props.filterLabels?.level3 : ""}`,
      optionsArray: level3Options?.length ? level3Options : [],
      hide: shouldHideForCurrentClient("l3_name"),
      style: { "marginBottom": "10px" },
      isStandAlone: false,
      level: "l3_name"
    }),
    // {
    //   id: 2,
    //   isRequired: false,
    //   label: props.filterLabels?.level2,
    //   name: "level2",
    //   dropdownValue: level2,
    //   onDropDownChange: onLevel2Change,
    //   placeHolder: `Select ${props.filterLabels?.level2 ? props.filterLabels?.level2 : ""
    //     }`,
    //   optionsArray: level2Options?.length ? level2Options : [],
    //   isMultiSelect: true,
    //   style: { "marginBottom": "10px" },
    // },
    // {
    //   id: 3,
    //   isRequired: false,
    //   label: props.filterLabels?.level3,
    //   name: "level3",
    //   dropdownValue: level3,
    //   onDropDownChange: onLevel3Change,
    //   placeHolder: `Select ${props.filterLabels?.level3 ? props.filterLabels?.level3 : ""
    //     }`,
    //   optionsArray: level3Options?.length ? level3Options : [],
    //   isMultiSelect: true,
    //   hide: shouldHideForCurrentClient("l3_name"),
    //   style: { "marginBottom": "10px" },
    // },
    {
      id: 4,
      isRequired: false,
      label: props.filterLabels?.level4,
      name: "level4",
      dropdownValue: level4,
      onDropDownChange: onLevel4Change,
      placeHolder: `Select ${props.filterLabels?.level4 ? props.filterLabels?.level4 : ""
        }`,
      optionsArray: level4Options?.length ? level4Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l4_name"),
      style: { "marginBottom": "10px" },
    },
    {
      id: 5,
      isRequired: false,
      label: props.filterLabels?.level5,
      name: "level5",
      dropdownValue: level5,
      onDropDownChange: onLevel5Change,
      placeHolder: `Select ${props.filterLabels?.level5 ? props.filterLabels?.level5 : ""
        }`,
      optionsArray: level5Options?.length ? level5Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l5_name")
    },
    {
      id: 6,
      isRequired: false,
      label: props.filterLabels?.level6,
      name: "level6",
      dropdownValue: level6,
      onDropDownChange: onLevel6Change,
      placeHolder: `Select ${props.filterLabels?.level6 ? props.filterLabels?.level6 : ""
        }`,
      optionsArray: level6Options?.length ? level6Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l6_name")
    },
    {
      id: 7,
      isRequired: false,
      label: props.filterLabels?.level7,
      name: "level7",
      dropdownValue: level7,
      onDropDownChange: onLevel7Change,
      placeHolder: `Select ${props.filterLabels?.level7 ? props.filterLabels?.level7 : ""
        }`,
      optionsArray: level7Options?.length ? level7Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l7_name")
    },
  

  ]

  const prep = [
    {
      id: 1,
      isRequired: true,
      label: props.filterLabels?.level1,
      name: "level1",
      value: level1,
      onChange: onLevel1Change,
      placeHolder: `Select ${props.filterLabels?.level1}`,
      options: level1Options?.length ? level1Options : [],
      style: { "marginBottom": "10px" },
    },
    getFilterForClients({
      id:2,
      isRequired: true,
      label:props.filterLabels?.level2, 
      name: "level2", 
      dropdownValue: level2,
      onDropDownChange: onLevel2Change, 
      placeHolder: `Select ${props.filterLabels?.level2 ? props.filterLabels?.level2 : ""}`, 
      optionsArray:  level2Options?.length ? level2Options : [],
      style: { "marginBottom": "10px" },
      isStandAlone: false,
      level: "l2_name"
    }, "dashboard"),
    getFilterForClients({
      id: 3,
      label: props.filterLabels?.level3,
      name: "level3",
      dropdownValue: level3,
      onDropDownChange: onLevel3Change,
      placeHolder: `Select ${props.filterLabels?.level3 ? props.filterLabels?.level3 : ""}`,
      optionsArray:level3Options?.length ? level3Options : [],
      hide: shouldHideForCurrentClient("l3_name"),
      style: { "marginBottom": "10px" },
      isStandAlone: false,
      level: "l3_name"
    }),
    // {
    //   id: 2,
    //   isRequired: false,
    //   label: props.filterLabels?.level2,
    //   name: "level2",
    //   dropdownValue: level2,
    //   onDropDownChange: onLevel2Change,
    //   placeHolder: `Select ${props.filterLabels?.level2 ? props.filterLabels?.level2 : ""
    //     }`,
    //   optionsArray: level2Options?.length ? level2Options : [],
    //   isMultiSelect: true,
    //   style: { "marginBottom": "10px" },
    // },
    // {
    //   id: 3,
    //   isRequired: false,
    //   label: props.filterLabels?.level3,
    //   name: "level3",
    //   dropdownValue: level3,
    //   onDropDownChange: onLevel3Change,
    //   placeHolder: `Select ${props.filterLabels?.level3 ? props.filterLabels?.level3 : ""
    //     }`,
    //   optionsArray: level3Options?.length ? level3Options : [],
    //   isMultiSelect: true,
    //   hide: shouldHideForCurrentClient("l3_name"),
    //   style: { "marginBottom": "10px" },
    // },
    {
      id: 4,
      isRequired: false,
      label: props.filterLabels?.level4,
      name: "level4",
      dropdownValue: level4,
      onDropDownChange: onLevel4Change,
      placeHolder: `Select ${props.filterLabels?.level4 ? props.filterLabels?.level4 : ""
        }`,
      optionsArray: level4Options?.length ? level4Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l4_name"),
      style: { "marginBottom": "10px" },

    },
    {
      id: 6,
      isRequired: false,
      label: props.filterLabels?.level5,
      name: "level5",
      dropdownValue: level5,
      onDropDownChange: onLevel5Change,
      placeHolder: `Select ${props.filterLabels?.level5 ? props.filterLabels?.level5 : ""
        }`,
      optionsArray: level5Options?.length ? level5Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l5_name")
    },
    {
      id: 7,
      isRequired: false,
      label: props.filterLabels?.level6,
      name: "level6",
      dropdownValue: level6,
      onDropDownChange: onLevel6Change,
      placeHolder: `Select ${props.filterLabels?.level6 ? props.filterLabels?.level6 : ""
        }`,
      optionsArray: level6Options?.length ? level6Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l6_name")
    },
    {
      id: 8,
      isRequired: false,
      label: props.filterLabels?.level7,
      name: "level7",
      dropdownValue: level7,
      onDropDownChange: onLevel7Change,
      placeHolder: `Select ${props.filterLabels?.level7 ? props.filterLabels?.level7 : ""
        }`,
      optionsArray: level7Options?.length ? level7Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l7_name")
    },
    {
      id: 5,
      isRequired: false,
      label: "DC",
      name: "dc",
      dropdownValue: dc,
      onDropDownChange: onDcChange,
      placeHolder: `Select ${props.filterLabels?.dc ? props.filterLabels?.dc : ""}`,
      optionsArray: dcOptions?.length ? dcOptions : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("dc")
    },
    // {
    //   id: 9,
    //   isRequired: false,
    //   label: "Factory Type",
    //   name: "factoryType",
    //   dropdownValue: factoryType,
    //   onDropDownChange: onFactoryTypeChange,
    //   placeHolder: `Select ${props.filterLabels?.factoryType ? props.filterLabels?.factoryType : ""}`,
    //   optionsArray: factoryTypeOptions?.length ? factoryTypeOptions : [],
    //   isMultiSelect: true,
    //   hide: shouldHideForCurrentClient("factoryType")
    // },
  ];

  return (
    <>
      {/* <Notification /> */}
      <Filter data={props.isViewAllocation ? viewAllocationPrep : prep} />
      {
        <div className="fill col-md-3">
          <label className="fnt-md fnt-bold fnt-bold ">{dateRangeLabel}</label>
          <DateRangePicker
            startDate={min_date}
            startDateId="s_id"
            endDate={max_date}
            endDateId="e_id"
            onDatesChange={({ startDate, endDate }) => {
              setDates(startDate, endDate, "Static");
            }}
            focusedInput={focusedInput}
            onFocusChange={(e) => setFocusedInput(e)}
            displayFormat="MM-DD-YYYY"
            isOutsideRange={isOutsideRange}
            showClearDates
            withPortal
            numberOfMonths={3}
          />
        </div>
      }
      {!props.isViewAllocation && (
        <div className="fill col-md-3 pt-2">
          <label className="fnt-md fnt-bold fnt-bold ">
            Last Allocation (Dates Not Within)
          </label>
          <DateRangePicker
            startDate={min_allocation_date}
            startDateId="sa_id"
            endDate={max_allocation_date}
            endDateId="ea_id"
            onDatesChange={({ startDate, endDate }) => {
              setAllocationDates(startDate, endDate, "Static");
            }}
            focusedInput={focusedInputAllocationDate}
            onFocusChange={(e) => setFocusedInputAllocationDate(e)}
            displayFormat="MM-DD-YYYY"
            isOutsideRange={isFutureDate}
            showClearDates
            withPortal
            numberOfMonths={3}
          />
        </div>
      )}
      <div className="col-md-3 mt-2">
        <button
          onClick={applyFilters}
          className="btn btn-primary filter-constraints-mode"
          title="Apply filters"
        >
          <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
        </button>
        <button
          onClick={() => resetFilters("All")}
          className="btn undo-constraints-mode"
          title="Reset filters"
        >
          <i className="fa fa-undo mr-2"></i>Reset
        </button>
      </div>
    </>
  );
}



const mapStateToProps = ({ dashboard }) => ({
  level1Options: dashboard.level1Options,
  level2Options: dashboard.level2Options,
  level3Options: dashboard.level3Options,
  level4Options: dashboard.level4Options,
  level5Options: dashboard.level5Options,
  level6Options: dashboard.level6Options,
  level7Options: dashboard.level7Options,
  dcOptions: dashboard.dcOptions,
  factoryTypeOptions: dashboard.factoryTypeOptions,
  filterLabels: dashboard.filterlabels,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFilterData: (payload, filterType) => dispatch(fetchFilterData(payload, filterType)),
  resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  getChartData: (payload) => dispatch(getChartData(payload)),
  getStyleInventoryData:(payload)=>dispatch(getStyleInventoryData(payload)),
  getRecommendedActionstData: (payload) => dispatch(getRecommendedActionstData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFilters);
