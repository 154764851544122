import React, { useEffect } from "react";
import { connect } from "react-redux";
import PageLoader from "../../../components/Loader/PageLoader";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import {
  getStoreCapacity,
  saveStoreCapacity,
  reset,
} from "./actions/StoreCapacityAction";
import "../../ProductStoreStatus/ProductStoreStatus.css";
import { useState } from "react";
import InputCell from "../../../components/Table/Cellrenderer/InputCell";
import ColumnFilter from "../../UserManagement/ColumnFilter";
import { cloneDeep } from "lodash";
import * as Notify from "../../../components/Notification/Notifications";
import { calculateMaximumEaches, calculateMaximumPacks } from "./helperFunctions";
import { isEmpty } from "lodash";
import EditIconCell from "../../../components/Table/Cellrenderer/EditIconCell";
import EditPackDetails from "../ProductView/EditPackDetails";
import { getPackDetails, resetEditUnitsSuccess } from "../FinalizeAction";
import AvailableTable from "./AvailableTable";
import { useRef } from "react";
import { STORE_CAPACITY_NO_RECORDS_MSG } from "./StoreCapacityConstants";

const PARALLEL_API_COUNT = 10;

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const StoreCapacity = (props) => {
  const {
    allocationCode,
    getStoreCapacityData,
    saveStoreCapacityData,
    storeCapacityData,
    storeCapacityLoading,
    activeTab,
    saveSuccess,
    saveError,
    articles,
    isFinalized
  } = props;

  const [tableData, setData] = useState([]);
  const [dcAvailableMap, setDcMap] = useState({});
  const [editData, setEditData] = useState({});
  const [dcUpdated, setDcUpdated] = useState({});
  const [currentAPICallIndex, setCurrentIndex] = useState(0);
  const [tableColumns, setTableColumns] = useState([]);
  const [packsAvailable, setPacksAvailable] = useState({});
  const [packEditData, setPackEditData] = useState({});
  const [packsUpdated, setPacksUpdated] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [articleData, setArticleData] = useState([]);
  const childRef = useRef();

  const columns = [
    {
      id: "expander", // Make sure it has an ID
      Header: " ",
      sticky: "left",
      columns: [{
        Header: "  ",
        sticky: "left",
        columns: [{
          Header: "Store number",
          accessor: "store_code",
          Cell: ({ value, row }) =>
            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            // to build the toggle for expanding a row
            row.canExpand ? (
              <div className="row" {...row.getToggleRowExpandedProps()}>
                <span className="expand__text__number">{value}</span>
                {row.isExpanded ? (
                  <div className="cursor-pointer missing-config-color">
                    <i
                      className="fa fa-angle-down fa-lg ml-2 expand-collapse"
                      title="Collpase"
                    ></i>
                  </div>
                ) : (
                  <div className="cursor-pointer missing-config-color">
                    <i
                      className="fa fa-angle-right fa-lg ml-2 expand-collapse"
                      title="Expand"
                    ></i>
                  </div>
                )}
              </div>
            ) : (
              <span className="store-group-mapping-color-width-height"> </span>
            ),
          disableFilters: true,
        }]
      }],
    },
    {
      Header: "Store Name",
      accessor: "store_name",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span className="store-group-mapping-color-width-height"> </span>
        ),
      disableFilters: true,
      width: 200,
    },
    {
      Header: "Department",
      accessor: "dept",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span className="store-group-mapping-color-width-height"> </span>
        ),
      disableFilters: true,
    },
    {
      Header: "Capacity Category",
      accessor: "capacity_category",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span className="store-group-mapping-color-width-height"> </span>
        ),
      disableFilters: true,
    },
    {
      Header: "Store Capacity",
      accessor: "capacity",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span className="store-group-mapping-color-width-height"> </span>
        ),
      disableFilters: true,
    },
    {
      Header: "Store Inventory",
      accessor: "store_inv",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span className="store-group-mapping-color-width-height"> </span>
        ),
      disableFilters: true,
    },
    {
      Header: "Allocated Reserve",
      accessor: "allocated_reserve",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span className="store-group-mapping-color-width-height"> </span>
        ),
      disableFilters: true,
    },
    {
      Header: "Net Store Capacity Available",
      accessor: "net_capacity_available",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span className="store-group-mapping-color-width-height"> </span>
        ),
      disableFilters: true,
    },
    {
      Header: '# Style Color',
      accessor: "article_count",
      disableFilters: true,
    },
    {
      Header: "Style Color",
      accessor: "article",
      disableFilters: true,
    },
    {
      Header: "OH+OO+IT",
      accessor: "oh_oo_it",
      disableFilters: true,
    },
    {
      Header: "Total Allocated Units",
      accessor: row => Object.values(row.allocated_quantity).reduce((pre, curr) => pre + curr),
      disableFilters: true,
    },
    {
      Header: "Pack ID",
      accessor: row => row.article ? row.packs : "",
      disableFilters: true,
    },
    {
      Header: "Packs Available",
      identify: "packs_available",
      disableFilters: true,
    },
    {
      Header: "Packs Allocated",
      identify: "packs",
      disableFilters: true,
    },
    {
      Header: "Eaches Allocated",
      identify: "eaches",
      disableFilters: true,
    },
    {
      Header: "Eaches Available",
      identify: "dc_available",
      disableFilters: true,
    },
  ];

  useEffect(() => {
    if (activeTab === "fsc") {
      setTableColumns(columns);
      getData();
    }
  }, [activeTab]);

  const getData = () => {
    setData([]);
    let req = { 
      allocationcode: allocationCode 
    }
    if(articles?.length) {
      req["article"] = articles
    }
    getStoreCapacityData(req);
  };

  useEffect(() => {
    if (storeCapacityData?.data?.length) {
      setData(storeCapacityData);
      createArticleData(storeCapacityData?.data)
    }
    else {
      setData([])
      setArticleData([])
    }
  }, [storeCapacityData]);

  useEffect(() => {
    // This IF condition checking whether all the parallel api calls are done or not
    let allArticles = [];
    if (!isEmpty(editData)) {
      allArticles = Object.keys(editData);
    }
    if (!isEmpty(packEditData)) {
      allArticles = [...allArticles, ...Object.keys(packEditData)];
    }
    if (saveSuccess && currentAPICallIndex >= allArticles?.length) {
      Notify.success("Allocated Units Edited Sucessfully!!");
      props.reset();
      setDcMap({});
      setPacksAvailable({});
      setEditData({});
      setDcUpdated({});
      getData();
    } else if (saveSuccess) { // Parallel api calls once the previous set of calls getting completed
      let originalAllocationCode =
        allocationCode === props.originalAllocationCode
          ? ""
          : props.originalAllocationCode;
      saveStoreCapacityData({
        allocationCode,
        dcAvailableMap,
        packsAvailable,
        editData,
        packEditData,
        originalAllocationCode,
        index: currentAPICallIndex,
        API_COUNT: PARALLEL_API_COUNT
      });
      setCurrentIndex((prev) => prev + PARALLEL_API_COUNT); // Setting the current index of an article to have the parallel save api calls
    }  
  }, [saveSuccess]);

  useEffect(() => {
    if (saveError) {
      Notify.error(saveError);
      props.reset();
    }
  }, [saveError]);

  const onEdit = (
    dc,
    article,
    store_code,
    size,
    updatedDCValue,
    entered_value,
    focusedValue,
    eachesDCAvailable
  ) => {
    setDcUpdated((old) => {
      return {
        ...old,
        [article]: {
          ...old?.[article],
          [dc]: eachesDCAvailable,
        },
      };
    });

    setDcMap((old) => {
      return {
        ...old,
        [article]: {
          ...old?.[article],
          [dc]: {
            ...old?.[article]?.[dc],
            [size]: updatedDCValue,
          },
        },
      };
    });

    setEditData((old) => {
      return {
        ...old,
        [article]: {
          ...old?.[article],
          [store_code]: {
            ...old?.[article]?.[store_code],
            [dc]: {
              ...old?.[article]?.[store_code]?.[dc],
              [size]: entered_value,
            },
          },
        },
      };
    });
    childRef.current.updateAvailableData(entered_value, focusedValue, article, size, dc)
  };

  const onPackEdit = (
    dc,
    article,
    store_code,
    packs,
    updatedDCValue,
    entered_value,
    focusedValue) => {
      let dcmap = cloneDeep(packsUpdated);
      if (dcmap[article]) {
        dcmap[article][dc] = updatedDCValue;
      } else {
        dcmap[article] = { [dc]: updatedDCValue };
      }
      setPacksUpdated(dcmap);
  
      setPacksAvailable((old) => {
        return {
          ...old,
          [article]: {
            ...old?.[article],
            [dc]: {
              ...old?.article?.[dc],
              [packs]: updatedDCValue
            },
          },
        };
      });
  
      setPackEditData((old) => {
        return {
          ...old,
          [article]: {
            ...old?.[article],
            [store_code]: {
              ...old?.[article]?.[store_code],
              [dc]: {
                ...old?.article?.[dc],
                [packs]: entered_value
              },
            },
          },
        };
      });
      childRef.current.updateAvailableData(entered_value, focusedValue, article, null, dc, true)
  };
  

  //This function will call the save api for the first time among the parallel calls
  const onSave = () => {
    setCurrentIndex(1);
    let originalAllocationCode =
      allocationCode === props.originalAllocationCode
        ? ""
        : props.originalAllocationCode;
    saveStoreCapacityData({
      allocationCode,
      dcAvailableMap,
      packsAvailable,
      editData,
      packEditData,
      originalAllocationCode,
      index: 0,
      API_COUNT: 1
    });
  };

  const getMaximum = (instance, dcKey, size, index) => {
    const maxValue = size ? calculateMaximumEaches(editData, tableData.data, dcAvailableMap, instance, dcKey, size, index) 
    : calculateMaximumPacks(packEditData, tableData.data, packsAvailable, instance, dcKey);
    return maxValue;
  };

  const getEachesDCAvailable = (row, dcKey, store_code, size, value, focusedValue) => {
    // const preDCValue = dcUpdated?.[row.article]?.[dcKey] ? dcUpdated?.[row.article]?.[dcKey]
    // : Object.values(row.eaches_available[dcKey]).reduce((pre, curr) => pre + curr)
    const preDCValue = row[`dcavailable${dcKey}`];


    // const preSizeValue =
    //   editData?.[row.article]?.[store_code]?.[dcKey]?.[size] >= 0
    //     ? editData?.[row.article]?.[store_code]?.[dcKey]?.[size]
    //     : row.eaches_size_value?.[dcKey]?.[row?.size?.[dcKey]?.indexOf(size)];
    const preSizeValue = focusedValue
    const updatedDCValue = (preDCValue || 0) - (Number(value) - (preSizeValue || 0));
    return updatedDCValue;
  }

  useEffect(() => {
    if (tableData?.sizes?.length) {
      let eachesIndex = columns.findIndex((col) => col.identify === "eaches");
      let dcIndex = columns.findIndex((col) => col.identify === "dc_available");
      let packsIndex = columns.findIndex((col) => col.identify === "packs");
      let packsAvailableIndex = columns.findIndex((col) => col.identify === "packs_available");

      columns[eachesIndex].columns = [];
      columns[dcIndex].columns = [];
      columns[packsIndex].columns = [];
      columns[packsAvailableIndex].columns = [];

      tableData?.dcs?.forEach((dc) => {
        let name = Object.values(dc)[0];
        let key = Object.keys(dc)[0];
        columns[eachesIndex].columns.push({
          Header: name,
          id: `${name + eachesIndex}`,
          accessor: (row) => row.loose_units_allocated[key],
          disableFilters: true,
        });
        columns[dcIndex].columns.push({
          Header: name,
          // id: `${name + dcIndex}`,
          accessor: `dcavailable${key}`,
          // Cell: (instance) => {
          //   const row = instance.row.original;
          //   return row?.eaches_available?.hasOwnProperty(key) ? (
          //     <span>
          //       {Number(dcUpdated?.[row.article]?.[key]) >= 0
          //         ? dcUpdated?.[row.article]?.[key]
          //         : Object.values(row.eaches_available[key]).reduce((pre, curr) => pre + curr)}
          //         </span>
          //   ) : (
          //     ""
          // )},
          disableFilters: true,
        });
        columns[packsAvailableIndex].columns.push({
          Header: name,
          accessor: `packsavailable${key}`,
          // id: `${name + packsAvailableIndex}`,
          // accessor: (row) =>
          //   row?.article ? Number(packsUpdated?.[row.article]?.[key]) >= 0
          //     ? packsUpdated?.[row.article]?.[key]
          //     : row?.packs_available?.[key]
          //     : "",
          disableFilters: true,
        });
        columns[packsIndex].columns.push({
          Header: name,
          id: `${name + packsIndex}`,
          accessor: (row) =>  row?.[name + packsIndex] || row?.packs_allocated_dc?.[key],
          Cell: (instance) =>
            !instance.row.canExpand &&
            instance.row.original?.is_pack &&
            instance.row.original?.packs_available?.hasOwnProperty(key) ? (
              <InputCell
                {...instance}
                style={{ textAlign: "center" }}
                min={0}
                max={getMaximum(instance, key)}
                step={1}
                type="number"
                blurHandler={(rowIdx, columnId, values, row_id, temp_id, row1, focusedValue) => {
                if(Number(focusedValue) !== Number(values)) {
                  const parentRowId = Number(
                    instance.row.id.split(".").slice(0, -1).join(".")
                  );
                  const store_code = tableData?.data?.[parentRowId].store_code;
                  const row = instance.row.original;
                  // const preDCValue =
                  //   packsUpdated?.[row.article]?.[key] >= 0
                  //     ? packsUpdated[row.article][key]
                  //     : (row.packs_available[key] || 0);
                  // const preSizeValue =
                  //   packEditData?.[row.article]?.[store_code]?.[key]?.[row.packs] >= 0
                  //     ? packEditData?.[row.article]?.[store_code]?.[key]?.[row.packs]
                  //     : (row.packs_allocated_dc[key] || 0);
                  const preDCValue = row[`packsavailable${key}`]
                  const preSizeValue = focusedValue;
                  const updatedDCValue = preDCValue - (Number(values) - preSizeValue);

                  onPackEdit(
                    key,
                    row.article,
                    store_code,
                    row.packs,
                    updatedDCValue,
                    Number(values),
                    focusedValue
                  );
                  instance.updateMyData(rowIdx, `packsavailable${key}`, updatedDCValue, row_id);
                }
                }}
              />
            ) : (
              instance?.value >= 0 ? instance?.value : ""
          ),
          disableFilters: true,
        });
      });

      tableData?.sizes?.forEach((size) => {
        let index;
        columns.push({
          Header: size,
          columns: [
            {
              Header: "Eaches Allocated",
              columns: tableData.dcs.map((dc) => {
                let dcKey = Object.keys(dc)[0];
                return {
                  Header: Object.values(dc)[0],
                  id: dcKey + size,
                  accessor: (row) => {
                      index = row.size?.[dcKey]?.indexOf(size)
                    return row?.[dcKey + size] || row.eaches_size_value?.[dcKey]?.[index]
                  },
                  Cell: (instance) => {
                    index = instance.row?.original?.size?.[dcKey]?.indexOf(size)
                    return !isFinalized && !instance.row.canExpand &&
                    instance.row.original?.is_eaches &&
                    instance.row.original?.eaches_available?.[dcKey]?.hasOwnProperty(size) ? (
                      <InputCell
                        {...instance}
                        style={{ textAlign: "center" }}
                        min={0}
                        max={getMaximum(instance, dcKey, size, index)}
                        step={1}
                        type="number"
                        blurHandler={(rowIdx, columnId, values, row_id, temp_id, row1, focusedValue) => {
                        if(Number(focusedValue) !== Number(values)) {
                          const parentRowId = Number(
                            instance.row.id.split(".").slice(0, -1).join(".")
                          ); // string
                          const store_code =
                            tableData?.data?.[parentRowId].store_code;
                          const row = instance.row.original;
                          const preDCValue = dcAvailableMap?.[row.article]?.[dcKey]?.[size] >= 0
                          ? dcAvailableMap?.[row.article]?.[dcKey]?.[size]
                          : row.eaches_available?.[dcKey]?.[size]

                          const preSizeValue =
                            editData?.[row.article]?.[store_code]?.[dcKey]?.[
                              size
                            ] >= 0
                              ? editData?.[row.article]?.[store_code]?.[
                                  dcKey
                                ]?.[size]
                              : row.eaches_size_value?.[dcKey]?.[row?.size?.[dcKey]?.indexOf(size)];
                          const updatedDCValue =
                            (preDCValue || 0) - (Number(values) - (preSizeValue || 0));

                          const eachesDCAvailable = getEachesDCAvailable(row, dcKey, store_code, size, values, focusedValue)
                          onEdit(
                            dcKey,
                            row.article,
                            store_code,
                            size,
                            updatedDCValue,
                            Number(values),
                            focusedValue,
                            eachesDCAvailable
                          );
                          instance.updateMyData(rowIdx, `dcavailable${dcKey}`, eachesDCAvailable, row_id);
                        }
                        }}
                      />
                    ) : (
                      instance?.value >= 0 ? instance?.value : ""
                    )
                  },
                  disableFilters: true,
                };
              }),
            },
            {
              Header: "Allocated Quantity",
              columns: tableData.dcs.map((dc) => {
                let dcKey = Object.keys(dc)[0];
                return {
                  Header: Object.values(dc)[0],
                  id: "allocated"+dcKey + size,
                  accessor: (row) => {
                      index = row.size?.[dcKey]?.indexOf(size)
                    return row?.["allocated"+dcKey + size] || row.size_value?.[dcKey]?.[index]
                  },
                  Cell: (instance) => instance?.value >= 0 ? instance?.value : "",
                  disableFilters: true,
                };
              }),
            },
          ],
        });
      });
      // columns.push({
      //   Header: "",
      //   id: "actions",
      //   sticky: "right",
      //   columns: [
      //     {
      //         Header: "Actions",
      //         accessor: "actions",
      //         Cell: (instance) => (
      //           !instance.row.canExpand && instance.row.original?.is_eaches && instance.row.original?.is_pack ?
      //             <div>
      //                 <EditIconCell
      //                     handleEdit={() => {onEditClick(instance.row.original)}}
      //                     instance={instance}
      //                 ></EditIconCell>
      //             </div>
      //             : ""
      //         ),
      //         width: 100,
      //         resizable: true,
      //         disableFilters: true,
      //     }
      // ]
      // });
      setTableColumns(columns);
    }
  }, [tableData]);

  const onEditClick = (row) => {
    let req = {
      allocationcode: allocationCode,
      article: row.article,
      store_code: row.store_code,
    };
    setPopupData(req);
    props.getPackDetails(req);
    setShowEditModal(true);
  };

  const closeEditModal = (callAllApi) => {
    setShowEditModal(false);
    props.resetEditSuccess();
    if (callAllApi) {
      getData();
    }
}

const createArticleData = (data) => {
  let articles = [];
  data.forEach(row => {
    articles = articles.concat(row.subRows)
  })
  setArticleData(articles);
}

  return (
    <>
      <div className="store__view__tables p-4">
        <PageLoader loader={storeCapacityLoading} gridLoader={true}>
          {!!articleData?.length && (
            <AvailableTable
              ref={childRef}
              data={articleData}
              tableData={tableData}
            />
          )}
          {!!tableData?.data?.length || storeCapacityLoading ? (
            <>
              <ReactTableWithPlugins
                tableWrapperStyle={{ height: "fit-content", maxHeight: "60rem" }}
                headerWrapperStyle={{ position: "sticky", top: 0, zIndex: "4" }}
                embededScroll
                data={tableData?.data || []}
                columns={tableColumns}
                containSubRow={true}
                // shouldPagination
                renderMarkup="TableMarkup"
                keyRT="sortAndSearch"
                tableId="store_capacity"
                hideColumnsFilter
                isHideCount={true}
                hideDropdown
                totalCount={tableData?.data?.length}
              />
              <div className="row justify-content-center">
              <button
                onClick={onSave}
                className="btn btn-primary products_lower_Div_button"
                disabled={
                  !Object.keys(dcAvailableMap)?.length &&
                  !Object.keys(packsAvailable)?.length
                }
              >
                Save
              </button>
            </div>
          </>
          ) : (
            <div className="empty warning_container">
              {STORE_CAPACITY_NO_RECORDS_MSG}
              {/* <p>{LOST_SALES_NO_RECORDS_HELPER_MSG}</p> */}
            </div>
          )}
          
        </PageLoader>
      </div>
      {showEditModal ? (
        <EditPackDetails
          showAlert={true}
          closeAlert={closeEditModal}
          data={popupData}
          originalAllocationCode={props.originalAllocationCode}
          // articles={props?.articles}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = ({ storeCapacity }) => {
  return {
    storeCapacityLoading: storeCapacity.storeCapacityLoading,
    storeCapacityError: storeCapacity.storeCapacityError,
    storeCapacityData: storeCapacity.storeCapacityData,
    newAllocationCode: storeCapacity.alloacationCode,
    saveSuccess: storeCapacity.saveSuccess,
    saveError: storeCapacity.saveError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStoreCapacityData: (payload) => dispatch(getStoreCapacity(payload)),
  saveStoreCapacityData: (payload) => dispatch(saveStoreCapacity(payload)),
  getPackDetails: (payload) => dispatch(getPackDetails(payload)),
  reset: () => dispatch(reset()),
  resetEditSuccess: () => dispatch(resetEditUnitsSuccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreCapacity);
