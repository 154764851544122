import React, { useEffect, useRef, useState } from "react";
import ColumnFilter from "../../UserManagement/ColumnFilter";
import { DAILY_SUMMARY_TABLE_HEADERS } from "./DailySummaryConstants";
import PageLoader from "../../../components/Loader/PageLoader";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import { connect } from "react-redux";
import { scrollIntoView} from "../../../utils/commonUtilities";
import { fetchSKUData } from "./DailySummaryAction";
import ExportFile from "../../../components/ExportFile/ExportFile";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const SKUStoreLevelDetails = (props) => {
  const [colums, setColums] = useState([]);

  const tableRef = useRef();

  useEffect(() => {
    props.fetchSKUData({ request: props.req });
  }, [props.req]);

  const skuStoreTableColumns = React.useMemo(
    () => [
      {
        Header: DAILY_SUMMARY_TABLE_HEADERS.ALLOCATION_ID,
        accessor: "plan_description",
        Filter: ColumnFilter,
        width: 350,
      },
      {
        Header: DAILY_SUMMARY_TABLE_HEADERS.INVENTORY_SOURCE,
        accessor: "inventory_source",
        Cell: (inst) => inst?.value,
        disableFilters: true,
      },
      {
        Header: DAILY_SUMMARY_TABLE_HEADERS.STORE_ID,
        accessor: "store",
        Filter: ColumnFilter,
        width: 200,
      },
      {
        Header: "Units Allocated",
        columns: [],
      },
      {
        Header: "OH",
        columns: [],
      },
    ],
    [props.sizeData]
  );

  useEffect(() => {
    if (props.tableData?.length) scrollIntoView(tableRef);
  }, [props.tableData]);

  useEffect(() => {
    if (props.sizeData.length) {
      props.sizeData?.forEach((val) => {
        skuStoreTableColumns[3]?.columns.push({
          Header: val,
          accessor: (row) => row?.units_allocated?.[val] || 0,
          Filter: ColumnFilter,
        });
        skuStoreTableColumns[4]?.columns.push({
          Header: val + " ",
          accessor: (row) => row?.dc_available?.[val] || 0,
          Filter: ColumnFilter,
        });
      });
    }
    setColums(skuStoreTableColumns);
  }, [props.sizeData]);

  return (
    <>
      <div>
        <PageLoader loader={props.loading} gridLoader={true}>
          {props.error ? (
            <div className="error">
              {props.tableDataError || "Something Went Wrong!!"}
            </div>
          ) : (
            <>
              <div style={{ paddingTop: "3rem" }} ref={tableRef}>
                <div className="deepdive__export__wrapper">
                  {props?.tableData.length ? (
                    <ExportFile
                      text={"Excel"}
                      data={props.skuExcelData}
                      sheetName={"Daily Allocation Summary"}
                      fileName={`daily_summary_${new Date().getTime()}`}
                      type={1}
                    />
                  ) : null}
                </div>
                <ReactTableWithPlugins
                  columns={colums}
                  data={props?.tableData?.length ? props.tableData : []}
                  renderMarkup="TableMarkup"
                  keyRT="sortAndSearch"
                  shouldPagination
                  totalRecordsLen={props?.tableData?.length}
                  tableId="skuStoreDailySummary"
                  tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                  headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                />
              </div>
            </>
          )}
        </PageLoader>
      </div>
    </>
  );
};

const mapStateToProps = ({ dailySummary }) => ({
  error: dailySummary.skuError,
  tableDataError: dailySummary.skuErrorMessage,
  loading: dailySummary.skuLoading,
  tableData: dailySummary.skuTableData,
  sizeData: dailySummary.sizeData,
  skuExcelData: dailySummary.skuExcelData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSKUData: (payload) => dispatch(fetchSKUData(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SKUStoreLevelDetails);
