import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import moment from "moment";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import {
    addDollar,
    addPer,
    numberUSFormatting,
    twoDecimal,
} from "../../../utils/formatters/valueFormatters";
import LinkCell from "../../../components/Table/Cellrenderer/LinkCell";

import {
    getStyleInventoryData,
    getStyleInvPopupData,
    getReviewRecommendData,
    resetDownloadIndex,
    getDownloadExcelData,
    resetPageIndex,
  } from "../DashboardActions";
import { getStrategyTableDataFromDashboard } from "../../Strategy/actions";

import * as Notify from '../../../components/Notification/Notifications'
import Notification from '../../../components/Notification/Notifications'

import { DashboardModal } from "../DashboardModal";
import { STRATEGY_PAGE } from "../../../constants/routeConstants";
import PageLoader from '../../../components/Loader/PageLoader';
import NumberRangeColumnFilter from '../../../components/Filters/NumberRangeColumnFilter';
import { levelFiltersLabelFormatter, updateArticleName } from "../../../utils/filterLevelMapping"
import { compareLowerCase, getLevelFiltersForReq } from '../../../utils/commonUtilities';
import ExportFile from '../../../components/ExportFile/ExportFile';
import CountrySplitFilter from "./CountrySplitFilter"
import ReactTollTip from '../../../components/Table/Cellrenderer/ReactToolTip';
import ColumnFilterServerSide from "../../../components/Filters/ColumnFilterServerSide";
import NumberRangeColumnFilterServerSide from "../../../components/Filters/NumberRangeColumnFilterServerSide";
import {
  getFinalCheckedRowsForCAData,
  getNewData,
  removeCheckedArticles,
} from "../../../utils/paginationHelper";
import { EXCEL_COLUMNS_FOR_FORMATTING } from '../../../components/ExportFile/ExportExcelConstants';
import DownloadProgressBar from '../../../components/DownloadProgressBar/DownloadProgressBar';


const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const StyleInventory = (props) => {
    const {
        downloadNextIndex,
        downloadExcelData,
        downloadTotalCount,
        resetDownloadIndex,
        reviewCall,
        stylesCount,
        reviewRequest,
      } = props;

    const [openStyleInvPopup, setopenStyleInvPopup] = useState(false)
    const [popupTitle, setpopupTitle] = useState(null)
    const [articleId, setarticleId] = useState(null)
    const [styleInvTableInst, setstyleInvTableInst] = useState(null)
    const [exportExcelData, setExcelData] = useState([])
    const [initialHiddenColumns, setHiddenColumns] = useState([])
    const [showTable, setShowTable] = useState(true)
    const [styleInvData, setStyleInvData] = useState([]);
    const [RTInstance, setRTInstance] = useState({});
    const [initialIndex, setInitialIndex] = useState(0);
    const [prevIndex, setPrevIndex] = useState(0);
    const [nextIndex, setNextIndex] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [searchTermReq, setSearchTermReq] = useState({});
    const [sortReq, setSortReq] = useState({});
    const [isFirstCall, setisFirstCall] = useState(true);
    const [downloadExcel, setDownloadExcel] = useState(false);
    const [downloadExcelLoading, setDownloadLoading] = useState(false);
    const [checkAll, setCheckAll] = useState([]);
    const [checkAllReqArr, setCheckAllReqArr] = useState([]);
    const [prevAction, setPrevAction] = useState("");
    const [isGetAllLoading, setIsGetAllLoading] = useState(false);
    const [checkAllData, setCheckAllData] = useState([]);
    const [checkedRows, setCheckedRows] = useState([]);
    const [initialSelectedRows, setInitialSelectedRows] = useState({});
    const [hideTotalRecords, setHideTotalRecords] = useState(true);
    const [hideTotalPages, setHideTotalPages] = useState(true);
    const [pageIndex, setPageIndex] = useState(0);
    const [dc, setDC] = useState();
    const [showCountryFilter, setShowCountryFilter] = useState(false);

    const countrySplitObj = {
        usa: ["ecm_lw_qty_us","bulk_remaining_intransit_us","ecm_oh_us","lw_qty_us", "lw_revenue_us", "lw_margin_us", "promo_us", "price_us", "bulk_remaining_us", "oh_us", "oo_us", "wos_us", 
            "si_us", "it_us"],
        can: ["ecm_lw_qty_ca","bulk_remaining_intransit_ca","ecm_oh_ca","lw_qty_ca", "lw_revenue_ca", "lw_margin_ca", "promo_ca", "price_ca", "bulk_remaining_ca", "oh_ca", "oo_ca", "wos_ca", 
            "si_ca", "it_ca"],
        mex: ["bulk_remaining_mx"],
        // agg: ["ecm_lw_qty","bulk_remaining_intransit","ecm_oh","lw_qty", "lw_revenue", "lw_margin", "promo", "price", "bulk_remaining", "oh", "oo", "wos", "si", "it"]
    }

    // useEffect(() => {
    //   setHiddenColumns([...countrySplitObj["usa"], ...countrySplitObj["can"], ...countrySplitObj["mx"]]);
    // }, [])

    const styleInvetoryCols = [
        {
            Header: " ",
            sticky: "left",
            columns: [
                {
                    Header: "Product Type",
                    accessor: "product_type",
                    Filter: (instance) => (
                        <ColumnFilterServerSide
                          {...instance}
                          searchTermValue={searchTermReq?.product_type?.value}
                          changeHandler={(term, id) =>
                            fecthDataWithSearchTerm(term, id, "string")
                          }
                        />
                      ),
                },
                {
                    Header: `${updateArticleName(false)} ID`,
                    accessor: "article",
                    width: 120,
                    Filter: (instance) => (
                        <ColumnFilterServerSide
                          {...instance}
                          searchTermValue={searchTermReq?.article?.search}
                          placeholder={"Search in bulk by ','"}
                          changeHandler={(term, id) =>
                            fecthDataWithSearchTerm(term, id, "array", ",")
                          }
                        />
                      ),
                    // sticky: "left"
                },
                {
                    Header: "Style ID",
                    accessor: "style_code",
                    Filter: (instance) => (
                        <ColumnFilterServerSide
                          {...instance}
                          searchTermValue={searchTermReq?.style_code?.search}
                          placeholder={"Search in bulk.."}
                          changeHandler={(term, id) =>
                            fecthDataWithSearchTerm(term, id, "array")
                          }
                        />
                    ),
                    filter: 'bulkFilterSpaceSeperated',                    
                },
                {
                    Header: "Color ID",
                    accessor: "color_code",
                    Filter: (instance) => (
                        <ColumnFilterServerSide
                          {...instance}
                          searchTermValue={searchTermReq?.color_code?.search}
                          placeholder={"Search in bulk.."}
                          changeHandler={(term, id) =>
                            fecthDataWithSearchTerm(term, id, "array")
                          }
                        />
                    ),
                    filter: 'bulkFilterSpaceSeperated',
                }
            ]
        },
        // {
        //     Header: "Style Description",
        //     accessor: "style_name",
        //     width: 250,
        //     Filter: ColumnFilter,
        //     // sticky: "left"
        // },
        // {
        //     Header: "Style Color",
        //     accessor: "color_desc",
        //     width: 200,
        //     Filter: ColumnFilter,
        //     // sticky: "left"
        // },
        {
            Header: "Assortment Indicator",
            accessor: "assortment_indicator",
            width: 250,
            Filter: (instance) => (
                <ColumnFilterServerSide
                  {...instance}
                  searchTermValue={searchTermReq?.assortment_indicator?.value}
                  changeHandler={(term, id) =>
                    fecthDataWithSearchTerm(term, id, "string")
                  }
                />
            ),
            // sticky: "left"
        },
        {
            Header: "Store Group",
            accessor: "store_groups",
            Cell: (instance) =>
            <div className="row min-100">
              <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                 <ReactTollTip {...instance} />         
              </span>
            </div>,
            width: 200,
            Filter: (instance) => (
                <ColumnFilterServerSide
                  {...instance}
                  searchTermValue={searchTermReq?.store_groups?.value}
                  changeHandler={(term, id) =>
                    fecthDataWithSearchTerm(term, id, "string")
                  }
                />
            ),
            // sticky: "left"
        },
        {
            Header: "Introduction Date",
            accessor: "intro_date",
            Cell: (instance) => (
                <div>
                    {instance?.row?.original.intro_date !== null ? instance?.row?.original?.intro_date: "-"}   
                </div>
            ),
            disableFilters: true,
            sortType: (rowA, rowB) => new Date(rowA?.original?.intro_date) >  new Date(rowB?.original?.intro_date)
            // sticky: "left"
        },
        {
            Header: "Last Allocated",
            accessor: "max_allocation_date",
            Cell: (instance) => (
                <div>
                    {instance?.row?.original.max_allocation_date !== null ? moment(instance?.row?.original?.max_allocation_date).format("MM-DD-YYYY"): "-"}   
                </div>
            ),
            disableFilters: true,
            // sortType: (rowA, rowB) => new Date(rowA?.original?.intro_date) >  new Date(rowB?.original?.intro_date)
            // sticky: "left"
        },
        // {
        //     Header: "Factory Type",
        //     accessor: "factory_type",
        //     width: 250,
        //     Filter: ColumnFilter,
        //     // sticky: "left"
        // },
        {
            Header: "Style Description",
            accessor: "style_name",
            width: 250,
            Filter: (instance) => (
                <ColumnFilterServerSide
                  {...instance}
                  searchTermValue={searchTermReq?.style_name?.value}
                  changeHandler={(term, id) =>
                    fecthDataWithSearchTerm(term, id, "string")
                  }
                />
            ),
            // sticky: "left"
        },
        {
            Header: "Style Color",
            accessor: "color_desc",
            width: 200,
            Filter: (instance) => (
                <ColumnFilterServerSide
                  {...instance}
                  searchTermValue={searchTermReq?.color_desc?.value}
                  changeHandler={(term, id) =>
                    fecthDataWithSearchTerm(term, id, "string")
                  }
                />
            ),
            // sticky: "left"
        },
        {
            Header: "Pack ID",
            accessor: "pack_id",
            Cell: (instance) => <ReactTollTip {...instance} />,
            Filter: (instance) => (
                <ColumnFilterServerSide
                  {...instance}
                  searchTermValue={searchTermReq?.pack_id?.value}
                  changeHandler={(term, id) =>
                    fecthDataWithSearchTerm(term, id, "string")
                  }
                />
            ),
            // sortType: "basic"
            sortType: (rowA, rowB, columnId) => compareLowerCase(rowA, rowB, columnId)
        },
        {
            Header: "Filters",
            columns: [
                {
                    Header: levelFiltersLabelFormatter("level1"),
                    accessor: "l1_name",
                    Filter: (instance) => (
                        <ColumnFilterServerSide
                          {...instance}
                          searchTermValue={searchTermReq?.l1_name?.value}
                          changeHandler={(term, id) =>
                            fecthDataWithSearchTerm(term, id, "string")
                          }
                        />
                    ),
                },
                {
                    Header: levelFiltersLabelFormatter("level2"),
                    accessor: "l2_name",
                    Filter: (instance) => (
                        <ColumnFilterServerSide
                          {...instance}
                          searchTermValue={searchTermReq?.l2_name?.value}
                          changeHandler={(term, id) =>
                            fecthDataWithSearchTerm(term, id, "string")
                          }
                        />
                    ),
                },
                {
                    Header: levelFiltersLabelFormatter("level3"),
                    accessor: "l3_name",
                    Filter: (instance) => (
                        <ColumnFilterServerSide
                          {...instance}
                          searchTermValue={searchTermReq?.l3_name?.value}
                          changeHandler={(term, id) =>
                            fecthDataWithSearchTerm(term, id, "string")
                          }
                        />
                    ),
                },
                {
                    Header: levelFiltersLabelFormatter("level4"),
                    accessor: "l4_name",
                    Filter: (instance) => (
                        <ColumnFilterServerSide
                          {...instance}
                          searchTermValue={searchTermReq?.l4_name?.value}
                          changeHandler={(term, id) =>
                            fecthDataWithSearchTerm(term, id, "string")
                          }
                        />
                    ),
                },
                {
                    Header: levelFiltersLabelFormatter("level5"),
                    accessor: "l5_name",
                    Filter: (instance) => (
                        <ColumnFilterServerSide
                          {...instance}
                          searchTermValue={searchTermReq?.l5_name?.value}
                          changeHandler={(term, id) =>
                            fecthDataWithSearchTerm(term, id, "string")
                          }
                        />
                    ),
                },
                {
                    Header: levelFiltersLabelFormatter("level6"),
                    accessor: "l6_name",
                    Filter: (instance) => (
                        <ColumnFilterServerSide
                          {...instance}
                          searchTermValue={searchTermReq?.l6_name?.value}
                          changeHandler={(term, id) =>
                            fecthDataWithSearchTerm(term, id, "string")
                          }
                        />
                    ),
                },
            ],
        },
        {
            Header: "Sales Performance(USA)",
            accessor: "usa_units",
            columns: [
                {
                    Header: "E-comm LW Sales Units ",
                    accessor: "ecm_lw_qty_us",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? numberUSFormatting(inst.value)
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.ecm_lw_qty_us?.value?.min}
                          maxSearchTermValue={searchTermReq?.ecm_lw_qty_us?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    defaultCanSort: true,
                    width: 200          
                },
                {
                    Header: "LW Sales Units",
                    accessor: "lw_qty_us",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? numberUSFormatting(inst.value)
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.lw_qty_us?.value?.min}
                          maxSearchTermValue={searchTermReq?.lw_qty_us?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    defaultCanSort: true

                },
                {
                    Header: "LW Revenue",
                    accessor: "lw_revenue_us",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addDollar(numberUSFormatting(inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.lw_revenue_us?.value?.min}
                          maxSearchTermValue={searchTermReq?.lw_revenue_us?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    defaultCanSort: true

                },
                {
                    Header: "LW Margin",
                    accessor: "lw_margin_us",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addDollar(numberUSFormatting(inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.lw_margin_us?.value?.min}
                          maxSearchTermValue={searchTermReq?.lw_margin_us?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "Promo %",
                    accessor: "promo_us",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addPer((inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.promo_us?.value?.min}
                          maxSearchTermValue={searchTermReq?.promo_us?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "AUR",
                    accessor: "price_us",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addDollar(numberUSFormatting(inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.price_us?.value?.min}
                          maxSearchTermValue={searchTermReq?.price_us?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                }]
        },
        {
            Header: "Sales Performance(CAN)",
            columns: [
                {
                    Header: "E-comm LW Sales Units ",
                    accessor: "ecm_lw_qty_ca",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? numberUSFormatting(inst.value)
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.ecm_lw_qty_ca?.value?.min}
                          maxSearchTermValue={searchTermReq?.ecm_lw_qty_ca?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    defaultCanSort: true,
                    width: 200

                },
                {
                    Header: "LW Sales Units",
                    accessor: "lw_qty_ca",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? numberUSFormatting(inst.value)
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.lw_qty_ca?.value?.min}
                          maxSearchTermValue={searchTermReq?.lw_qty_ca?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    defaultCanSort: true

                },
                {
                    Header: "LW Revenue",
                    accessor: "lw_revenue_ca",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addDollar(numberUSFormatting(inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.lw_revenue_ca?.value?.min}
                          maxSearchTermValue={searchTermReq?.lw_revenue_ca?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    defaultCanSort: true

                },
                {
                    Header: "LW Margin",
                    accessor: "lw_margin_ca",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addDollar(numberUSFormatting(inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.lw_margin_ca?.value?.min}
                          maxSearchTermValue={searchTermReq?.lw_margin_ca?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "Promo %",
                    accessor: "promo_ca",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addPer((inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.promo_ca?.value?.min}
                          maxSearchTermValue={searchTermReq?.promo_ca?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "AUR",
                    accessor: "price_ca",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addDollar(numberUSFormatting(inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.price_ca?.value?.min}
                          maxSearchTermValue={searchTermReq?.price_ca?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                }]
        },
        {
            Header: "LW Sales Performance",
            columns: [
                {
                    Header: "E-comm LW Sales Units ",
                    accessor: "ecm_lw_qty",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? numberUSFormatting(inst.value)
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.ecm_lw_qty?.value?.min}
                          maxSearchTermValue={searchTermReq?.ecm_lw_qty?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    defaultCanSort: true,
                    width: 200
                },
                {
                    Header: "LW Sales Units",
                    accessor: "lw_qty",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? numberUSFormatting(inst.value)
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.lw_qty?.value?.min}
                          maxSearchTermValue={searchTermReq?.lw_qty?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    defaultCanSort: true

                },
                {
                    Header: "LW Revenue",
                    accessor: "lw_revenue",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addDollar(numberUSFormatting(inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.lw_revenue?.value?.min}
                          maxSearchTermValue={searchTermReq?.lw_revenue?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    defaultCanSort: true

                },
                {
                    Header: "LW Margin",
                    accessor: "lw_margin",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addDollar(numberUSFormatting(inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.lw_margin?.value?.min}
                          maxSearchTermValue={searchTermReq?.lw_margin?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "LW Promo %",
                    accessor: "promo",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addPer((inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.promo?.value?.min}
                          maxSearchTermValue={searchTermReq?.promo?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "LW AUR",
                    accessor: "price",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addDollar(numberUSFormatting(inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.price?.value?.min}
                          maxSearchTermValue={searchTermReq?.price?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                }]
        },
        {
            Header: "WTD Sales Performance",
            columns: [
                {
                    Header: "E-comm WTD Sales Units ",
                    accessor: "ecm_tw_qty",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? numberUSFormatting(inst.value)
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.ecm_tw_qty?.value?.min}
                          maxSearchTermValue={searchTermReq?.ecm_tw_qty?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    defaultCanSort: true,
                    width: 200
                },
                {
                    Header: "WTD Sales Units",
                    accessor: "tw_qty",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? numberUSFormatting(inst.value)
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.tw_qty?.value?.min}
                          maxSearchTermValue={searchTermReq?.tw_qty?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    defaultCanSort: true

                },
                {
                    Header: "WTD Revenue",
                    accessor: "tw_revenue",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addDollar(numberUSFormatting(inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.tw_revenue?.value?.min}
                          maxSearchTermValue={searchTermReq?.tw_revenue?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    defaultCanSort: true

                },
                {
                    Header: "WTD Margin",
                    accessor: "tw_margin",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addDollar(numberUSFormatting(inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.tw_margin?.value?.min}
                          maxSearchTermValue={searchTermReq?.tw_margin?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "WTD Promo %",
                    accessor: "tw_promo",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addPer((inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.tw_promo?.value?.min}
                          maxSearchTermValue={searchTermReq?.tw_promo?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "WTD AUR",
                    accessor: "tw_price",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addDollar(numberUSFormatting(inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.tw_price?.value?.min}
                          maxSearchTermValue={searchTermReq?.tw_price?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                }]
        },
        {
            Header: "Inventory(USA)",
            columns: [
                {
                    Header: "Bulk Remaining",
                    accessor: "bulk_remaining_us",
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.bulk_remaining_us?.value?.min}
                          maxSearchTermValue={searchTermReq?.bulk_remaining_us?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    filter: 'between',
                },
                {
                    Header: "DC In Transit",
                    accessor: "bulk_remaining_intransit_us",
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.bulk_remaining_intransit_us?.value?.min}
                          maxSearchTermValue={searchTermReq?.bulk_remaining_intransit_us?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    filter: 'between',
                },
                {
                    Header: "E-comm On Hand ",
                    accessor: "ecm_oh_us",
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.ecm_oh_us?.value?.min}
                          maxSearchTermValue={searchTermReq?.ecm_oh_us?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "On Hand",
                    accessor: "oh_us",
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.oh_us?.value?.min}
                          maxSearchTermValue={searchTermReq?.oh_us?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "On Order",
                    accessor: "oo_us",
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.oo_us?.value?.min}
                          maxSearchTermValue={searchTermReq?.oo_us?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "In Transit",
                    accessor: "it_us",
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.it_us?.value?.min}
                          maxSearchTermValue={searchTermReq?.it_us?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "WOS",
                    accessor: "wos_us",
                    Cell: (inst) => (
                        <div>
                            {/* {inst.value}  */}
                            {inst.value || inst.value === 0 ? (inst.value) : null}
                        </div>
                    ),
                    // Filter: ColumnFilter
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.wos_us?.value?.min}
                          maxSearchTermValue={searchTermReq?.wos_us?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    // width: 200
                },
                {
                    Header: "Size Integrity (On Hand)",
                    accessor: "si_us",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addPer((inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.si_us?.value?.min}
                          maxSearchTermValue={searchTermReq?.si_us?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    width: 180
                    // Filter: NumberRangeColumnFilter,
                    // filter: 'between',
                    // width: 200
                },
            ],
        },
        {
            Header: "Inventory(CAN)",
            columns: [
                {
                    Header: "Bulk Remaining",
                    accessor: "bulk_remaining_ca",
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.bulk_remaining_ca?.value?.min}
                          maxSearchTermValue={searchTermReq?.bulk_remaining_ca?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    filter: 'between',
                },
                {
                    Header: "DC In Transit",
                    accessor: "bulk_remaining_intransit_ca",
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.bulk_remaining_intransit_ca?.value?.min}
                          maxSearchTermValue={searchTermReq?.bulk_remaining_intransit_ca?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    filter: 'between',
                },
                {
                    Header: "E-comm On Hand ",
                    accessor: "ecm_oh_ca",
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.ecm_oh_ca?.value?.min}
                          maxSearchTermValue={searchTermReq?.ecm_oh_ca?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "On Hand",
                    accessor: "oh_ca",
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.oh_ca?.value?.min}
                          maxSearchTermValue={searchTermReq?.oh_ca?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "On Order",
                    accessor: "oo_ca",
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.oo_ca?.value?.min}
                          maxSearchTermValue={searchTermReq?.oo_ca?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "In Transit",
                    accessor: "it_ca",
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.it_ca?.value?.min}
                          maxSearchTermValue={searchTermReq?.it_ca?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "WOS",
                    accessor: "wos_ca",
                    Cell: (inst) => (
                        <div>
                            {/* {inst.value}  */}
                            {inst.value || inst.value === 0 ? (inst.value) : null}
                        </div>
                    ),
                    // Filter: ColumnFilter
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.wos_ca?.value?.min}
                          maxSearchTermValue={searchTermReq?.wos_ca?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    // width: 200
                },
                {
                    Header: "Size Integrity (On Hand)",
                    accessor: "si_ca",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addPer((inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.si_ca?.value?.min}
                          maxSearchTermValue={searchTermReq?.si_ca?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    width: 180
                    // Filter: NumberRangeColumnFilter,
                    // filter: 'between',
                    // width: 200
                },
            ],
        },
        {
          Header: "Inventory (Mexico)",
          columns: [
              {
                  Header: "Bulk Remaining",
                  accessor: "bulk_remaining_mx",
                  Filter: (instance) => (
                      <NumberRangeColumnFilterServerSide
                        {...instance}
                        minSearchTermValue={searchTermReq?.bulk_remaining_mx?.value?.min}
                        maxSearchTermValue={searchTermReq?.bulk_remaining_mx?.value?.max}
                        clickHandler={(min, max, id) =>
                          fecthDataWithSearchTerm([min, max], id, "range")
                        }
                      />
                  ),
                  Cell: (inst) => numberUSFormatting(inst?.value),
                  filter: 'between',
              }
          ]
        },
        {
            Header: "Inventory",
            columns: [
                {
                    Header: "Bulk Remaining",
                    accessor: "bulk_remaining",
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.bulk_remaining?.value?.min}
                          maxSearchTermValue={searchTermReq?.bulk_remaining?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    filter: 'between',
                },
                {
                    Header: "DC In Transit",
                    accessor: "bulk_remaining_intransit",
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.bulk_remaining_intransit?.value?.min}
                          maxSearchTermValue={searchTermReq?.bulk_remaining_intransit?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    filter: 'between',
                },
                {
                    Header: "E-comm On Hand ",
                    accessor: "ecm_oh",
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.ecm_oh?.value?.min}
                          maxSearchTermValue={searchTermReq?.ecm_oh?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "On Hand",
                    accessor: "oh",
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.oh?.value?.min}
                          maxSearchTermValue={searchTermReq?.oh?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "On Order",
                    accessor: "oo",
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.oo?.value?.min}
                          maxSearchTermValue={searchTermReq?.oo?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "In Transit",
                    accessor: "it",
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.it?.value?.min}
                          maxSearchTermValue={searchTermReq?.it?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                },
                {
                    Header: "WOS",
                    accessor: "wos",
                    Cell: (inst) => (
                        <div>
                            {/* {inst.value}  */}
                            {inst.value || inst.value === 0 ? (inst.value) : null}
                        </div>
                    ),
                    // Filter: ColumnFilter
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.wos?.value?.min}
                          maxSearchTermValue={searchTermReq?.wos?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    // width: 200
                },
                {
                    Header: "Size Integrity (On Hand)",
                    accessor: "si",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addPer((inst.value))
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide
                          {...instance}
                          minSearchTermValue={searchTermReq?.si?.value?.min}
                          maxSearchTermValue={searchTermReq?.si?.value?.max}
                          clickHandler={(min, max, id) =>
                            fecthDataWithSearchTerm([min, max], id, "range")
                          }
                        />
                    ),
                    filter: 'between',
                    width: 180
                    // Filter: NumberRangeColumnFilter,
                    // filter: 'between',
                    // width: 200
                },
            ],
        },
        {
            Header: "Store Count",
            columns: [
                {
                    Header: "Stock Out",
                    accessor: "stock_out",
                    Cell: (inst) => (
                        <LinkCell
                            {...inst}
                            onClick={() =>
                                onStyleInvPopupHandler(
                                    "Stock Out",
                                    inst.row.values.article,
                                    "stockOut"
                                )
                            }
                        />
                    ),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: "Shortfall",
                    accessor: "shortfall",
                    Cell: (inst) => (
                        <LinkCell
                            {...inst}
                            onClick={() =>
                                onStyleInvPopupHandler(
                                    "Shortfall",
                                    inst.row.values.article,
                                    "shortFall"
                                )
                            }
                        />
                    ),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    // Filter: NumberRangeColumnFilter,
                    // filter: 'between',
                    // width: 200
                },
                {
                    Header: "Normal",
                    accessor: "normal",
                    Cell: (inst) => (
                        <LinkCell
                            {...inst}
                            onClick={() =>
                                onStyleInvPopupHandler(
                                    "Normal",
                                    inst.row.values.article,
                                    "normal"
                                )
                            }
                        />
                    ),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: "Excess",
                    accessor: "excess",
                    Cell: (inst) => (
                        <LinkCell
                            {...inst}
                            onClick={() =>
                                onStyleInvPopupHandler(
                                    "Excess",
                                    inst.row.values.article,
                                    "excess"
                                )
                            }
                        />
                    ),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
            ],
        },
    ];

    const onStyleInvPopupHandler = (popupTitle, articleId, storesFilter) => {
        setopenStyleInvPopup(true)
        setpopupTitle(popupTitle)
        setarticleId(articleId)
        let dc = props.filters?.dc ? Array.isArray(props.filters?.dc?.[0]) ? props.filters?.dc?.[0]?.map(a => a.value) : props.filters?.dc?.map(a => a.value) : []
        let payload = {
            l1_name: [
                Array.isArray(props.filters.l1_name)
                  ? props.filters.l1_name?.[0].value || props.filters.l1_name?.[0]
                  : props.filters.l1_name?.value,
              ],
              l2_name: props.filters?.l2_name?.[0]?.value
                ? getLevelFiltersForReq(props.filters?.l2_name)
                : props.filters?.l2_name,
              l3_name: props.filters?.l3_name?.[0]?.value
                ? getLevelFiltersForReq(props.filters?.l3_name)
                : props.filters?.l3_name,
              l4_name: props.filters?.l4_name?.[0]?.value
                ? getLevelFiltersForReq(props.filters?.l4_name)
                : props.filters?.l4_name,
              l5_name: props.filters?.l5_name?.[0]?.value
                ? getLevelFiltersForReq(props.filters?.l5_name)
                : props.filters?.l5_name,
              l6_name: props.filters?.l6_name?.[0]?.value
                ? getLevelFiltersForReq(props.filters?.l6_name)
                : props.filters?.l6_name,
              l7_name: props.filters?.l7_name?.[0]?.value
                ? getLevelFiltersForReq(props.filters?.l7_name)
                : props.filters?.l7_name,
              dc,
        }

        props.getStyleInvPopupData({ storesFilter, articleId, ...payload });
    }

    const closeAlert = () => {
        setopenStyleInvPopup(false)
    };

    const goToCreateAllocation = (styles, storeNumbers) => {
        let { start_date, end_date } = props?.filters;
        resetDownloadIndex();
        let dc = props.filters?.dc
          ? Array.isArray(props.filters?.dc?.[0])
            ? props.filters?.dc?.[0]?.map((a) => a.value)
            : props.filters?.dc?.map((a) => a.value)
          : [];
        let factory_type = props.filters?.factory_type
          ? Array.isArray(props.filters?.factory_type?.[0])
            ? props.filters?.factory_type?.[0]?.map((a) => a.value)
            : props.filters?.factory_type?.map((a) => a.value)
          : [];
        let payload = {
          l1_name: [
            Array.isArray(props.filters.l1_name)
              ? props.filters.l1_name?.[0].value
              : props.filters.l1_name?.value,
          ],
          l2_name: getLevelFiltersForReq(props.filters?.l2_name),
          l3_name: getLevelFiltersForReq(props.filters?.l3_name),
          l4_name: getLevelFiltersForReq(props.filters?.l4_name),
          l5_name: getLevelFiltersForReq(props.filters?.l5_name),
          l6_name: getLevelFiltersForReq(props.filters?.l6_name),
          l7_name: getLevelFiltersForReq(props.filters?.l7_name),
          dc,
          article: styles,
          start_date: start_date?.[0] || null,
          end_date: end_date?.[0] || null,
          factory_type,
        };
    
        let request = {};
        if (!isEmpty(payload)) {
          request.Attributes = {};
          request.Operators = { NonAttributeColumn: [] };
          for (const key in payload) {
            if (!isEmpty(payload[key])) {
              request[key] = payload[key];
              request.Attributes[key] = request[key];
              request.Operators.NonAttributeColumn.push(key);
            }
          }
        }
        request["dc"] = payload.dc;
        let reviewFilters = {};
        // if(reviewCall) {
        //     for(let key in props.filters) {
        //         if(props.filters[key]) {
        //             reviewFilters[key] = props.filters[key]
        //             request[key] = props.filters[key]
        //         }
        //     }
        // }
        request["styleIndex"] = 0;
        props.getStrategyTableDataFromDashboard(request);
        props.history.push(STRATEGY_PAGE, {
          filters: props.filters,
          styles: styles,
          dc: payload.dc,
          storeNumbers: storeNumbers,
        });
      };


    const onCreateAllocationTableHandler = (data) => {
        let allocationData = data?.length ? data : checkAllData;
        const storeNumbers = props.storeCodes;
        if (allocationData?.length) {
          goToCreateAllocation(
            allocationData.map((item) => item.article),
            storeNumbers
          );
        } else if (Object.keys(styleInvTableInst.state.selectedRowIds).length > 0) {
          const styles = [];
          Object.keys(styleInvTableInst.state.selectedRowIds).forEach((rowId) => {
            styles.push(
              styleInvTableInst.preFilteredRowsById[rowId].original.article
            );
          });
          goToCreateAllocation(styles, storeNumbers);
        } else {
          Notify.error(
            `Select Atleast one ${updateArticleName(false)} Inventory!!`
          );
        }
    };

    const applyFilters = (req) => {
      const dc = req.map((item) => item.value);
      hideDCColumns(req);
      reset();
      callGetApi(dc);
      setDC(dc);
      setShowCountryFilter(true);
    };

    const callGetApi = (dc) => {
      if (reviewCall) {
        props.getReviewRecommendData({
          ...reviewRequest,
          row_index: 0,
          searchColumns: searchTermReq,
          sortColumn: sortReq,
          dc,
        });
      }
      else {
        props.getStyleInventoryData({
          ...props.filters,
          row_index: 0,
          dc,
          searchColumns: searchTermReq,
          sortColumn: sortReq,
        });
      }
    }

    const hideDCColumns = (dc) => {
      dc = Array.isArray(dc?.[0]) ? dc[0] : dc;
      let columnsToHide = [];
      let selectedValues = dc.map((item) => props.dcCountryMap[item.value]?.toLowerCase());
      Object.keys(countrySplitObj).forEach((key) => {
        if (!selectedValues.includes(key)) {
          columnsToHide = columnsToHide.concat(countrySplitObj[key]);
        }
      });
      setHiddenColumns(columnsToHide);
    };

    const fetchData = (index) => {
        if (reviewCall) {
          if (prevIndex >= index || index < pageIndex || props?.out_of_data) return;
        } else if (prevIndex >= index || nextIndex >= totalCount) return;
        setPrevIndex(index);
        setInitialIndex(
          reviewCall ? (Math.floor((pageIndex/10)) <= 0 ? 0 : Math.floor((pageIndex/10)-1)) : Math.floor(nextIndex / 10)
        );
        if (reviewCall) {
          props.getReviewRecommendData({
            ...reviewRequest,
            row_index: nextIndex,
            searchColumns: searchTermReq,
            sortColumn: sortReq,
            dc
          });
        } else {
          props.getStyleInventoryData({
            ...props.filters,
            row_index: nextIndex,
            searchColumns: searchTermReq,
            sortColumn: sortReq,
            dc
          });
        }
    };

    useEffect(() => {
        if (props?.data?.length) {
          let l_newData = getNewData({
            p_newData: [...styleInvTableInst?.data, ...props?.data],
            p_checkAll: checkAll,
          });
          setInitialSelectedRows(l_newData?.finalCheckedRows);
          if (styleInvTableInst?.data?.length) {
            setStyleInvData([...styleInvTableInst.data, ...props?.data]);
          } else {
            setStyleInvData(props?.data);
          }
        }
        
        setShowTable(true)
    }, [props?.data]);

    useEffect(() => {
        if (props.nextIndex == 100 && !reviewCall) {
          setPrevIndex(0);
          // setInitialIndex(0)
        }
        setTotalCount(reviewCall ? props.recDatatotalCount : props.totalCount);
        setNextIndex(reviewCall ? props.recDatanextIndex : props.nextIndex);
      }, [
        props.totalCount,
        props.nextIndex,
        props.recDatanextIndex,
        props.recDatatotalCount,
      ]);
    
      const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
        if (p_type === "array") {
          if(split_type) {
            p_term = p_term.replace(/\s*,\s*/g, ",")
          }
          setSearchTermReq((old) => {
            let l_searchTermReq = cloneDeep(old);
            var id = p_term.split(split_type ? split_type : " ")
            if (p_term?.trim() == 0) {
              l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
            } else {
              l_searchTermReq[p_id] = {
                type: p_type,
                values: id,
                search: p_term,
              };
            }
            return {
              ...l_searchTermReq,
            };
          });
        } else if (Array.isArray(p_term)) {
          setSearchTermReq((old) => {
            let l_searchTermReq = cloneDeep(old);
            let min = p_term[0] == "" ? null : p_term[0];
            let max = p_term[1] == "" ? null : p_term[1];
            if (min == null && max == null) {
              l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
            } else {
              l_searchTermReq[p_id] = {
                type: p_type,
                value: {
                  min: min,
                  max: max,
                },
              };
            }
            return {
              ...l_searchTermReq,
            };
          });
        } else {
          setSearchTermReq((old) => {
            let l_searchTermReq = cloneDeep(old);
            if (p_term?.trim() == 0) {
              l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
            } else {
              l_searchTermReq[p_id] = {
                type: p_type,
                value: p_term,
              };
            }
            return {
              ...l_searchTermReq,
            };
          });
        }
        isFirstCall && setisFirstCall(false);
      };
    
      const fecthDataWithSorting = (p_sort) => {
        if (!isEmpty(sortReq)) {
          isFirstCall && setisFirstCall(false);
        }
        setSortReq(p_sort[0]);
      };

      const reset = () => {
        setStyleInvData([]);
        setstyleInvTableInst(null);
        setInitialIndex(0);
        setPageIndex(0);
        setPrevIndex(0);
        setNextIndex(0);
        setDownloadExcel(false);
        props.resetPageIndex();
      }

      useEffect(() => {
        if (
          (!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) &&
          !isEmpty(props.filters)
        ) {
          reset();
          if (reviewCall) {
            props.getReviewRecommendData({
              ...reviewRequest,
              row_index: 0,
              searchColumns: searchTermReq,
              sortColumn: sortReq,
              dc
            });
          } else {
            props.getStyleInventoryData({
              ...props.filters,
              row_index: 0,
              searchColumns: searchTermReq,
              sortColumn: sortReq,
              dc
            });
          }
        }
      }, [searchTermReq, sortReq]);

      const generateExcel = () => {
        formatExcelData(styleInvData);
        // setExcelData(styleInvData)
        setDownloadLoading(true);
        setDownloadExcel(false);
        // getExcelData()
        let excelFilters = reviewCall ? reviewRequest : props.filters;
        props.getDownloadExcelData({
          ...excelFilters,
          row_index: nextIndex,
          row_count: 500,
          searchColumns: searchTermReq,
          sortColumn: sortReq,
          reviewCall: reviewCall,
        });
      };
    
      const getExcelData = () => {
        let excelFilters = reviewCall ? reviewRequest : props.filters;
        props.getDownloadExcelData({
          ...excelFilters,
          row_index: downloadNextIndex,
          row_count: 500,
          searchColumns: searchTermReq,
          sortColumn: sortReq,
          reviewCall: reviewCall,
        });
      };
    
      useEffect(() => {
        if (downloadExcelLoading && downloadNextIndex < downloadTotalCount) {
          getExcelData();
        } else if (downloadNextIndex > downloadTotalCount) {
          generaExcelData();
        }
      }, [downloadNextIndex]);
    
      const generaExcelData = (data) => {
        if (downloadExcelLoading) {
          setTimeout(() => {
            setDownloadLoading(false);
            setDownloadExcel(true);
          }, 1000);
        }
        setTimeout(() => {
          setExcelData([]);
          resetDownloadIndex();
        }, 2000);
      };
    
      useEffect(() => {
        if (downloadExcelData?.length) {
          formatExcelData(downloadExcelData);
        }
      }, [downloadExcelData]);
    
      const formatExcelData = (data) => {
        let keys = [
            { label: "Product Type", key: "product_type" },
            { label: "Style Color ID", key: "article" },
            { label: "Color ID", key: "color_code" },
            { label: "Assortment Indicator", key: "assortment_indicator" },
            { label: "Store Group", key: "store_groups" },
            { label: "Introduction Date", key: "intro_date" },
            { label: "Last Allocated", key: "max_allocation_date" },
            { label: "Style ID", key: "style_code" },
            { label: "Style Description", key: "style_name" },
            { label: "Style Color", key: "color_desc" },
            { label: "Channel", key: "l1_name" },
            { label: "Department", key: "l2_name" },
            { label: "Class", key: "l3_name" },
            { label: "Style Group", key: "l4_name" },
            { label: "Silhouette", key: "l5_name" },
            { label: "Sub Collect", key: "l6_name" },
            { label: "LW Sales Units", key: "ecm_lw_qty" },
            { label: "LW Sales Units", key: "lw_qty" },
            { label: "LW Revenue", key: "lw_revenue" },
            { label: "LW Margin", key: "lw_margin" },
            { label: "Promo %", key: "promo" },
            { label: "AUR", key: "price" },
            { label: "WTD Sales Units", key: "ecm_tw_qty" },
            { label: "WTD Sales Units", key: "tw_qty" },
            { label: "WTD Revenue", key: "tw_revenue" },
            { label: "WTD Margin", key: "tw_margin" },
            { label: "WTD Promo %", key: "tw_promo" },
            { label: "WTD AUR", key: "tw_price" },
            { label: "Bulk Remaining", key: "bulk_remaining" },
            { label: "Bulk Remaining (Reynosa)", key: "bulk_remaining_mx" },
            { label: "On Hand", key: "oh" },
            { label: "On Order", key: "oo" },
            { label: "In Transit", key: "it" },
            { label: "WOS", key: "wos" },
            { label: "Size Integrity(On Hand)", key: "si" },
            { label: "Bulk Remaining (USA)", key: "bulk_remaining_us" },
            { label: "On Hand (USA)", key: "oh_us" },
            { label: "On Order (USA)", key: "oo_us" },
            { label: "In Transit (USA)", key: "it_us" },
            { label: "WOS (USA)", key: "wos_us" },
            { label: "Size Integrity(On Hand) (USA)", key: "si_us" },
            { label: "Bulk Remaining (CAN)", key: "bulk_remaining_ca" },
            { label: "On Hand (CAN)", key: "oh_ca" },
            { label: "On Order (CAN)", key: "oo_ca" },
            { label: "In Transit (CAN)", key: "it_ca" },
            { label: "WOS (CAN)", key: "wos_ca" },
            { label: "Size Integrity(On Hand) (CAN)", key: "si_ca" },
            { label: "Stock Out", key: "stock_out" },
            { label: "Shortfall", key: "shortfall" },
            { label: "Normal", key: "normal" },
            { label: "Excess", key: "excess" },
          ];
        let excelData = [];
        let perSymbolCols = ["promo", "si","tw_promo", "si_us", "si_ca"];
        let val;
        data?.forEach((row) => {
          let obj = {};
          keys.forEach((item) => {
            val = row[item.key]
            if (perSymbolCols.includes(item.key) && val) {
              val = addPer(val);
            }
            if (item.key === "store_groups") {
              obj[item.label] = row[item.key]?.join(", ");
            } else {
              obj[item.label] = val;
            }
          });
          excelData.push(obj);
        });
        setExcelData(exportExcelData.concat(excelData));
      };
    
      const closeDownloadModal = () => {
        setDownloadLoading(false);
        resetDownloadIndex();
        setExcelData([]);
      };
    
      useEffect(() => {
        if (props.downloadExcelError) {
          setDownloadLoading(false);
          Notify.error(props.downloadExcelError);
          resetDownloadIndex();
        }
      }, [props.downloadExcelError]);

      const updateSetAll = (value) => {
        let l_newAction = {
          searchTermReq: searchTermReq,
        };
        if (!value.checked) {
          setPrevAction("checkAll");
          l_newAction["checkAll"] = true;
        } else {
          setPrevAction("unCheckAll");
          l_newAction["unCheckAll"] = true;
          let l_updatedCheckedRows = removeCheckedArticles(
            checkedRows,
            searchTermReq
          );
          setCheckedRows(l_updatedCheckedRows);
        }
        setCheckAll((old) => [...old, l_newAction]);
      };
    
      const updateCheckedRows = (value) => {
        let l_newAction = {},
          l_article = value.instance.article;
    
        if (!value.checked) {
          setPrevAction("checkRow");
          if (prevAction === "checkRow") {
            let checkAllCopy = [...checkAll];
            let l_lastValueIndex =
              checkAllCopy.length - 1 === -1 ? 0 : checkAllCopy.length - 1;
            let l_checekdRows = checkAllCopy?.[l_lastValueIndex];
            l_checekdRows["checkedRows"].push(l_article);
            checkAllCopy.pop();
            setCheckAll([...checkAllCopy, l_checekdRows]);
          } else {
            l_newAction["checkedRows"] = [l_article];
            setCheckAll((old) => [...old, l_newAction]);
          }
    
          let l_checkedRow = { [l_article]: value.instance };
          setCheckedRows((old) => {
            return {
              ...old,
              ...l_checkedRow,
            };
          });
        } else {
          setPrevAction("unCheckRow");
          if (prevAction === "unCheckRow") {
            let checkAllCopy = [...checkAll];
            let l_lastValueIndex =
              checkAllCopy.length - 1 === -1 ? 0 : checkAllCopy.length - 1;
            let l_checekdRows = checkAllCopy[l_lastValueIndex];
            l_checekdRows["unCheckedRows"].push(l_article);
            checkAllCopy.pop();
            setCheckAll([...checkAllCopy, l_checekdRows]);
          } else {
            l_newAction["unCheckedRows"] = [l_article];
            setCheckAll((old) => [...old, l_newAction]);
          }
    
          let l_checkedRow = cloneDeep(checkedRows);
          delete l_checkedRow[l_article];
          setCheckedRows((old) => {
            return {
              ...l_checkedRow,
            };
          });
        }
      };
    
      const checkPaginationHistory = () => {
        let l_checkedUnchecked = checkAll.filter(
          (val) => val.checkAll || val.unCheckAll
        );
        if (l_checkedUnchecked?.length) {
          let l_checkedUnchecked = checkAll.filter(
            (val) => val.checkAll || val.unCheckAll
          );
          let l_checkAll = [];
          for (let i = 0; i < l_checkedUnchecked.length; i++) {
            if (l_checkedUnchecked[i].checkAll) {
              l_checkAll.push(l_checkedUnchecked[i]);
            }
            if (l_checkedUnchecked[i].unCheckAll) {
              l_checkAll = [];
            }
          }
    
          let checked = l_checkAll.filter((val) => val.checkAll);
          let unchecked = l_checkAll.filter((val) => val.unCheckAll);
          for (let i = 0; i < checked.length; i++) {
            for (let j = 0; j < unchecked.length; j++) {
              if (isEqual(checked[i].searchTermReq, unchecked[j].searchTermReq)) {
                checked.splice(i, 1);
              }
            }
          }
          setCheckAllReqArr(checked);
          // setCheckAllData(tableData)
          setIsGetAllLoading(true);
          // setTableData([])
          getCheckAllData(checked);
        } else {
          onCreateAllocationTableHandler(getCreateAllocationData());
        }
      };
    
      const getCreateAllocationData = () => {
        let finalArticlesData = [];
        checkAll.forEach((item) => {
          if (item.checkedRows) {
            finalArticlesData = finalArticlesData.concat(item.checkedRows);
          } else if (item.unCheckedRows) {
            finalArticlesData = finalArticlesData.filter(
              (article) => !item.unCheckedRows.includes(article)
            );
          }
        });
        return finalArticlesData.map((article) => {
          return { article };
        });
      };
    
      const getCheckAllData = (p_checkAllReq, checkAllNextIndex, data ) => {
        if (!isEmpty(p_checkAllReq)) {
          let l_checkAllRequest = p_checkAllReq[0];
          // if(l_checkAllRequest?.checkAll && isEmpty(l_checkAllRequest?.searchTermReq)) {
          //     setPrevCheckAllCall(true)
          // }
          let reqFilters = {};
          for (let key in props.filters) {
            if (props.filters[key]) {
              reqFilters[key] = props.filters[key];
            }
          }
          if (reviewCall) {
            reqFilters["storesFilter"] = reviewRequest["storesFilter"];
          }
          props.getStyleInventoryData({
            ...reqFilters,
            checkAll: true,
            row_count: 500,
            row_index: checkAllNextIndex !== undefined ? checkAllNextIndex : props.checkAllNextIndex,
            searchColumns: l_checkAllRequest?.searchTermReq,
            sortColumn: sortReq,
            reviewCall,
            dc
          });
        } else {
          onCreateAllocationTableHandler(data.concat(getCreateAllocationData()));
        }
      };
    
      useEffect(() => {
        let checkAllCount = reviewCall ? stylesCount : props.checkAllTotalCount;
        if (props?.checkAllData?.length) {
          let l_finalCheckedArticles = getFinalCheckedRowsForCAData(
            checkAll,
            props.checkAllData
          );
          // let data = cloneDeep(checkAllData).concat(props?.checkAllData)
          let data = cloneDeep(checkAllData).concat(
            Object.values(l_finalCheckedArticles)
          );
          props?.checkAllData?.length && setCheckAllData(data);
          if (props.checkAllNextIndex < checkAllCount && isGetAllLoading) {
            getCheckAllData(checkAllReqArr, props.checkAllNextIndex, data);
          } else if (props.checkAllNextIndex > checkAllCount) {
            let checkAllArr = [...checkAllReqArr];
            checkAllArr.shift();
            // setCheckAll(checkAllArr)
            setCheckAllReqArr(checkAllArr);
            getCheckAllData(checkAllArr, 0, data);
          } else if (isGetAllLoading) {
            setPrevAction("");
          }
        } else if (props.checkAllNextIndex > checkAllCount) {
          let checkAllArr = [...checkAllReqArr];
          checkAllArr.shift();
          setCheckAllReqArr(checkAllArr);
          getCheckAllData(checkAllArr, 0);
        }
      }, [props?.checkAllData]);
    
      useEffect(() => {
        setStyleInvData([]);
        setSearchTermReq({});
        setSortReq({});
        setRTInstance(null);
        setInitialIndex(0);
        setPageIndex(0);
        setPrevIndex(0);
        setTotalCount(0);
        setNextIndex(0);
        props.resetPageIndex();
        setShowTable(false);
        setDC((Array.isArray(props.filters?.dc?.[0]) ? props.filters?.dc?.[0] : props.filters?.dc)?.map(dc => dc.value))
        props.filters?.dc?.length && hideDCColumns(props.filters?.dc);
      }, [props.filters]);

    return (
        <>
            <DownloadProgressBar
                nextIndex={exportExcelData?.length}
                totalCount={reviewCall ? stylesCount : downloadTotalCount || totalCount}
                showModal={downloadExcelLoading}
                closeModal={closeDownloadModal}
            />
            <div className="position-relative">
                {(props.data || showCountryFilter) &&
                <CountrySplitFilter dcCountryMap={props.dcCountryMap} dcOptions={props.filters.dc} applyFilters={applyFilters} />}
                {/* <Notification /> */}
                <PageLoader
                    // loader={props.data ? false : true}
                    loader={props.styleInventoryloading ||
                        (reviewCall && props.reviewRecommendLoading)}
                    gridLoader={true}
                    loaderMessage="Loading..."
                >
                    {
                        props.data || reviewCall ?
                            (
                                <>
                                    <h2 className="font-weight-bold mx-auto my-10 p-3 text-center">{props?.heading}</h2>
                                    <div className='text-right mb-2'>
                                    <ExportFile
                                        downloadExcelData={downloadExcel}
                                        text={"Excel"}
                                        callFunc={generateExcel}
                                        data={exportExcelData}
                                        fileName={`style_color_inventory_${new Date().getTime()}`}
                                        type={1}
                                        formateXLSX={true}
                                        excelColumnsToBeFormatted={EXCEL_COLUMNS_FOR_FORMATTING}
                                    />
                                    </div>
                                    {showTable && <ReactTableWithPlugins
                                        getInstanceOnMount={(inst) => setstyleInvTableInst(inst)}
                                        columns={styleInvetoryCols}
                                        sortBy={[{ id: 'lw_qty', desc: true }]}
                                        data={styleInvData}
                                        renderMarkup="TableMarkup"
                                        keyRT="StyleInvTable"
                                        shouldPagination
                                        features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                                        tableId="style_inv"
                                        initialHiddenColumns={initialHiddenColumns}
                                        fetchData={(data) => fetchData(data)}
                                        initialPageIndex={initialIndex}
                                        totalCount={totalCount}
                                        getManualSortByOptions={(instance) => {
                                        fecthDataWithSorting(instance);
                                        }}
                                        manualSortBy={true}
                                        checkAllCallBack={(val) => updateSetAll(val)}
                                        checkCallBack={(val) => updateCheckedRows(val)}
                                        initialSelectedRows={initialSelectedRows}
                                        hideTotalRecords={reviewCall && hideTotalRecords}
                                        hideTotalPages={reviewCall && hideTotalPages}
                                        tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                                        headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                                        hideDropdown={"Yes"}
                                    />}
                                    {styleInvData.length ?
                                        (<div className="text-center">
                                            <button
                                                className="btn btn-primary fnt-md px-2"
                                                onClick={() => checkPaginationHistory()}
                                            >
                                                Create Allocation
                                            </button>
                                        </div>) :
                                        ''
                                    }

                                    {props.styleInvPopupData && (
                                        <DashboardModal
                                            openStyleInvPopup={openStyleInvPopup}
                                            closeAlert={closeAlert}
                                            popupTitle={popupTitle}
                                            articleId={articleId}
                                            styleInvPopupData={props.styleInvPopupData}
                                        />
                                    )}

                                </>) :
                            ''
                    }

                </PageLoader>
            </div>
       </>
    )
}

const mapStateToProps = ({ dashboard }) => ({
  styleInvPopupData: dashboard.styleInvPopupData,
  styleInventoryloading: dashboard.styleInventoryloading,
  totalCount: dashboard.styleInvtotalCount,
  nextIndex: dashboard.styleInvnextIndex,
  recDatatotalCount: dashboard.recDatatotalCount,
  recDatanextIndex: dashboard.recDatanextIndex,
  downloadNextIndex: dashboard.downloadNextIndex,
  downloadExcelData: dashboard.downloadExcelData,
  downloadTotalCount: dashboard.downloadTotalCount,
  downloadExcelError: dashboard.downloadExcelError,
  checkAllData: dashboard.checkAllData,
  checkAllNextIndex: dashboard.checkAllNextIndex,
  checkAllTotalCount: dashboard.checkAllTotalCount,
  storeCodes: dashboard.storeCodes,
  out_of_data: dashboard.reviewRecommendOutofData,
  pageIndex: dashboard.reviewPageIndex,
  reviewRecommendLoading: dashboard.reviewRecommendLoading,
  dcOptions: dashboard.dcOptions,
  dcCountryMap: dashboard.dcCountryMap
})

const mapDispatchToProps = (dispatch) => ({
    getStyleInvPopupData: (payload) => dispatch(getStyleInvPopupData(payload)),
    getStrategyTableDataFromDashboard: (payload) => dispatch(getStrategyTableDataFromDashboard(payload)),
    getStyleInventoryData: (payload) => dispatch(getStyleInventoryData(payload)),
    getReviewRecommendData: (payload) => dispatch(getReviewRecommendData(payload)),
    getDownloadExcelData: (payload) => dispatch(getDownloadExcelData(payload)),
    resetDownloadIndex: () => dispatch(resetDownloadIndex()),
    resetPageIndex: () => dispatch(resetPageIndex()),
})


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StyleInventory))
