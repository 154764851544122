import React from "react";
import { isEmpty } from "lodash"
import Api from "../configs/api_config"
import { GET_USER_PREFERENCE, SAVE_USER_PREFERENCE_COLUMNS, SET_USER_PREFERENCE, UPDATE_TOKEN } from "../constants/apiConstants"
import moment from "moment";
import ColumnFilter from "../containers/UserManagement/ColumnFilter";
import * as StoreDetailsConsts from '../containers/NewStoreSetUp/views/StoreDetailsConfig/StoreDetailsDcConfigConstants';
import axios from "axios";
import jwt_decode from 'jwt-decode'
import * as Notify from "../components/Notification/Notifications";
import MultiSelect from "../components/Filters/MultiSelect";
import Select from "../components/Filters/SingleSelect";
import ReactTollTip from "../components/Table/Cellrenderer/ReactToolTip";
import { INVENTORY_SOURCE_FILTER_DATA } from "../containers/StrategyConfiguration/StrategyConfigConstants";
import { identify as postHogIdentify } from "./PostHog/postHog";
import { LOGIN_ROUTE, SSO_ROUTE } from "../constants/routeConstants";


const clientIds = [2, 3, 4, 5, 6, 7, 8, 9, 10, 12]
const prodIds = [2, 3, 6, 8]
const prodIdsForWhatFix = [2,3,11,12,8]
const prodURL = "https://cdn.whatfix.com/prod/e5b24cc0-a053-11ec-90f5-000d3a5780c8/embed/embed.nocache.js" 
const devQAURL = "https://whatfix.com/e5b24cc0-a053-11ec-90f5-000d3a5780c8/embed/embed.nocache.js"
export const currentClientName = process.env.REACT_APP_CLIENT
export const clientEnvId = process.env.REACT_APP_ENV_ID

export const scrollIntoView = (ref) => {
    ref?.current?.scrollIntoView({
        behavior: "smooth",
    })
}

export const compareLowerCase = (rowA, rowB, id) =>{
    const a = (rowA.original[id]?.[0]|| "0")?.toLowerCase()
    const b = (rowB.original[id]?.[0]|| "0")?.toLowerCase()
    if (a > b) return 1; 
    if (a < b) return -1;
    return 0;
}

export const getWhatFixURL = () => {
    try {
        return prodIdsForWhatFix?.includes((+clientEnvId)) ? prodURL : devQAURL
    }
    catch(err){
        return devQAURL;
    }
}

export const dateFormat = (val, format = "MM-DD-YYYY") => {
    return val ? moment(val).format(format) : ""
}

export const appendPerc = (val) => isNaN(val) ? val : val + '%'

export const isObject = (p_object) => {
    if(typeof p_object == 'object' && !(p_object instanceof Array) && p_object?.value != null) {
        return true;
    }
    return false;
}

const getConditionForUserPreference = (p_getFilters,p_levelFilters) => {
    if(Object.keys(p_levelFilters).length != (MANDATORY_SINGLE_SELECT_FILTERS?.[currentClientName]?.length || 1)){
        return true;
    }
    for(let i in p_levelFilters) {
        if(Array.isArray(p_levelFilters?.[i])) {
            if(Array.isArray(p_getFilters?.[i])) {
                if(isArraysAreEqual(p_getFilters[i], p_levelFilters[i])) {
                    return false;
                }
            }
            else {
                return false
            }
        }
        else if(p_getFilters?.[i]?.value == p_levelFilters?.[i]?.value || !(p_levelFilters?.[i]?.value)) {
            continue;
        }
        else{
            return false;
        }
    }
    return true;
}

const isArraysAreEqual = (arr1, arr2) => {
    let filterArray = arr1.length > arr2.length ? arr1 : arr2
    let findArray = arr1.length > arr2.length ? arr2 : arr1
    return !!filterArray.filter((filter1) => !findArray.find(filter2 => filter1.value === filter2.value ))?.length
}

const getFiltersFromResponse = (p_filters) => {
    if(p_filters?.l1_name?.label) {
        return p_filters
    }
    let l_filters = {}
    for (let i in p_filters) {
        l_filters[i] = {label:p_filters[i], value:p_filters[i]}
    }
    return l_filters;
}

export const getRequestForChartAPI = (p_filters) => {
    let l_filters = {}
    for (let i in p_filters) {
        l_filters[i] = [p_filters[i]]
    }
    return l_filters;
}

export const getRequestForChartAPIAfterReset = (p_filters) => {
    let l_mandatoryFilters = MANDATORY_SINGLE_SELECT_FILTERS[currentClientName] || ["l1_name"]
    let l_req = {}
    for( let i of l_mandatoryFilters) {
        l_req[i] = p_filters[i]
    }
    return l_req;
}

export const setUserPreferenceFilter = async (p_req, levelfilters) => {

    let getFilter = JSON.parse(localStorage.getItem("user_preference"))
    if (getFilter && !isEmpty(getFilter.filters) && getFilter.user_id == p_req.user_id && getConditionForUserPreference(getFilter.filters,levelfilters)) {
        return;
    }
    else {
        levelfilters["l2_name"] = Array.isArray(levelfilters["l2_name"]) ? [levelfilters["l2_name"][0]?.value == "*" ? levelfilters["l2_name"][1] : levelfilters["l2_name"][0]] : [levelfilters["l2_name"]]
        let response = await Api.post(SET_USER_PREFERENCE, { "user_id": p_req.user_id, "filters": levelfilters })
        let prepData = { "user_id": p_req.user_id, "filters": levelfilters }
        localStorage.setItem("user_preference", JSON.stringify(prepData))
    }
}

export const getUserPreferenceFilter = async (p_req, {onl1Change,onl2Change,onl3Change}) => {
    let getFilter = JSON.parse(localStorage.getItem("user_preference"))
    if (getFilter && !isEmpty(getFilter.filters) && getFilter.user_id == p_req.user_id) {
        !isEmpty(getFilter.filters.l1_name) && onl1Change && onl1Change(getFilter.filters.l1_name,{user_preference:true})
        !isEmpty(getFilter.filters.l2_name) && onl2Change && onl2Change(getFilter.filters.l2_name,{user_preference:true, l1_name:getFilter.filters.l1_name})
        !isEmpty(getFilter.filters.l3_name) && onl3Change && onl3Change(getFilter.filters.l3_name,{user_preference:true, l1_name:getFilter.filters.l1_name, l2_name:getFilter.filters.l2_name})
    }
    else {
        let response = await Api.post(GET_USER_PREFERENCE, p_req)
        let prepData = { ...p_req, "filters": getFiltersFromResponse(response?.data?.data?.[0]?.filters) }
        if(Array.isArray(prepData["filters"]["l2_name"])) {
            prepData["filters"]["l2_name"] = [prepData["filters"]["l2_name"][0]]
        }
        else {             
            prepData["filters"]["l2_name"] = [prepData["filters"]["l2_name"]]         
        }
        localStorage.setItem("user_preference", JSON.stringify(prepData))
        if (response?.data?.data && response?.data?.data?.[0]?.filters) {
            let l_response = response?.data?.data?.[0]?.filters
            !isEmpty(l_response?.l1_name) && onl1Change && onl1Change(l_response.l1_name,{user_preference:true})
            !isEmpty(l_response?.l2_name) && onl2Change && onl2Change(l_response.l2_name,{user_preference:true, l1_name:l_response.l1_name})
            !isEmpty(l_response?.l3_name) && onl3Change && onl3Change(l_response.l3_name,{user_preference:true, l1_name:l_response.l1_name, l2_name:l_response.l2_name})
        }
    }
}

export const removeUserPreference = async (p_req) => {
    let getFilter = JSON?.parse(localStorage.getItem("user_preference"))
    let loggedUserId = getFilter?.user_id
    let editedUserId = p_req?.uid
    if(loggedUserId != editedUserId) {
        return;
    }
    let l_userPreferenceFilters =  USER_PREFERENCE_FILTERS[currentClientName] || ["l1_name"]
    // l_userPreferenceFilters?.forEach(val => {
        for(let val of l_userPreferenceFilters){
            let filter =  getFilter?.filters?.[val]?.value;
            if(p_req?.attributes?.[val]?.includes(filter)) {
                continue;
            }
            localStorage.removeItem("user_preference")
            break;
        }
    // )
    return;
}

export const hideToProd = () => {
    return !prodIds.includes(Number(clientEnvId))
}

export const role = {
    super_user: 1,
    admin: 2,
    allocator: 3
}

export const getUserRole = (...roles) => {
    return roles.map(user_role => role[user_role])
}

export const USER_ROLE_CONSTANTS = {
    SUPER_USER: "super_user",
    ADMIN: "admin",
    ALLOCATOR: "allocator"
}

// List of user preference filters 

export const USER_PREFERENCE_FILTERS = {
    "puma": ["l1_name"],
    "pandora": ["l1_name"],
    "ashley": ["l1_name"],
    "coach": ["l1_name","l2_name","l3_name"],
    "katespade": ["l1_name","l2_name","l3_name"]
}

// List of Mandatory Filters

export const MANDATORY_SINGLE_SELECT_FILTERS = {
    "puma": ["l1_name"],
    "pandora": ["l1_name"],
    "ashley": ["l1_name"],
    "coach": ["l1_name","l2_name"],
    "katespade": ["l1_name","l2_name"]
}

// Nature of filters for clients

export const FILTER_TYPE_FOR_CLIENT_IN_MODULES = {
    "storeEligibilityGroup" : {
        "puma": {"l1_name":"multi"},
        "pandora":  {"l1_name":"multi"},
        "ashley":  {"l1_name":"multi","l2_name":"single","l3_name":"single"},
        "coach": {"l1_name":"single","l2_name":"multi","l3_name":"multi"},
        "katespade": {"l1_name":"single","l2_name":"multi","l3_name":"multi"},
    },
    "dashboard" : {
        "puma": {"l1_name":"multi"},
        "pandora":  {"l1_name":"multi"},
        "ashley":  {"l1_name":"multi","l2_name":"multi","l3_name":"multi"},
        "coach": {"l1_name":"single","l2_name":"multi","l3_name":"multi"},
        "katespade": {"l1_name":"single","l2_name":"multi","l3_name":"multi"},
    },
    "strategy" : {
        "puma": {"l1_name":"single"},
        "pandora":  {"l1_name":"single"},
        "ashley":  {"l1_name":"single","l2_name":"multi","l3_name":"multi"},
        "coach": {"l1_name":"single","l2_name":"multi","l3_name":"multi"},
        "katespade": {"l1_name":"single","l2_name":"multi","l3_name":"multi"},
    },
    "viewpast": {
        "puma": {"l1_name":"single"},
        "pandora":  {"l1_name":"single"},
        "ashley":  {"l1_name":"single","l2_name":"multi","l3_name":"multi"},
        "coach": {"l1_name":"single","l2_name":"multi","l3_name":"multi"},
        "katespade": {"l1_name":"single","l2_name":"multi","l3_name":"multi"},
    },
    "reporting": {
        "coach": {"l1_name":"single","l2_name":"multi","l3_name":"multi"},
        "katespade": {"l1_name":"single","l2_name":"multi","l3_name":"multi"},
    }
}

// Required of filters for clients

export const REQUIRED_FILTER_TYPE_FOR_CLIENT_IN_MODULES = {
    "storeEligibilityGroup" : {
        "puma": ["l1_name"],
        "pandora": ["l1_name"],
        "ashley": ["l1_name"],
        "coach": ["l1_name","l2_name"],
        "katespade": ["l1_name","l2_name"],
    },
    "dashboard": {
        "puma": ["l1_name"],
        "pandora": ["l1_name"],
        "ashley": ["l1_name"],
        "coach": ["l1_name","l2_name"],
        "katespade": ["l1_name","l2_name"],
    }
}


export const EDIT_COMPONENT_FOR_SIZES_OR_EACHES_PACKS = {
        "puma": "size",
        "pandora": "packs-eaches",
        "ashley": "packs-eaches",
        "coach": "packs-eaches",
        "katespade": "packs-eaches",
}

// Number of artilces in dasboard for charts

export const ARTICLES_IN_DASHBOARD_CHARTS = {
    "coach": 10,
    "katespade": 10,
}

// List of Columns in the table to be hidden for clients

export const HIDE_TABLE_COLUMNS = {
    "puma": ["l5_name", "level5", "l6_name", "level6", "dummyId","po_number","asn","source","released_by","released_date_picked",
        "released_time","article_status_tag", "style_code", "color_code"],
    "pandora": ["dummyId", "region", "district", "l3_name", "l4_name", "l5_name", "level3", "level4", "level5", "l6_name", "level6", 
        "subcategory", "dcs","po_number","asn","source","released_by","released_date_picked","released_time","article_status_tag", 
        "style_code", "color_code"],
    "ashley": ["l5_name", "level5", "l6_name", "level6", "dummyId","season_code_active", "style_code", "color_code"],
    "coach": ["dummyId", "region", "released_by", "po_number", "asn", "po_id", "asn_id", "packs_allocated", "po_asn_count", "po_reserve", 
        "po_asn_receipt_quantity", "inventory_date", "latest_receipt_date", "total_quantity", "receipt_quantity", "quantity_variance",
        "vendor_id", "vendor_name", "status", "allocated_packs", "loose_units_allocated", "packs", "packs_available"],
    "katespade": ["dummyId", "region", "released_by", "asn_id", "season_code_active", "new_store_reserve"]
}

// List of Filters to be hidden for clients

export const HIDABLE_FILTERS = {
    "puma": ["l5_name"],
    "pandora": ["l3_name", "l4_name", "l5_name", "region", "district", "season_code", "dc"],
    "ashley": ["dc", "store_grade_source", "l5_name","season_code_active"],
    "coach": ["l7_name", "l8_name", "region"],
    "katespade": ["l7_name", "l8_name", "region", "season_code_active"]
}

export const HIDABLE_FILTERS_MODULE = {
    "puma": { "storeEligibilityGroup": ["l2_name", "l3_name", "l4_name", "l5_name", "country"], "createPP": ["l5_name"], "createEditUser": ["l2_name,l3_name"] },
    "pandora": { "storeEligibilityGroup": ["l2_name", "l3_name", "l4_name", "l5_name", "country"], "createPP": ["l3_name", "l4_name", "l5_name", "region", "district", "season_code", "dc"], "createEditUser": ["l2_name,l3_name"] },
    "ashley": { "storeEligibilityGroup": ["l5_name", "country"], "createPP": ["dc", "store_grade_source", "l5_name","season_code_active"], "createEditUser": ["l2_name,l3_name"], "newStore": ["channel", "country"] },
    "coach": { "storeEligibilityGroup": ["l8_name"], "createPP": ["l8_name"], "createEditUser": [""], "createEditUser": ["l2_name,l3_name"], "newStore": ["address"]  },
    "katespade": { "storeEligibilityGroup": ["l8_name"], "createPP": ["l8_name"], "createEditUser": [""], "createEditUser": ["l2_name,l3_name"], "newStore": ["address"]  },
}

// List of Tabs to be hidden for clients

export const HIDABLE_TABS = {
    1: [],
    2: ["newstore"],
    3: ["missing_config"],
    4: ["missing_config"],
    5: ["missing_config"],
    6: ["newstore","missing_config"],
    7: ["missing_config"],
    8: ["missing_config"],
    9: ["missing_config"],
    10: ["missing_config", "newstore"],
    12: ["missing_config", "newstore"]
}


// List of Level Filters for clients

export const LEVEL_FILTER_LABELS = {
    "pandora": [{ label: "Department", key: "department" }, { label: "Sub-Department", key: "sub_department" }],
    "puma": [{ label: "Department", key: "department" }, { label: "Gender", key: "gender" },
    { label: "Sub-Category", key: "subcat" }, { label: "DCS", key: "dcs" }],
    "ashley": [{ label: "Department", key: "department" }, { label: "Sub-Department", key: "sub_department" },
    { label: "Class", key: "class" }, { label: "Sub-Class", key: "sub_class" }],
    "coach": [{ label: "Department", key: "department" }, { label: "Sub-Department", key: "sub_department" },
    { label: "Class", key: "class" }, { label: "Sub-Class", key: "sub_class" }],
    "katespade": [{ label: "Department", key: "department" }, { label: "Sub-Department", key: "sub_department" },
    { label: "Class", key: "class" }, { label: "Sub-Class", key: "sub_class" }]
}

// List of Level Filter Column Names in table for clients

export const LEVEL_FILTER_COLUMN_NAMES = {
    "puma": {
        level1: "Department",
        level2: "Gender",
        level3: "Sub Category",
        level4: "DCS",
    },
    "pandora": {
        level1: "Department",
        level2: "Sub-Department",
    },
    "ashley": {
        // level1: "Department Grouping",
        level1: "Department",
        level2: "Sub-Department",
        level3: "Class",
        level4: "Sub-Class"
    },
    "coach": {
        // level1: "Department Grouping",
        // level1: "Country",
        level1: "Channel",
        level2: "Department",
        level3: "Class",
        level4: "Style Group",
        level5: "Silhouette",
        level6: "Sub Collect"
    },
    "katespade": {
        // level1: "Department Grouping",
        // level1: "Country",
        level1: "Channel",
        level2: "Department",
        level3: "Class",
        level4: "Sub-Class",
        level5: "Silhouette",
        level6: "Collection"
    }
}

// List of Store Grade Columns clients

export const STORE_GRADE_COLUMNS = {
    "pandora": {
        Header: 'Store Grade',
        accessor: 'store_grade_ft',
        width: 210,
        Filter: ColumnFilter,
        filter: 'exactText'
    },
    "ashley": {
        Header: 'Store Grade',
        accessor: 'store_grade_ft',
        width: 210,
        Filter: ColumnFilter,
        filter: 'exactText'
    },
    "coach": {
        Header: 'Store Grade',
        accessor: 'store_grade',
        width: 210,
        Filter: ColumnFilter,
        filter: 'exactText'
    },
    "katespade": {
        Header: 'Store Grade',
        accessor: 'store_grade',
        width: 210,
        Filter: ColumnFilter,
        // filter: 'exactText',
        filter: (rows, id, filterValue) => rows.filter((row) => (( Array.isArray(row.values[id]) ? row.values[id]?.[0]?.toLowerCase() === filterValue.toLowerCase() : (row.values[id]?.toLowerCase() === filterValue.toLowerCase()))))
    },
    "puma": {
        Header: "Store Grade",
        columns: [
            {
                Header: "Accessories",
                accessor: 'store_grade_ac',
                Filter: ColumnFilter,
                width: 120,
                filter: 'exactText',
            },
            {
                Header: "Apparel",
                accessor: 'store_grade_ap',
                Filter: ColumnFilter,
                width: 120,
                filter: 'exactText',
            },
            {
                Header: "Footwear",
                accessor: 'store_grade_ft',
                Filter: ColumnFilter,
                width: 120,
                filter: 'exactText',
            },
        ],
    }
}

export const RECORDS_PER_PAGE_MAPPING = {
    "finalize_store_view" : {
        "puma": 10,
        "pandora": 10,
        "ashley": "infinite",
        "coach": 10,
        "katespade": 10
    },
    "finalize_product_view" : {
        "puma": 10,
        "pandora": 10,
        "ashley": "infinite",
        "coach": 10,
        "katespade": 10
    }
}
 
export const getNumberOfRecordsPerPages = (p_module,p_length) => {
    let l_rowsPerPage = RECORDS_PER_PAGE_MAPPING[p_module][currentClientName] || 10 
    return l_rowsPerPage === "infinite" ? p_length : l_rowsPerPage;
}

// component to be used for edit sizes in finalize screen
export const isSizeComponentForEdit = () => {
    return EDIT_COMPONENT_FOR_SIZES_OR_EACHES_PACKS[currentClientName] === "size" ? true : false;
}

// func to return number of articles in dashboard charts 

export const noOfArtlcesInDashboard = () => {
    return (ARTICLES_IN_DASHBOARD_CHARTS?.[currentClientName] || 5)
}

const isRequiredForClient = (p_module,p_level) => {
    return (REQUIRED_FILTER_TYPE_FOR_CLIENT_IN_MODULES?.[p_module]?.[currentClientName]?.includes(p_level)) || false
}
 
// func to check if filter can be single or multi for current client

export const getFilterForClients = ({isStandAlone,id,label,name,dropdownValue,onDropDownChange,placeHolder,optionsArray,style,level,hide, disableFilters,isDisabled },p_module = null) => {
    if(p_module) {
        let l_filterType = FILTER_TYPE_FOR_CLIENT_IN_MODULES?.[p_module]?.[currentClientName]?.[level] || "single"
        let l_filter 
        if(l_filterType === "single") {
            if(!isStandAlone){
                l_filter = {
                    id: id,
                    isRequired: isRequiredForClient(p_module,level),
                    label: label,
                    name: name,
                    value: dropdownValue,
                    onChange: onDropDownChange,
                    placeHolder: placeHolder,
                    options: optionsArray,
                    style: style,
                    hide: hide,
                    isDisabled: isDisabled || disableFilters,
                }
            }
            else {
                l_filter = (
                    <>
                        <label className="fnt-md fnt-bold fnt-bold">{label}</label>
                        <Select
                            name={name}
                            value={dropdownValue}
                            onChange={onDropDownChange}
                            isDisabled={isDisabled || disableFilters}
                            placeHolder={placeHolder}
                            options={optionsArray}
                        />
                    </>
                )
            }
        }
        else{ 
            if(!isStandAlone){
                l_filter = {
                    id: id,
                    isRequired: isRequiredForClient(p_module,level),
                    label: label,
                    name: name,
                    dropdownValue: dropdownValue,
                    onDropDownChange: onDropDownChange,
                    placeHolder: placeHolder,
                    optionsArray: optionsArray,
                    isMultiSelect: true,
                    style: style,
                    hide: hide,
                    isDisabled: isDisabled || disableFilters,
                }
            }
            else{
                l_filter = (
                    <>
                        <label className="fnt-md fnt-bold fnt-bold">{label}</label>
                        <MultiSelect
                            name={name}
                            dropdownValue={dropdownValue}
                            isDisabled={isDisabled || disableFilters}
                            onDropDownChange={onDropDownChange}
                            placeHolder={`Select ${placeHolder}`}
                            optionsArray={optionsArray}
                        />
                    </>
                )
            }
        }
        
        return l_filter;
    }
    else{
        if(!isStandAlone) {
            let l_filter = {} 
            if(MANDATORY_SINGLE_SELECT_FILTERS[currentClientName]?.includes(level)) {
                l_filter = {
                    id: id,
                    isRequired: true,
                    label: label,
                    name: name,
                    value: dropdownValue,
                    onChange: onDropDownChange,
                    placeHolder: placeHolder,
                    options: optionsArray,
                    style: style,
                    hide: hide,
                    isDisabled: isDisabled || disableFilters,
                }
            }
            else {
                l_filter = {
                    id: id,
                    isRequired: false,
                    label: label,
                    name: name,
                    dropdownValue: dropdownValue,
                    onDropDownChange: onDropDownChange,
                    placeHolder: placeHolder,
                    optionsArray: optionsArray,
                    isMultiSelect: true,
                    style: style,
                    hide: hide,
                    isDisabled: isDisabled || disableFilters,
                }
    
            }
            return l_filter;
        }
        else {
            let l_filter
            if(MANDATORY_SINGLE_SELECT_FILTERS[currentClientName]?.includes(level)) {
                l_filter = (
                    <>
                        <label className="fnt-md fnt-bold fnt-bold">{label}</label>
                        <Select
                            name={name}
                            value={dropdownValue}
                            onChange={onDropDownChange}
                            isDisabled={isDisabled || disableFilters}
                            placeHolder={placeHolder}
                            options={optionsArray}
                        />
                    </>
                )
            }
            else{
                l_filter = (
                    <>
                        <label className="fnt-md fnt-bold fnt-bold">{label}</label>
                        <MultiSelect
                            name={name}
                            dropdownValue={dropdownValue}
                            isDisabled={isDisabled || disableFilters}
                            onDropDownChange={onDropDownChange}
                            placeHolder={`Select ${placeHolder}`}
                            optionsArray={optionsArray}
                        />
                    </>
                )
            }
            return l_filter
        }
    }
}

// func to get condition for applyfilter function

export const getDefaultFiltersCondition = (params) => {
    let l_mandatory = (MANDATORY_SINGLE_SELECT_FILTERS?.[currentClientName] || ["l1_name"])
    for(let i of l_mandatory ) {
        if(!isEmpty(params?.[i])) {
            continue;
        }
        return false;
    }
    return true;
}

// func to check wheather it's mandatory filter

export const isMandatory = (params) => {
    return ((MANDATORY_SINGLE_SELECT_FILTERS?.[currentClientName] || ["l1_name"])?.includes(params)) ? "required" : "fill";
}

// func to check if filter can be hidden for current client

export const shouldHideForCurrentClient = (p_level, p_module) => {
    let hide;
    if (p_module) {
        hide = HIDABLE_FILTERS_MODULE?.[currentClientName]?.[p_module]?.includes(p_level);
    }
    else {
        hide = HIDABLE_FILTERS?.[currentClientName]?.includes(p_level);
    }
    return hide;
}

export const hideTabForClientID = (p_tab) => {
    let hide;
    hide = HIDABLE_TABS?.[clientEnvId]?.includes(p_tab)
    return hide;
}

export const getLabel = (p_label) => {
    let labelMapping = {
        "season_code_open" : {
            "ashley" : "Season Code"
        }
    }
    return (labelMapping?.[p_label]?.[currentClientName] || "Season Code Open")
}

// func to get the list of table columns to be hidden for current client
// export const getHideColumns = (p_initialHidenColumns, tableId) => {
//     let hideColumns = HIDE_TABLE_COLUMNS[currentClientName]
//         ? HIDE_TABLE_COLUMNS[currentClientName]
//         : [];
//         hideColumns = hideColumns.concat(getUserHiddenColumns(1, tableId));
//     // hideColumns = hideColumns.concat(p_initialHidenColumns);
//     return hideColumns
// }

export const getHideColumns = (p_initialHidenColumns, tableId, hideColumnsFilter) => {
    let hideColumns = HIDE_TABLE_COLUMNS[currentClientName]
        ? HIDE_TABLE_COLUMNS[currentClientName]
        : [];
    hideColumns = hideColumns.concat(p_initialHidenColumns);
    return hideColumns.concat(getUserHiddenColumns(1, tableId, hideColumnsFilter));
}

export const getDefaultHiddenColumns = () => {
    return HIDE_TABLE_COLUMNS[currentClientName]
    ? HIDE_TABLE_COLUMNS[currentClientName]
    : [];
}

// func to get store grade columns to be shown for current client

export const getStoreGradeColumnNamesByClient = () => {
    return STORE_GRADE_COLUMNS[currentClientName]
}

const accessorConfigMaping = {
    'style_name': {
        'width': 210,
        Cell: (instance) => <ReactTollTip {...instance} />,
    },
    'style': {
        'width': 210,
        Cell: (instance) => <ReactTollTip {...instance} />,
    },
    'description':{
        'width': 210,
        Cell: (instance) => <ReactTollTip {...instance} />,
    },
    'style_desc':{
        'width': 210,
        Cell: (instance) => <ReactTollTip {...instance} />,
    }
}

// func to get column config based on accessor

export const getDefaultColumnConfig = (p_columnConfig) => {
    try {
        let l_columnConfig =  p_columnConfig.map(val => {
            if(accessorConfigMaping[val?.accessor]){
                return {
                    ...val,
                    ...accessorConfigMaping[val?.accessor]
                }
            }
            else {
                return {
                    ...val
                }
            }
        })
        return l_columnConfig;
    }
    catch(e) {
        return p_columnConfig;
    }
}

// func to get capacity columns of current client

export const getCapacityColumnsByClient = () => {
    const CAPACITY_COLUMNS = {
        "puma": [{
            Header: StoreDetailsConsts.CAPACITY_AP_FIELD_LABEL,
            accessor: 'ap_capacity',
            width: 120,
        },
        {
            Header: StoreDetailsConsts.CAPACITY_FT_FIELD_LABEL,
            accessor: 'ft_capacity',
            width: 130,
        },
        {
            Header: StoreDetailsConsts.CAPACITY_ACC_FIELD_LABEL,
            accessor: 'ac_capacity',
            width: 110,
        }],
        "ashley": {
            Header: "Capacity",
            accessor: 'size',
            width: 120,
        },
        "pandora": {
            Header: "Capacity",
            accessor: 'size',
            width: 120,
        },
        "coach": {
            Header: "Capacity",
            accessor: 'size',
            width: 120,
        },
        "katespade": {
            Header: "Capacity",
            accessor: 'size',
            width: 120,
        }
    }
    return CAPACITY_COLUMNS[currentClientName]

}

// func to get level filters of current client

export const getLevelFilterLabelsForCurrentClient = () => {
    return LEVEL_FILTER_LABELS[currentClientName]
}

//func to capitalize the first letter of a string

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isAshley = () => {
    return process.env.REACT_APP_CLIENT === "ashley"
}

export const isCoach = () => {
    return process.env.REACT_APP_CLIENT === "coach" || process.env.REACT_APP_CLIENT == "katespade"
}

export const hideFeatureForCurrentClient = (feature) => {
    const featureObj = {
        "puma": ["demand_type", "finalize_csv", "InvComponent", "packs", "upload_file"],
        "pandora": ["demand_type", "finalize_csv", "InvComponent", "packs", "upload_file"],
        "ashley": ["finalize_excel", "userLevelAccess", "retailProId", "style_inventory"],
        "coach": ["finalize_excel", "userLevelAccess", "retailProId"],
        "katespade": ["finalize_excel", "userLevelAccess", "retailProId"]

    }
    return featureObj[currentClientName]?.includes(feature)
}

//SSO login check

export const isSSOLogin = () => {
    let ssoLogin_clients_ids = [2,4,7,12]
    return ssoLogin_clients_ids?.includes(Number(clientEnvId))
        // && process.env.REACT_APP_CUSTOM_NODE_ENV === "production"
        // ? false
        // : false;
}

export const isApplicationLogin = () => {
    let appLogin_clients_ids = [1,3,4,5,6,7, 8, 9, 10] 
    return appLogin_clients_ids?.includes(Number(clientEnvId))
        // && process.env.REACT_APP_CUSTOM_NODE_ENV === "production"
        // ? false
        // : false;
}

export const callRefreshTokenApi =  () => {
    let headers = {authorizationkey: localStorage.getItem('access_token')}
    return axios.post(UPDATE_TOKEN,{token:localStorage.getItem('access_token')}, {headers: headers})
}

export const nextStep = () => {
    const isActive = sessionStorage.getItem("isActive");
    if(isActive === "true") {
        return 'refresh'
    }
    
    const jwt = localStorage.getItem("access_token");

    if (jwt) {
      const decodedJwt = jwt_decode(jwt)

      if (decodedJwt.exp * 1000 < Date.now()) {
          return 'logout';
      }
      else {
        return 'doNothing';
      }
    }
    return 'doNothing';
}

export const logoutUser = () => {
    localStorage.clear()
    // localStorage.removeItem("access_token");
    // localStorage.removeItem("group_id");
    // localStorage.removeItem("userName");
    // localStorage.removeItem("user_id");
    // localStorage.removeItem("email");
    // localStorage.removeItem("user_preference");
    // localStorage.removeItem("column_preference")

    sessionStorage.removeItem("isActive")
}

export const setLocalStorage = (token) => {
    localStorage.setItem('access_token', token);

    let decoded = jwt_decode(token)

    localStorage.setItem('group_id', decoded.group_id)
    localStorage.setItem('userName', decoded.name)
    localStorage.setItem('email', decoded.email)
    localStorage.setItem('user_id', decoded.user_id)
    localStorage.setItem('retail_pro_id', decoded.retail_pro_id)
}

export const setLocalStorageAfterLogin = (token) => {
    localStorage.setItem('access_token', token);
    var decoded = jwt_decode(token)

    postHogIdentify(decoded.email)
    
    localStorage.setItem('group_id', decoded.group_id)
    localStorage.setItem('userName', decoded.name)
    localStorage.setItem('email', decoded.email)
    localStorage.setItem('user_id', decoded.user_id)
    localStorage.setItem('retail_pro_id', decoded.retail_pro_id)
    // localStorage.setItem("column_preferences", JSON.stringify(decoded?.column_preferences?.[0] || {}))
    sessionStorage.setItem('isActive', true)
}

export const setColumnPreferenceAfterLogin = (token) => {
    localStorage.setItem("column_preferences", JSON.stringify(token?.[0] || {}))
}

// export const getUserHiddenColumns = (type, tableId) => {
//     // let userHiddenCols = JSON.parse(localStorage.getItem("hiddenColumns"))?.map(col => col.value) || []
//     // if(columns) {
//     //     columns = columns.map(col => col?.accessor)
//     //     return columns?.filter(item => userHiddenCols?.includes(item?.value)) || []

//     // }
//     let colPref = JSON.parse(localStorage.getItem("column_preferences"))?.["hiddenColumns"] || []
//     let userHiddenCols = colPref?.[tableId] ? colPref[tableId] : colPref?.["global"] 
//     return type === 1 ? (userHiddenCols?.map(col => col.value) || [])
//         : (userHiddenCols || [])
// }

// export const getUserPrefrenceColumns = () => {
//     return JSON.parse(localStorage.getItem("column_preference"))
// }


export const getUserHiddenColumns = (type, tableId, hideColumnsFilter) => {
    // let userHiddenCols = JSON.parse(localStorage.getItem("hiddenColumns"))?.map(col => col.value) || []
    // if(columns) {
    //     columns = columns.map(col => col?.accessor)
    //     return columns?.filter(item => userHiddenCols?.includes(item?.value)) || []

    // }
    let colPref = JSON.parse(localStorage.getItem("column_preferences"))?.["hiddenColumns"] || []
    let userHiddenCols = colPref?.[tableId] ? colPref[tableId] : colPref?.["global"] 
    return hideColumnsFilter ? [] : (type === 1 ? (userHiddenCols?.map(col => col.value) || [])
        : (userHiddenCols || []))
}

export const saveColumnOrderApi = async(column_preferences) => {
    let response = await Api.post(SAVE_USER_PREFERENCE_COLUMNS,
        {
            user_id: localStorage.getItem("user_id"),
            column_preferences: [column_preferences]
        })
        if (response?.data?.status) {
            setTimeout(() => {
                Notify.success("Column order saved successfully!");
            }, 200)
        }
        return response
}

//This functoon takes object as input and prepares req

export const prepareRequestObject = (p_req = {}) => {
    let l_req = {}
    let l_keys = Object.keys(p_req)
    l_keys?.forEach(val => {
     !isEmpty(p_req[val]) && (l_req[val] = p_req[val])
    })
    return l_req;
}

export const getLevelFiltersForUserPreference = (p_levelFilters) => {
    let l_userPreferenceFilters =  USER_PREFERENCE_FILTERS[currentClientName] || ["l1_name"]
    let l_preferenceFilter = {}
    l_userPreferenceFilters?.forEach(val => {
        p_levelFilters?.[val] && (l_preferenceFilter[val] = p_levelFilters?.[val])
    })
    return l_preferenceFilter
}

export const getLevelFiltersForReq = (p_filter) => {
   return Array.isArray(p_filter?.[0]) ? p_filter?.[0]?.map(val => val.value) : p_filter?.map(val => val.value); 
}

export const getLabelValueFromArray = (p_input) => {
    let l_output = (p_input || [] )?.map(val => {
        return {
            label: val,
            value: val
        }
    })
    return l_output;
}

export const fetchFiltersOnReset = async ({l1_name,l2_name,l3_name,onl1Change,onl2Change,onl3Change}) => {
    let l_mandatoryFilters = MANDATORY_SINGLE_SELECT_FILTERS[currentClientName] || ["l1_name"]
    
        l_mandatoryFilters.includes("l1_name") && onl1Change && onl1Change(l1_name,{user_preference:true})
        l_mandatoryFilters.includes("l2_name") && onl2Change && onl2Change(l2_name,{user_preference:true, l1_name:l1_name})
        l_mandatoryFilters.includes("l3_name") && onl3Change && onl3Change(l3_name,{user_preference:true, l1_name:l1_name, l2_name:l2_name})
}

export const raiseTicketLink = () => {
    const ticketingLinks = {
        "ashley": "https://impactanalytics.mojohelpdesk.com/login/create_request#/ticket-form/63618",
        "coach": "https://impactanalytics.mojohelpdesk.com/login/create_request#/ticket-form/62498",
        "puma": "https://impactanalytics.mojohelpdesk.com/login/create_request#/ticket-form/57813",
        "katespade": "https://impactanalytics.mojohelpdesk.com/login/create_request#/ticket-form/62498"
    }
    return ticketingLinks[currentClientName]
}

export const isFutureDate = day => day.isAfter(moment());

export const flattenArray = (filters) => filters.flat(Infinity)

export const getInventorySourceName = (source) => {
    return source ? INVENTORY_SOURCE_FILTER_DATA?.filter(val => val.value === source)[0]?.label : null;
}

export const findSum = (array) => array?.length ? array.reduce((acc, num) => acc+num) : 0

// custom function to slice an object keeping the keys in place.
export const sliceObject = (obj, startIndex, endIndex) => {
    const keys = Object.keys(obj);
    const slicedKeys = keys.slice(startIndex, endIndex);
    const result = {};
    slicedKeys.forEach((key) => {
      result[key] = obj[key];
    });
    return result;
}

export const logoutRedirect = () => {
  const isSSOLogin = JSON.parse(localStorage.getItem("isSSO"));
  if (isSSOLogin) {
    window.location.replace(SSO_ROUTE);
  } else {
    let url = LOGIN_ROUTE;
    window.history.pushState({ urlPath: url }, "", url);
    window.location.reload();
  }
};
