import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ColumnFilter from "../../UserManagement/ColumnFilter";
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import { ContractPO_ALLOCATION_TABLE_HEADERS } from "./ContractPOAllocationConstants";
import StylesPopup from "./StylesPopup";
import { dateFormat } from "../../../utils/commonUtilities";
import { POS_VALUES } from "../RecommendedAction/constantRecommendedAction";
import { numberUSFormatting } from "../../../utils/formatters/valueFormatters";
import ReactTollTip from "../../../components/Table/Cellrenderer/ReactToolTip";
import {
  FINALIZE_PAGE,
} from "../../../constants/routeConstants";
import * as Notify from "../../../components/Notification/Notifications";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ContractPOAllocationTable = (props) => {
  const {
    data,
    toRelease,
    isPopup,
    setTableInstance,
    type,
    status,
    rowData,
    selectedFilters,
    getCreateAllocationData,
  } = props;
  const history = useHistory();

  const [showStylesPopup, setShowStyles] = useState(false);
  const [selectedData, setStylesData] = useState({});
  const [initialHiddenColumns, setHiddenColumns] = useState([]);


  useEffect(() => {
    let hideColumns = [];
    if (isPopup) {
      hideColumns = [
        "allocation_name",
        "po_id",
        "vendor_id",
        "style_color_count",
        "created_at",
        "validity_period_start",
        "validity_period_end",
        "action",
      ];
    } 
    else if (rowData?.pos === POS_VALUES.AUTO_APPROVE_RELEASE_CONTRACT_PO) {
      hideColumns = [
        "style_color_count"
      ];
    }
    else {
      hideColumns = [
        "product_type",
        "article",
        "style_code",
        "color_code",
        "style_desc",
        "color_desc",
        "add_store_groups",
        "assortment_indicator",
      ];
    }

    // show these columns only For Auto-Approve, Release - Contract PO only
    if (rowData?.pos !== POS_VALUES.AUTO_APPROVE_RELEASE_CONTRACT_PO) {
      hideColumns = [
        ...hideColumns,
        "allocation_name",
        "created_at",
        "allocated_quantity",
      ];
    }
    // As per task GEN-2066 Show Review button for About to Expire Contract PO as well.
    // if (rowData?.pos === POS_VALUES.ABOUT_TO_EXPIRE_CONTRACT_PO) {
    //   hideColumns = [
    //     ...hideColumns,
    //     "action",
    //   ]
    // }

    setHiddenColumns(hideColumns);
  }, [toRelease, isPopup, type, status, rowData]);

  const columns = [
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.ALLOCATION_NAME,
      accessor: "allocation_name",
      Filter: ColumnFilter,
      width: 300,
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.PO_ID,
      accessor: "po_id",
      Filter: ColumnFilter,
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.VENDOR_ID,
      accessor: "vendor_id",
      Filter: ColumnFilter,
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.STYLE_COUNT,
      accessor: "style_color_count",
      disableFilters: true,
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.CREATION_DATE,
      accessor: "created_at",
      Cell: ({ value, row }) => dateFormat(value),
      disableFilters: true,
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.VALIDITY_START,
      accessor: "validity_period_start",
      Cell: ({ value, row }) => dateFormat(value),
      disableFilters: true,
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.VALIDITY_END,
      accessor: "validity_period_end",
      Cell: ({ value, row }) => dateFormat(value),
      disableFilters: true,
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.PRODUCT_TYPE,
      accessor: "product_type",
      Filter: ColumnFilter,
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.STYLE_COLOR_ID,
      accessor: "article",
      Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
      filter: 'bulkFilterCommaSeperated',
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.STYLE_ID,
      accessor: "style_code",
      Filter: ColumnFilter,
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.COLOR_ID,
      accessor: "color_code",
      Filter: ColumnFilter,
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.STYLE_DESC,
      accessor: "style_desc",
      Filter: ColumnFilter,
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.COLOR_DESC,
      accessor: "color_desc",
      Filter: ColumnFilter,
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.STORE_GROUP,
      accessor: "add_store_groups",
      Cell: (instance) =>
      <div className="row min-100">
        <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
           <ReactTollTip {...instance} />         
        </span>
      </div>,
      width: 200,
      Filter: ColumnFilter,
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.ASSORTMENT_INDICATOR,
      accessor: "assortment_indicator",
      Filter: ColumnFilter,
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.OH_OO,
      accessor: "oh_oo_intransit",
      Cell: (inst) => (
        <div>
            {inst.value || inst.value === 0
                ? numberUSFormatting(inst.value)
                : null}
        </div>
    ),
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.ECOM_SALES_UNIT,
      accessor: "ecom_lw_qty",
      Cell: (inst) => (
        <div>
            {inst.value || inst.value === 0
                ? numberUSFormatting(inst.value)
                : null}
        </div>
    ),
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.LW_SALES_UNIT,
      accessor: "lw_qty",
      Cell: (inst) => (
        <div>
            {inst.value || inst.value === 0
                ? numberUSFormatting(inst.value)
                : null}
        </div>
    ),
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.TARGET_QUANTITY,
      accessor: "target_quantity",
      Cell: (inst) => (
        <div>
            {inst.value || inst.value === 0
                ? numberUSFormatting(inst.value)
                : null}
        </div>
    ),
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.OPEN_TARGET_QUANTITY,
      accessor: "quantity",
      Cell: (inst) => (
        <div>
            {inst.value || inst.value === 0
                ? numberUSFormatting(inst.value)
                : null}
        </div>
    ),
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: ContractPO_ALLOCATION_TABLE_HEADERS.ALLOCATED_QTY,
      accessor: "allocated_quantity",
      Cell: (inst) => (
        <div>
            {inst.value || inst.value === 0
                ? numberUSFormatting(inst.value)
                : null}
        </div>
    ),
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: "Action",
      accessor: "action",
      sticky: "right",
      Cell: ({ row }) => (
        <div>
          <button
            className="btn btn-outline-secondary btn-sm px-2 rounded-md text-body"
            onClick={() => showContractPOStyles(row)}
          >
            Review
            <i
              className="fa fa-angle-right ml-2"
              title="Configure"
              aria-hidden="true"
            ></i>
          </button>
        </div>
      ),
      width: 150,
      disableFilters: true,
    },
  ];

  const showContractPOStyles = (row) => {
    setStylesData({
      data: row.originalSubRows,
      po: row.original.po_id,
      ...row?.original,
    });
    if(rowData?.pos === POS_VALUES.AUTO_APPROVE_RELEASE_CONTRACT_PO)
    {
      if (row?.values?.article) {
       let articles=[row?.values?.article]
        history.push(FINALIZE_PAGE, {
          planDetails: {
            isPOASNFlow: true,
            contractPO: true,
            articles,
            filters: selectedFilters,
            allocationCode: row?.values?.allocation_name,
            po_number: row?.values?.po_id,
            allocationName: row?.values?.allocation_name,
          },
        });
      } else {
        Notify.error("Please select atleast one article to Review!!");
      }
    }
    else{
      setShowStyles(true);
    }
  };

  return (
    <>
      <ReactTableWithPlugins
        shouldPagination
        data={data ? data : []}
        columns={columns}
        renderMarkup="TableMarkup"
        containSubRow={true}
        features={isPopup ? ["CHECKBOX_FOR_FIRSTCOLUMN"] : []}
        keyRT={
          toRelease ? "toBeReleased" : isPopup ? "StyleInvTable" : "autoApprove"
        }
        getInstanceOnMount={(instance) => {
          setTableInstance(instance);
        }}
        tableId={"Contract_po"}
        initialHiddenColumns={initialHiddenColumns}
      />
      {showStylesPopup && (
        <StylesPopup
          poData={selectedData}
          closeModal={() => {
            setShowStyles(false);
          }}
          showModal={showStylesPopup}
          rowData={rowData}
          selectedFilters={selectedFilters}
          getCreateAllocationData={getCreateAllocationData}
        />
      )}
    </>
  );
};

export default ContractPOAllocationTable;
