import {
  GET_STORE_DATA,
  GET_STORE_DATA_SUCCESS,
  GET_STORE_DATA_ERROR,
  GET_STORE_DETAILS,
  GET_STORE_DETAILS_SUCCESS,
  GET_STORE_DETAILS_ERROR,
  GET_STORE_PRODUCT_DETAILS,
  GET_STORE_PRODUCT_DETAILS_SUCCESS,
  GET_STORE_PRODUCT_DETAILS_ERROR,
  GET_PRODUCT_DATA,
  GET_PRODUCT_DATA_SUCCESS,
  GET_PRODUCT_DATA_ERROR,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_ERROR,
  EDIT_ALLOCATED_UNITS,
  EDIT_ALLOCATED_UNITS_SUCCESS,
  EDIT_ALLOCATED_UNITS_ERROR,
  DC_AVAILABLE_ERROR,
  RESET_EDIT_ERROR,
  RESET_ALL,
  DOWNLOAD_XML,
  DOWNLOAD_XML_SUCCESS,
  DOWNLOAD_XML_ERROR,
  DOWNLOAD_EXCEL,
  DOWNLOAD_EXCEL_SUCCESS,
  DOWNLOAD_EXCEL_ERROR,
  DOWNLOAD_CSV,
  DOWNLOAD_CSV_SUCCESS,
  DOWNLOAD_CSV_ERROR,
  FINALIZE_ALLOCATION,
  FINALIZE_ALLOCATION_SUCCESS,
  FINALIZE_ALLOCATION_ERROR,
  CONFIRM_DATA_ADA,
  CONFIRM_DATA_ADA_SUCCESS,
  CONFIRM_DATA_ADA_FAILED,
  RESET_FINALISE_DATA,
  GET_PACK_DETAILS_ERROR,
  GET_PACK_DETAILS_SUCCESS,
  GET_PACK_DETAILS,
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  UPDATE_FINALIZE_INFO_SUCCESS,
  UPDATE_FINALIZE_INFO_ERROR,
  UPDATE_FINALIZE_INFO,
  UPDATE_PACKS_EACHES_FOR_STORES,
  UPDATE_PACKS_EACHES_FOR_STORES_SUCCESS,
  UPDATE_PACKS_EACHES_FOR_STORES_ERROR,
  DISABLE_EDIT,
  DISABLE_EDIT_ERROR,
  DISABLE_EDIT_SUCCESS,
  GET_PACKS_FOR_BULK_UPDATE,
  GET_PACKS_FOR_BULK_UPDATE_SUCCESS,
  GET_PACKS_FOR_BULK_UPDATE_ERROR,
  DOWNLOAD_CSV_BEFORE_FINALIZE,
  DOWNLOAD_CSV_BEFORE_FINALIZE_SUCCESS,
  DOWNLOAD_CSV_BEFORE_FINALIZE_ERROR,
  SAVE_STORE_DATA,
  SAVE_STORE_DATA_SUCCESS,
  SAVE_STORE_DATA_ERROR,
  SET_EDIT_ALLOCATION_CODE,
  RESET_EDIT_UNITS_SUCCESS,
} from "./FinalizeAction";
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export const finalizeState = {
  storeDataLoading: false,
  storeDetailsDataLoading: false,
  productDataLoading: false,
  productDetailsDataLoading: false,
  storeProductDetailsLoading: false,
  editAllocatedUnitSuccess: false,
  editAllocatedUnitError: false,
  dcAvailableError: false,
  downloadXmlLoading: false,
  downloadExcelLoading: false,
  downloadCsvLoading: false,
  finalizeLoading: false,
  storeData: [],
  storeAllocationData: [],
  productAllocationData: [],
  storeDCs: [],
  storeDetailsData: { data: [], sizes: [] },
  productData: { data: [], sizes: [], dc_list: [] },
  productDetailsData: { data: [], sizes: [] },
  storeProductDetails: { data: [], sizes: [] },
  downloadXmlData: {},
  downloadExcelData: [],
  downloadCsvData: [],
  finalizeData: {},
  storeDataError: null,
  storeDetailsDataError: null,
  productDataError: null,
  productDetailsDataError: null,
  storeProductDetailsError: null,
  downloadXmlError: null,
  downloadExcelError: null,
  downloadCsvError: null,
  finalizeError: null,
  packColumns: [],
  max_size_mapping: [],
  packData: [],
  updatePackEachesData: false,
  packsForBulkUpdateLoading: false,
  packDataForBulkUpdate: [],
  packForBulkUpdateError: false,
  packLoading: false,
  updatePackEachesLoading: false,
  packError: false,
  updatePackEachesError: false,
  uploadSuccess: false,
  uploadLoading: false,
  uploadError: false,
  finalizeInfoSaved: false,
  disableEditLoading: false,
  disableEditData: false,
  disableEditError: false,
  confirmDataAdaSuccess: false,
  alloacationCode: null,
  csvBeforeFinalizeLoading: false,
  csvBeforeFinalizeData: [],
  csvBeforeFinalizeHeader: [],
  csvBeforeFinalizeError: false,
  saveSuccess: false,
  saveError: false,
  allocationStatus: false
};

export default {
  finalize: handleActions(
    {
      [GET_STORE_DATA]: (state) => {
        return immutable(state, {
          storeDataLoading: { $set: true },
          storeData: { $set: [] },
          storeDetailsData: { $set: { data: [], sizes: [] } },
          editAllocatedUnitSuccess: { $set: false },
          editAllocatedUnitError: { $set: false },
          dcAvailableError: { $set: false },
          storeAllocationData: { $set: [] },
          updatePackEachesLoading: { $set: false },
          updatePackEachesData: { $set: false },
          updatePackEachesError: { $set: false },
          storeDataError: { $set: null },
          storeDCs: { $set: [] },
        });
      },
      [GET_STORE_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          storeData: { $set: (payload && payload.data) || [] },
          storeAllocationData: {
            $set: (payload && payload.storeAllocationData) || [],
          },
          storeDCs: { $set: (payload && payload.dc) || [] },
          storeDataLoading: { $set: false },
          storeDataError: { $set: null },
          productDataError: { $set: null },
          allocationStatus: {$set: payload.allocationStatus}
        });
      },
      [GET_STORE_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          storeDataError: {
            $set: (payload && payload.error) || "Something Went Wrong",
          },
          productDataError: {
            $set: (payload && payload.error) || "Something Went Wrong",
          },
          storeDataLoading: { $set: false },
          storeData: { $set: [] },
          storeAllocationData: { $set: [] },
          storeDCs: { $set: [] },
        });
      },
      [GET_STORE_DETAILS]: (state) => {
        return immutable(state, {
          storeDetailsDataLoading: { $set: true },
          storeDetailsData: { $set: { data: [], sizes: [] } },
          storeProductDetails: { $set: { data: [], sizes: [] } },
        });
      },
      [GET_STORE_DETAILS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          storeDetailsData: {
            $set: (payload && payload.data) || { data: [], sizes: [] },
          },
          storeDetailsDataLoading: { $set: false },
          storeDetailsDataError: { $set: null },
        });
      },
      [GET_STORE_DETAILS_ERROR]: (state, { payload }) => {
        return immutable(state, {
          storeDetailsDataError: { $set: (payload && payload.error) || null },
          storeDetailsDataLoading: { $set: false },
          storeDetailsData: { $set: { data: [], sizes: [] } },
        });
      },

      [GET_STORE_PRODUCT_DETAILS]: (state) => {
        return immutable(state, {
          storeProductDetailsLoading: { $set: true },
          storeProductDetails: { $set: { data: [], sizes: [] } },
        });
      },
      [GET_STORE_PRODUCT_DETAILS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          storeProductDetails: {
            $set: (payload && payload.data) || { data: [], sizes: [] },
          },
          storeProductDetailsLoading: { $set: false },
          storeProductDetailsError: { $set: null },
        });
      },
      [GET_STORE_PRODUCT_DETAILS_ERROR]: (state, { payload }) => {
        return immutable(state, {
          storeProductDetailsError: {
            $set: (payload && payload.error) || null,
          },
          storeProductDetailsLoading: { $set: false },
          storeProductDetails: { $set: { data: [], sizes: [] } },
        });
      },
      [GET_PRODUCT_DATA]: (state) => {
        return immutable(state, {
          productDataLoading: { $set: true },
          productData: { $set: { data: [], sizes: [], dc_list: [] } },
          editAllocatedUnitSuccess: { $set: false },
          dcAvailableError: { $set: false },
          productAllocationData: { $set: [] },
          updatePackEachesLoading: { $set: false },
          updatePackEachesData: { $set: false },
          updatePackEachesError: { $set: false },
          productDataError: { $set: null },
          storeDataError: { $set: null },
        });
      },
      [GET_PRODUCT_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          productData: {
            $set: (payload && payload.data) || {
              data: [],
              sizes: [],
              dc_list: [],
            },
          },
          productDataLoading: { $set: false },
          productDataError: { $set: null },
          storeDataError: { $set: null },
          productAllocationData: {
            $set: (payload && payload.productAllocationData) || [],
          },
          allocationStatus: {$set: payload.allocationStatus}
        });
      },
      [GET_PRODUCT_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          productDataError: {
            $set: (payload && payload.error) || "Something Went Wrong",
          },
          storeDataError: {
            $set: (payload && payload.error) || "Something Went Wrong",
          },
          productDataLoading: { $set: false },
          productData: { $set: { data: [], sizes: [], dc_list: [] } },
          productAllocationData: { $set: [] },
        });
      },
      [GET_PACK_DETAILS]: (state) => {
        return immutable(state, {
          packLoading: { $set: true },
          packData: { $set: [] },
          packColumns: { $set: [] },
          max_size_mapping: { $set: [] },
          packError: { $set: false },
        });
      },
      [GET_PACK_DETAILS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          packLoading: { $set: false },
          packData: { $set: (payload && payload.data) || [] },
          packColumns: { $set: (payload && payload.columns) || [] },
          max_size_mapping: {
            $set: (payload && payload.max_size_mapping) || [],
          },
          packError: { $set: false },
        });
      },
      [GET_PACK_DETAILS_ERROR]: (state, { payload }) => {
        return immutable(state, {
          packLoading: { $set: false },
          packData: { $set: [] },
          packColumns: { $set: [] },
          max_size_mapping: { $set: [] },
          packError: { $set: true },
        });
      },

      [UPDATE_PACKS_EACHES_FOR_STORES]: (state) => {
        return immutable(state, {
          updatePackEachesLoading: { $set: true },
          updatePackEachesData: { $set: false },
          updatePackEachesError: { $set: false },
        });
      },
      [UPDATE_PACKS_EACHES_FOR_STORES_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          alloacationCode: {
            $set: (payload && payload.allocationCode) || state.alloacationCode,
          },
          updatePackEachesLoading: { $set: false },
          updatePackEachesData: { $set: (payload && payload.data) || false },
          updatePackEachesError: { $set: false },
        });
      },
      [UPDATE_PACKS_EACHES_FOR_STORES_ERROR]: (state, { payload }) => {
        return immutable(state, {
          updatePackEachesLoading: { $set: false },
          updatePackEachesData: { $set: false },
          updatePackEachesError: { $set: true },
        });
      },

      [GET_PACKS_FOR_BULK_UPDATE]: (state) => {
        return immutable(state, {
          packsForBulkUpdateLoading: { $set: true },
          packDataForBulkUpdate: { $set: [] },
          packForBulkUpdateError: { $set: false },
        });
      },
      [GET_PACKS_FOR_BULK_UPDATE_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          packsForBulkUpdateLoading: { $set: false },
          packDataForBulkUpdate: { $set: (payload && payload.data) || [] },
          packForBulkUpdateError: { $set: false },
        });
      },
      [GET_PACKS_FOR_BULK_UPDATE_ERROR]: (state, { payload }) => {
        return immutable(state, {
          packsForBulkUpdateLoading: { $set: false },
          packDataForBulkUpdate: { $set: [] },
          packForBulkUpdateError: { $set: true },
        });
      },

      [GET_PRODUCT_DETAILS]: (state) => {
        return immutable(state, {
          productDetailsDataLoading: { $set: true },
          productDetailsData: { $set: { data: [], sizes: [] } },
        });
      },
      [GET_PRODUCT_DETAILS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          productDetailsData: {
            $set: (payload && payload.data) || { data: [], sizes: [] },
          },
          productDetailsDataLoading: { $set: false },
          productDetailsDataError: { $set: null },
        });
      },
      [GET_PRODUCT_DETAILS_ERROR]: (state, { payload }) => {
        return immutable(state, {
          productDetailsDataError: { $set: (payload && payload.error) || null },
          productDetailsDataLoading: { $set: false },
          productDetailsData: { $set: { data: [], sizes: [] } },
        });
      },
      [EDIT_ALLOCATED_UNITS]: (state) => {
        return immutable(state, {
          editAllocatedUnitSuccess: { $set: false },
          editAllocatedUnitError: { $set: false },
          dcAvailableError: { $set: false },
          packLoading: { $set: true },
        });
      },
      [EDIT_ALLOCATED_UNITS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          alloacationCode: {
            $set: (payload && payload.allocationCode) || state.alloacationCode,
          },
          editAllocatedUnitSuccess: { $set: true },
          editAllocatedUnitError: { $set: false },
          packLoading: { $set: false },
        });
      },
      [EDIT_ALLOCATED_UNITS_ERROR]: (state, { payload }) => {
        return immutable(state, {
          editAllocatedUnitSuccess: { $set: false },
          editAllocatedUnitError: { $set: true },
          packLoading: { $set: false },
        });
      },
      [DOWNLOAD_XML]: (state) => {
        return immutable(state, {
          downloadXmlLoading: { $set: true },
          downloadXmlData: { $set: {} },
        });
      },
      [DOWNLOAD_XML_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          downloadXmlLoading: { $set: false },
          downloadXmlError: { $set: null },
          downloadXmlData: { $set: (payload && payload.data) || {} },
        });
      },
      [DOWNLOAD_XML_ERROR]: (state, { payload }) => {
        return immutable(state, {
          downloadXmlLoading: { $set: false },
          downloadXmlError: { $set: (payload && payload.error) || null },
          downloadXmlData: { $set: {} },
        });
      },
      [DOWNLOAD_EXCEL]: (state) => {
        return immutable(state, {
          downloadExcelLoading: { $set: true },
          downloadExcelData: { $set: [] },
        });
      },
      [DOWNLOAD_EXCEL_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          downloadExcelLoading: { $set: false },
          downloadExcelError: { $set: null },
          downloadExcelData: { $set: (payload && payload.data) || [] },
        });
      },
      [DOWNLOAD_EXCEL_ERROR]: (state, { payload }) => {
        return immutable(state, {
          downloadExcelLoading: { $set: false },
          downloadExcelError: { $set: (payload && payload.error) || null },
          downloadExcelData: { $set: [] },
        });
      },
      [DOWNLOAD_CSV]: (state) => {
        return immutable(state, {
          downloadCsvLoading: { $set: true },
          downloadCsvData: { $set: [] },
        });
      },
      [DOWNLOAD_CSV_SUCCESS]: (state, payload) => {
        return immutable(state, {
          downloadCsvLoading: { $set: false },
          downloadCsvError: { $set: null },
          downloadCsvData: { $set: (payload && payload.data) || [] },
        });
      },
      [DOWNLOAD_CSV_ERROR]: (state, payload) => {
        return immutable(state, {
          downloadCsvLoading: { $set: false },
          downloadCsvError: { $set: (payload && payload.error) || null },
          downloadCsvData: { $set: [] },
        });
      },
      [DOWNLOAD_CSV_BEFORE_FINALIZE]: (state) => {
        return immutable(state, {
          csvBeforeFinalizeLoading: { $set: true },
          csvBeforeFinalizeData: { $set: [] },
          csvBeforeFinalizeHeader: { $set: [] },
        });
      },
      [DOWNLOAD_CSV_BEFORE_FINALIZE_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          csvBeforeFinalizeLoading: { $set: false },
          csvBeforeFinalizeError: { $set: null },
          csvBeforeFinalizeData: { $set: (payload && payload.data) || [] },
          csvBeforeFinalizeHeader: { $set: (payload && payload.headers) || [] },
        });
      },
      [DOWNLOAD_CSV_BEFORE_FINALIZE_ERROR]: (state, { payload }) => {
        return immutable(state, {
          csvBeforeFinalizeLoading: { $set: false },
          csvBeforeFinalizeError: { $set: (payload && payload.error) || null },
          csvBeforeFinalizeData: { $set: [] },
          csvBeforeFinalizeHeader: { $set: [] },
        });
      },
      [FINALIZE_ALLOCATION]: (state, { payload }) => {
        return immutable(state, {
          finalizeLoading: { $set: true },
          finalizeError: { $set: null },
          finalizeData: { $set: {} },
        });
      },
      [FINALIZE_ALLOCATION_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          finalizeLoading: { $set: false },
          finalizeError: { $set: null },
          finalizeData: { $set: (payload && payload.data) || {} },
        });
      },
      [FINALIZE_ALLOCATION_ERROR]: (state, { payload }) => {
        return immutable(state, {
          finalizeLoading: { $set: false },
          finalizeError: { $set: (payload && payload.error) || null },
          finalizeData: { $set: (payload && payload.data) || {} },
        });
      },
      [CONFIRM_DATA_ADA]: (state, { payload }) => {
        return immutable(state, {
          confirmDataAdaSuccess: { $set: false },
        });
      },
      [CONFIRM_DATA_ADA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          confirmDataAdaSuccess: { $set: true },
        });
      },
      [CONFIRM_DATA_ADA_FAILED]: (state, { payload }) => {
        return immutable(state, {
          confirmDataAdaSuccess: { $set: false },
        });
      },
      [UPDATE_FINALIZE_INFO]: (state, { payload }) => {
        return immutable(state, {
          finalizeInfoSaved: { $set: false },
        });
      },
      [UPDATE_FINALIZE_INFO_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          finalizeInfoSaved: { $set: false },
        });
      },
      [UPDATE_FINALIZE_INFO_ERROR]: (state, { payload }) => {
        return immutable(state, {
          finalizeInfoSaved: { $set: true },
        });
      },
      [UPLOAD_FILE]: (state, { payload }) => {
        return immutable(state, {
          uploadLoading: { $set: true },
          uploadError: { $set: false },
          uploadSuccess: { $set: false },
        });
      },
      [UPLOAD_FILE_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          uploadLoading: { $set: false },
          uploadError: { $set: false },
          uploadSuccess: { $set: true },
        });
      },
      [UPLOAD_FILE_ERROR]: (state, { payload }) => {
        return immutable(state, {
          uploadLoading: { $set: false },
          uploadError: { $set: true },
          uploadSuccess: { $set: false },
        });
      },
      [DC_AVAILABLE_ERROR]: (state, { payload }) => {
        return immutable(state, {
          dcAvailableError: { $set: payload },
        });
      },
      [RESET_EDIT_ERROR]: (state) => {
        return immutable(state, {
          dcAvailableError: { $set: false },
          editAllocatedUnitError: { $set: false },
        });
      },
      [RESET_ALL]: (state) => {
        return immutable(state, {
          storeDataLoading: { $set: false },
          storeDetailsDataLoading: { $set: false },
          productDataLoading: { $set: false },
          productDetailsDataLoading: { $set: false },
          storeProductDetailsLoading: { $set: false },
          editAllocatedUnitSuccess: { $set: false },
          editAllocatedUnitError: { $set: false },
          dcAvailableError: { $set: false },
          downloadExcelLoading: { $set: false },
          downloadXmlLoading: { $set: false },
          finalizeLoading: { $set: false },
          storeData: { $set: [] },
          storeDetailsData: { data: [], sizes: [] },
          storeProductDetails: { $set: { data: [], sizes: [] } },
          productData: { $set: { data: [], sizes: [], dc_list: [] } },
          productDetailsData: { $set: { data: [], sizes: [] } },
          downloadXmlData: { $set: {} },
          downloadExcelData: { $set: [] },
          // finalizeData: { $set: {} },
          storeDataError: { $set: null },
          storeDetailsDataError: { $set: null },
          productDataError: { $set: null },
          productDetailsDataError: { $set: null },
          storeProductDetailsError: { $set: null },
          downloadXmlError: { $set: null },
          downloadExcelError: { $set: null },
          finalizeError: { $set: null },
          downloadCsvLoading: { $set: true },
          downloadCsvData: { $set: [] },
          csvBeforeFinalizeHeader: { $set: [] },
          downloadCsvError: { $set: null },
          csvBeforeFinalizeLoading: { $set: false },
          csvBeforeFinalizeData: { $set: [] },
          csvBeforeFinalizeError: { $set: null },
          uploadLoading: { $set: false },
          uploadError: { $set: false },
          uploadSuccess: { $set: false },
          storeDCs: { $set: [] },
          disableEditLoading: { $set: false },
          disableEditData: { $set: false },
          disableEditError: { $set: false },
          alloacationCode: { $set: null },
        });
      },
      [DISABLE_EDIT]: (state) => {
        return immutable(state, {
          disableEditLoading: { $set: true },
          disableEditData: { $set: false },
          disableEditError: { $set: false },
        });
      },
      [DISABLE_EDIT_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          disableEditLoading: { $set: false },
          disableEditData: { $set: true },
          disableEditError: { $set: false },
        });
      },
      [DISABLE_EDIT_ERROR]: (state, { payload }) => {
        return immutable(state, {
          disableEditLoading: { $set: false },
          disableEditData: { $set: false },
          disableEditError: { $set: true },
        });
      },

      [SAVE_STORE_DATA]: (state) => {
        return immutable(state, {
          storeDataLoading: { $set: true },
          saveSuccess: { $set: false },
          storeDataError: { $set: null },
        });
      },
      [SAVE_STORE_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          storeDataLoading: { $set: false },
          storeDataError: { $set: null },
          saveSuccess: { $set: true },
        });
      },
      [SAVE_STORE_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          storeDataError: {
            $set: (payload && payload.error) || "Something Went Wrong",
          },
          productDataError: {
            $set: (payload && payload.error) || "Something Went Wrong",
          },
          storeDataLoading: { $set: false },
          storeData: { $set: [] },
          storeAllocationData: { $set: [] },
          storeDCs: { $set: [] },
          saveSuccess: { $set: false },
        });
      },

      [RESET_FINALISE_DATA]: (state) => {
        return immutable(state, {
          finalizeData: { $set: {} },
        });
      },

      [SET_EDIT_ALLOCATION_CODE]: (state, payload) => {
          return immutable(state, {
              alloacationCode: { $set: (payload && payload.allocationCode) || state.alloacationCode},
          })
      },
      [RESET_EDIT_UNITS_SUCCESS]: (state) => {
        return immutable(state, {
            editAllocatedUnitSuccess: { $set: false },
        })
      },
    },
    finalizeState
  ),
};
