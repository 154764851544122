import * as actionTypes from "./actionTypes"

/*
Function to dispatch action to get po allocation data
*/

export const getContractPoData = (payload) => ({
    type: actionTypes.GET_CONTRACT_PO_DATA,
    payload
})

export const getAutoApprovalreleaseContractPoData = (payload) => ({
    type: actionTypes.GET_AUTO_APPROVAL_RELEASE_CONTRACT_PO_DATA,
    payload
})