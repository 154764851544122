import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import PageLoader from '../../../components/Loader/PageLoader';
import InputCell from '../../../components/Table/Cellrenderer/InputCell';
import SelectCell from '../../../components/Table/Cellrenderer/SelectCell';
import SelectCellComponent from '../../../components/Table/Cellrenderer/SelectCellComponent';
import ReactTable from '../../../components/Table/ReactTable';
import WithDynamicPlugins from '../../../components/Table/WithPlugins';
import ColumnFilter from '../../UserManagement/ColumnFilter';
import { updateTableData } from '../ConstraintsAction';
import { levelFiltersLabelFormatter, updateArticleName } from "../../../utils/filterLevelMapping"
import NumberRangeColumnFilter from '../../../components/Filters/NumberRangeColumnFilter';
import SetAllModal from '../../../components/ErrorModal/SetAllModal';
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import _, { cloneDeep, isEmpty, isNull } from 'lodash';
import ColumnFilterServerSide from '../../../components/Filters/ColumnFilterServerSide';
import NumberRangeColumnFilterServerSide from '../../../components/Filters/NumberRangeColumnFilterServerSide';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);
const pageSize = 20
export function range(start, end) {
  return Array(end - start).fill().map((_, idx) => start + idx)
}

const Store = (props) => {

  const [RTinstance, setRTinstance] = useState(null)
  const [isButtonClicked, setIsButtonClicked] = useState(false)
  const [filters, setFilters] = useState(props.filters)
  const [isDisabled, setIsDisabled] = useState(true)
  const [shouldLoading, setShouldLoading] = useState(false)
  const [loaderMessage, setLoaderMessage] = useState(null)
  const [prevIndex, setPrevIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(null)
  const [nextIndex, setIndex] = useState(0)
  // const [isDataLoading, setIsDataLoading] = useState(false)
  const [initialIndex, setInitialIndex] = useState(0)
  const [showSetAll, setShowSetAll] = useState(false)
  const [minPerStoreSA, setMinPerStoreSA] = useState(null)
  const [maxPerStoreSA, setMaxPerStoreSA] = useState(null)
  const [transitSA, setTransitSA] = useState(null)
  const [wosSA, setwosSA] = useState(null)
  const [storeData, setStoreData] = useState([])
  const [updatedRows, setUpdatedRows] = useState([])
  const [initialSelectedRows, setInitialSelectedRows] = useState({})
  const [setAll, setSetAll] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState("*Avg. Min per store will be set to 0")
  const [searchTermReq, setSearchTermReq] = useState({})
  const [sortReq, setSortReq] = useState({})
  const [isFirstCall, setisFirstCall] = useState(true)
  const [dcColumns, setDCColumns] = useState([])
  const [hideTotalRecords, setHideTotalRecords] = useState(true)
  const [hideTotalPages, setHideTotalPages] = useState(true)
  const [setAllDisabled, setDisabled] = useState(false)
  

  const columns = React.useMemo(
    () => [
      {
        Header: `${updateArticleName(false)} ID`,
        accessor: 'article',
        Filter: (instance) => (
          <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.article?.search} placeholder={"Search in bulk by ','"} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "array", ",")} />
        ),
      },
      {
        Header: "Style ID",
        accessor: "style_code",
        Filter: (instance) => (
          <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_code?.search} placeholder={"Search in bulk.."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "array")} />
        ),
        // sticky: "left"
      },
      {
        Header: "Color ID",
        accessor: "color_code",
        Filter: (instance) => (
          <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_code?.search} placeholder={"Search in bulk.."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "array")} />
        ),        // sticky: "left"
      },
      {
        Header: "Assortment Indicator",
        accessor: "assortment_indicator",
        width: 250,
        Filter: (instance) => (
          <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.assortment_indicator?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
        ),         // sticky: "left"
      },
      {
        Header: "Country",
        accessor: 'country',
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: levelFiltersLabelFormatter("level1"),
        accessor: 'l1_name',
        disableFilters: true
      },
      {
        Header: props?.filterLabels.level2 || levelFiltersLabelFormatter("level2"),
        accessor: 'l2_name',
        disableFilters: true
      },
      {
        Header: props?.filterLabels.level3 || levelFiltersLabelFormatter("level3"),
        accessor: 'l3_name',
        disableFilters: true
      },
      {
        Header: props?.filterLabels.level4 || levelFiltersLabelFormatter("level4"),
        accessor: 'l4_name',
        disableFilters: true
      },
      {
        Header: props?.filterLabels.level5 || levelFiltersLabelFormatter("level5"),
        accessor: 'l5_name',
        disableFilters: true
      },
      {
        Header: props?.filterLabels.level6 || levelFiltersLabelFormatter("level6"),
        accessor: 'l6_name',
        disableFilters: true
      },
      {
        Header: 'Style',
        accessor: 'style_name',
        width: 330,
        Filter: (instance) => (
          <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
        ),
      },
      {
        Header: 'Global Color',
        accessor: 'color',
        Filter: (instance) => (
          <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
        ),
      },

      {
        Header: 'Style Color',
        accessor: 'color_desc',
        width: 200,
        Cell: ({ value, row }) =>
          (row.canExpand || row.depth === 0) ? (
            <span>{value}</span>
          ) : <span style={{ backgroundColor: "rgb(224,224,224)", minHeight: "100%", minWidth: "100%" }}> </span>,
        Filter: (instance) => (
          <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_desc?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
        ),
      },
      {
        Header: 'Size',
        accessor: 'size_desc',
        Filter: (instance) => (
          <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.size_desc?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
        ),
      },
      {
        Header: 'Store Number',
        accessor: 'store_code',
        Filter: (instance) => (
          <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.store_code?.search} placeholder={"Search in bulk.."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "array")} />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Store Name',
        accessor: 'store_name',
        width: 300,
        Filter: (instance) => (
          <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.store_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Store Grade',
        accessor: 'store_grade',
        Filter: (instance) => (
          <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.store_grade?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "exact")} />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Avg. Min per store',
        accessor: 'min_store',
        Cell: (instance) => <InputCell shouldOnBlur roundOffOnChange min={0} max={Number(instance.row?.original?.max_store)} step={1} type="number" {...instance}
          debouncedChangeHandler={(rowIndex, columnId, value, rowId, subRow, p_row) => {
            setStoreData((oldData) => {
              if (oldData?.length) {
                let newData = cloneDeep(oldData);
                newData[rowIndex][columnId] = value;
                return newData;
              }
              else {
                return oldData
              }
            })

            setInitialIndex(RTinstance.state.pageIndex)
            setIsDisabled((prev) => {
              if (prev) {
                return false;
              }
            });
            setUpdatedRows((old) => {
              return [...old, p_row.original.unique]
            })
          }}
        />,
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.min_store?.value?.min} maxSearchTermValue={searchTermReq?.min_store?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
        ),
        filter: 'between',
        disableSortBy: true,
      },
      {
        Header: 'Avg. Max.per store',
        accessor: 'max_store',
        Cell: (instance) => <InputCell shouldOnBlur roundOffOnChange min={Number(instance.row?.original?.min_store)} step={1} type="number" {...instance}
          debouncedChangeHandler={(rowIndex, columnId, value, rowId, subRow, p_row) => {
            setStoreData((oldData) => {
              if (oldData?.length) {
                let newData = cloneDeep(oldData);
                newData[rowIndex][columnId] = value;
                return newData;
              }
              else {
                return oldData
              }
            })

            setInitialIndex(RTinstance.state.pageIndex)
            setIsDisabled((prev) => {
              if (prev) {
                return false;
              }
            });
            setUpdatedRows((old) => {
              return [...old, p_row.original.unique]
            })
          }}
        />,
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.max_store?.value?.min} maxSearchTermValue={searchTermReq?.max_store?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
        ),
        filter: 'between',
        disableSortBy: true,
      },
      {
        Header: 'WOS',
        accessor: 'wos',
        Cell: (instance) => <InputCell roundOffOnChange min={0} step={1} type="number" {...instance}
          debouncedChangeHandler={(rowIndex, columnId, value, rowId, subRow, p_row) => {
            setStoreData((oldData) => {
              if (oldData?.length) {
                let newData = cloneDeep(oldData);
                newData[rowIndex][columnId] = value;
                return newData;
              }
              else {
                return oldData
              }
            })

            setInitialIndex(RTinstance.state.pageIndex)
            setUpdatedRows((old) => {
              return [...old, p_row.original.unique]
            })
            setIsDisabled((prev) => {
              if (prev) {
                return false;
              }
            });
          }}
        />,
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.wos?.value?.min} maxSearchTermValue={searchTermReq?.wos?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
        ),
        filter: 'between',
        disableSortBy: true,
      },
      // {
      //   Header: 'Transit Time',
      //   accessor: 'transit_time_sum',
      //   Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}
      //      debouncedChangeHandler={(rowIndex,columnId,value,rowId,subRow,p_row) => {
      //                 setIsDisabled((prev) => {
      //                     if (prev) {
      //                       return false;
      //                     }
      //                 });
      //                 setInitialIndex(RTinstance.state.pageIndex)
      //                 setStoreData((oldData) => {
      //                   let newData = cloneDeep(oldData);
      //                   newData[rowIndex][columnId] = value;
      //                   return newData;
      //                 })
      //                 setUpdatedRows((old) => {
      //                   return [...old,p_row.original.unique]
      //                 })
      //           }}
      //   />,
      //   Filter: (instance) => (
      //     <NumberRangeColumnFilter  {...instance}/>
      //   ),
      //   filter: 'between',
      //   disableSortBy: true,
      // },
      {
        Header: "Transit Time",
        columns: dcColumns
        // [
        // {
        //   Header: 'PUMA Retail Stores WH10',
        //   accessor: 'dc_store_1',
        //   Filter: (instance) => (
        //     <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.dc_store_1?.value?.min} maxSearchTermValue= {searchTermReq?.dc_store_1?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
        //   ),
        //   filter: 'between',
        //   disableSortBy: true,
        // },
        // {
        //   Header: 'U.S. Continental',
        //   accessor: 'dc_store_2',
        //   Filter: (instance) => (
        //     <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.dc_store_2?.value?.min} maxSearchTermValue= {searchTermReq?.dc_store_2?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
        //   ),
        //   filter: 'between',
        //   disableSortBy: true,
        // },
        // {
        //   Header: 'Retail Bulk',
        //   accessor: 'dc_store_3',
        //   Filter: (instance) => (
        //     <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.dc_store_3?.value?.min} maxSearchTermValue= {searchTermReq?.dc_store_3?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
        //   ),
        //   filter: 'between',
        //   disableSortBy: true,
        // },
        // {
        //   Header: 'Retail Bulk WH20',
        //   accessor: 'dc_store_4',
        //   Filter: (instance) => (
        //     <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.dc_store_4?.value?.min} maxSearchTermValue= {searchTermReq?.dc_store_4?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
        //   ),
        //   filter: 'between',
        //   disableSortBy: true,
        // },
        // {
        //   Header: 'United Legwear WHS',
        //   accessor: 'dc_store_5',
        //   Filter: (instance) => (
        //     <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.dc_store_5?.value?.min} maxSearchTermValue= {searchTermReq?.dc_store_5?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
        //   ),
        //   filter: 'between',
        //   disableSortBy: true,
        // },
        // {
        //   Header: 'Infinity',
        //   accessor: 'dc_store_6',
        //   Filter: (instance) => (
        //     <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.dc_store_6?.value?.min} maxSearchTermValue= {searchTermReq?.dc_store_6?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
        //   ),
        //   filter: 'between',
        //   disableSortBy: true,
        // }
        // ]
      },
      {
        Header: 'Updated By',
        accessor: 'updated_by',
        Filter: (instance) => (
          <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.updated_by?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
        ),
        width: 250,
        disableSortBy: true
      },
      {
        Header: 'Last Modified',
        accessor: 'updated_at',
        disableFilters: true,
        width: 250,
        disableSortBy: true
      },
    ], [RTinstance, searchTermReq, dcColumns])

  useEffect(() => {
    if (props.isUpdated && isButtonClicked) {
      setShouldLoading(false)
      setLoaderMessage(null)
      setInitialIndex(0)
      setPrevIndex(0)
      props.resetStyleIndex()
      props.fetchStoreData({ filters: props.filters, "rowCount": props.rowsCount, "rowIndex": props.initialindex, styleIndex: 0 })
    }
  }, [props.isUpdated])

  useEffect(() => {
    if (props.out_of_data) {
      setHideTotalPages(false);
      setHideTotalRecords(false);
    }
    else {
      setHideTotalPages(true);
      setHideTotalRecords(true);
    }
  }, [props.out_of_data])

  useEffect(() => {
    if (props.storeData) {
      // const {dcs} = props.storeData[0]
      let dcColumnNames = []
      props?.storeData[0]?.dcs?.forEach(dcName => {
        dcColumnNames.push({
          Header: dcName,
          accessor: dcName,
          Filter: (instance) => (
            <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.[dcName]?.value?.min} maxSearchTermValue={searchTermReq?.[dcName]?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
          ),
          filter: 'between',
          disableSortBy: true,
        })
      })
      setDCColumns(dcColumnNames)
    }
    if (props.isFilterClicked) {
      setSearchTermReq({})
      setSortReq({})
      setisFirstCall(true)
      props.resetIsFilterClicked()
    }
    if (isEmpty(props?.storeData)) {
      setInitialIndex(0)
      setPrevIndex(0)
      setSetAll(false)
      setInitialSelectedRows({})
    }
    setStoreData(props?.storeData)
  }, [props?.storeData])

  useEffect(() => {
    if (setAll && storeData.length >= props.rowsCount) {
      let initialSelectedRows = {}
      range(Number(storeData.length) - Number(props.rowsCount), storeData.length).forEach((item, index) => {
        initialSelectedRows[`${item}`] = true
      })
      setInitialSelectedRows((oldData) => {
        return {
          ...oldData,
          ...initialSelectedRows
        }
      })
    }
  }, [storeData])

  useEffect(() => {
    if (maxPerStoreSA == "")
      setShowMessage(false)
    else if (maxPerStoreSA == 0)
      setShowMessage(true)
    else
      setShowMessage(false)
  }, [maxPerStoreSA])

  const applyConstraints = (p_setAll, p_rows) => {
    let data
    setSortReq({})
    setSearchTermReq({})
    if (p_setAll) {
      let req = {
        ...props?.storeRequest,
        "screen_type": "set_all",
      }
      if (wosSA || wosSA == 0) {
        req["wos"] = wosSA
      }
      if (minPerStoreSA || minPerStoreSA == 0) {
        req["min_store"] = minPerStoreSA
      }
      if (maxPerStoreSA || maxPerStoreSA == 0) {
        if (showMessage)
          req['min_store'] = "0"
        req["max_store"] = maxPerStoreSA
      }
      req["country"] = props?.filters?.country?.map(country => country?.value)

      const { rowCount, rowIndex, ...updatedReq } = req
      setDisabled (true)
      props.updateTableData(updatedReq)
      setIsDisabled(true);
      setIsButtonClicked(true)
      setShouldLoading(true)
      setLoaderMessage('Applying your edits')
      props.setOtherViewsEmpty('store')
      // RTinstance.setObject([])
    }
    else {
      if (p_rows.length) {
        data = p_rows
        if (!updatedRows.length) {
          setDisabled (true)
          setIsDisabled(true);
        }
      }
      else {
        let uniqueUpdatedRows = [...new Set(updatedRows)]
        data = storeData?.filter(val => uniqueUpdatedRows.includes(val.unique))
        setUpdatedRows([])
        setDisabled((prev) => {
          if (!prev) {
            return true;
          }
        });
        setIsDisabled((prev) => {
          if (!prev) {
            return true;
          }
        });
      }
      for (let i = 0; i < data.length; i++) {
        if (Number(data[i].min_store || 0) > Number(data[i].max_store || 0)) {
          Notify.error("Make sure min is less than or equal to max !!")
          return;
        }
      }
      let req = {
        // data: RTinstance?.obje,
        data: data,
        "screen_type": "store",
        "country": props?.filters?.country?.map(country => country?.value),
      }
      // setInitialIndex(0)
      // setPrevIndex(0)
      props.updateTableData(req)
      setIsButtonClicked(true)
      setShouldLoading(true)
      setLoaderMessage('Applying your edits')
      props.setOtherViewsEmpty('store')
      // RTinstance.setObject([])
    }
  }

  const retainCheckboxState = () => {
    let initialSelectedRows = {}
    Object.keys(RTinstance.state.selectedRowIds).forEach((item, index) => {
      initialSelectedRows[`${item}`] = true
    })
    setInitialSelectedRows(initialSelectedRows)
  }

  const fetchData = (index) => {
    // if(prevIndex >= index || index < pageIndex)
    if ((prevIndex >= index || index < pageIndex))
      return;
    // if(Object.keys(RTinstance.state.selectedRowIds).length == storeData.length){
    //   setSetAll(true)
    // }
    // else{
    //   setSetAll(false)
    // }
    retainCheckboxState()
    // setIsDataLoading(true)
    setPrevIndex(index)
    setInitialIndex(Math.floor(pageIndex / pageSize))
    props.fetchStoreData({ filters: props.filters, "rowCount": props.rowsCount, "rowIndex": nextIndex, "styleIndex": props.styleIndex, searchTermReq, sortReq })
  }

  const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
    if (p_type === "array") {
      if (split_type) {
        p_term = p_term.replace(/\s*,\s*/g, ",")
      }
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old)
        var id = p_term.split(split_type ? split_type : " ")
        if (p_term?.trim() == 0) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
        }
        else {

          l_searchTermReq[p_id] = {
            "type": p_type,
            "values": id,
            "search": p_term
          }
        }
        return {
          ...l_searchTermReq
        }
      })
    }
    else if (Array.isArray(p_term)) {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old)
        let min = p_term[0] == "" ? null : p_term[0]
        let max = p_term[1] == "" ? null : p_term[1]
        if (min == null && max == null) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
        }
        else {
          l_searchTermReq[p_id] = {
            "type": p_type,
            "value": {
              "min": min,
              "max": max,
            }
          }
        }
        return {
          ...l_searchTermReq,
        }
      })
    }
    else {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old)
        if (p_term?.trim() == 0) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
        }
        else {
          l_searchTermReq[p_id] = {
            "type": p_type,
            "value": p_term
          }
        }
        return {
          ...l_searchTermReq,
        }
      })
    }
    isFirstCall && setisFirstCall(false)
    setUpdatedRows([])
    // setIsDisabled((prev) => {
    //   if (!prev) {
    //     return true;
    //   }
    // });
  }

  const fecthDataWithSorting = (p_sort) => {
    setSortReq(p_sort)
    isFirstCall && setisFirstCall(false)
    setUpdatedRows([])
    if (p_sort?.length) {
      // setIsDisabled((prev) => {
      //   if (!prev) {
      //     return true;
      //   }
      // });
    }
  }


  useEffect(() => {
    if ((!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) && !isEmpty(props.filters)) {
      props.resetStoreData()
      setInitialIndex(Math.floor(pageIndex / pageSize))
      props.fetchStoreData({ filters: props.filters, "rowCount": props.rowsCount, "rowIndex": props.initialindex, "styleIndex": 0, searchTermReq, sortReq });
    }
  }, [searchTermReq, sortReq])

  useEffect(() => {
    setTotalCount(props.storeTotalCount)
    setIndex(props.storeIndex)
    setPageIndex(props.pageIndex)
  }, [props.storeTotalCount, props.storeIndex, props.pageIndex])

  const prepInput = React.useMemo(() => [
    {
      id: 1,
      isRequired: false,
      label: "Avg. Min per store",
      name: "min",
      className: "setAll__input__center_3",
      value: minPerStoreSA,
      onChange: (e) => {
        setMinPerStoreSA(Math.round(e.target.value))
      },
      onBlur: (e) => {
        let l_minPerStoreSA = minPerStoreSA
        if (maxPerStoreSA) {
          if (!Number(maxPerStoreSA))
            l_minPerStoreSA = 0
          else {
            l_minPerStoreSA = Math.min(Number(maxPerStoreSA), l_minPerStoreSA)
            l_minPerStoreSA = l_minPerStoreSA === 0 ? "0" : l_minPerStoreSA
          }
        }
        setMinPerStoreSA(l_minPerStoreSA)
      },
      input: true,
      type: "number",
      min: 0,
      step: 1,
    },
    {
      id: 2,
      isRequired: false,
      label: "Avg. Max.per store",
      name: "max",
      className: "setAll__input__center_3",
      value: maxPerStoreSA,
      onChange: (e) => {
        setMaxPerStoreSA(Math.round(e.target.value))
      },
      onBlur: (e) => {
        let l_maxPerStoreSA = maxPerStoreSA
        if (minPerStoreSA) {
          l_maxPerStoreSA = Math.max(Number(minPerStoreSA), l_maxPerStoreSA)
          l_maxPerStoreSA = l_maxPerStoreSA === 0 ? "0" : l_maxPerStoreSA
        }
        setMaxPerStoreSA(l_maxPerStoreSA)
      },
      input: true,
      type: "number",
      min: 0,
      step: 1
    },
    {
      id: 3,
      isRequired: false,
      label: "WOS",
      name: "wos",
      className: "setAll__input__center_3",
      value: wosSA,
      onChange: (e) => {
        let val = e.target.value
        if (Number(val) < 0) {
          val = 0;
        }
        setwosSA(Math.round(val))
      },
      input: true,
      type: "number",
      min: 0,
    },
  ], [minPerStoreSA, maxPerStoreSA, wosSA])

  const handleSetAllOk = () => {
    if (minPerStoreSA != null && maxPerStoreSA != null && Number(minPerStoreSA) > Number(maxPerStoreSA)) {
      Notify.error("Make sure min is less than or equal to max !!")
      return;
    }
    let setAllRows = Object.keys(RTinstance.state.selectedRowIds)
    setAll && setSetAll(false)
    setInitialIndex(RTinstance.state.pageIndex)
    setInitialSelectedRows({})
    let updatedRows = []
    setStoreData((oldData) => {
      let newData = cloneDeep(oldData);
      setAllRows.forEach(setAllRow => {
        updatedRows.push(newData[setAllRow]['unique'])
        if (minPerStoreSA)
          newData[setAllRow]['min_store'] = minPerStoreSA
        if (maxPerStoreSA) {
          if (showMessage)
            newData[setAllRow]['min_store'] = "0"
          newData[setAllRow]['max_store'] = maxPerStoreSA
        }
        if (wosSA || wosSA == 0)
          newData[setAllRow]['wos'] = wosSA
        if (transitSA)
          newData[setAllRow]['transit_time_sum'] = transitSA
      })
      return newData;
    });
    let newData = cloneDeep(storeData);
    newData = newData.filter((val, indx) => setAllRows.includes(String(indx)))
    newData.forEach(data => {
      if (minPerStoreSA)
        data['min_store'] = minPerStoreSA
      if (maxPerStoreSA) {
        if (showMessage)
          data['min_store'] = "0"
        data['max_store'] = maxPerStoreSA
      }
      if (wosSA)
        data['wos'] = wosSA
      if (transitSA)
        data['transit_time_sum'] = transitSA
    })
    // if (minPerStoreSA || maxPerStoreSA || wosSA) {
      let l_boolean = storeData?.length == setAllRows?.length
      applyConstraints(l_boolean, newData);
    // }
    // setUpdatedRows((old) => {
    //   let updatedRows = []
    //   setAllRows.forEach(setAllRow => {
    //       updatedRows.push(storeData[setAllRow]['unique'])
    //   })
    //   return [...old,...updatedRows]
    // })

    // setIsDisabled((prev) => {
    //   if (prev) {
    //     return false;
    //   }
    // });

  }

  const handleSetAllCancel = () => {
    setShowSetAll(false);
  }

  const handleClose = () => {
    showSetAll && setShowSetAll(false);
  }

  const setAllHandler = (p_instance) => {
    if (Object.keys(p_instance.state.selectedRowIds).length > 0) {
      setShowMessage(false)
      setShowSetAll(true);
      setMinPerStoreSA(null)
      setMaxPerStoreSA(null)
      setwosSA(null)
      setTransitSA(null)
      setDisabled(false)
    }
    else {
      Notify.error(`Please select atleast 1 store to use Set All functionality!!`)
    }
  }

  const updateSetAll = (p_instance) => {
    if (p_instance?.isAllRowsSelected && !setAll) {
      setSetAll(true)
    }
    if (!p_instance.isAllRowsSelected && Object.keys(p_instance.state.selectedRowIds).length == 0 && setAll) {
      setSetAll(false)
    }
  }


  return (
    <>
      <div>
        {/* <Notification /> */}
        {showSetAll && (
          <SetAllModal
            showMessage={showMessage}
            message={message}
            filter={prepInput}
            messageStyle={{ margin: "auto", color: "red" }}
            handleOk={handleSetAllOk}
            handleCancel={handleSetAllCancel}
            handleClose={() => handleClose()}
            disableApply={setAllDisabled}
          />
        )}
        <PageLoader loaderMessage={loaderMessage} loader={props.loading || shouldLoading} gridLoader={true}>
          {(props.storeError) ? (<div className="error">Something Went Wrong!!</div>) :
            <ReactTableWithPlugins
              shouldPagination
              hideDropdown
              totalRecordsLen={storeData?.length}
              data={storeData}
              columns={columns}
              setIsDisabled={setIsDisabled}
              renderMarkup='TableMarkup'
              tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
              headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
              keyRT="constraintsPageTable"
              pageSize={pageSize}
              getInstanceOnMount={(instance) => {
                updateSetAll(instance)
                setRTinstance(instance)
                props.setRTinstancecallbackStore(instance)
              }}
              getManualSortByOptions={(instance) => {
                fecthDataWithSorting(instance)
              }}
              fetchData={(data) => fetchData(data)}
              initialPageIndex={initialIndex}
              initialSelectedRows={initialSelectedRows}
              features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
              manualSortBy={true}
              tableId="constraints_store"
              hideTotalRecords={hideTotalRecords}
              hideTotalPages={hideTotalPages}
            />}
        </PageLoader>
      </div>
      <div div style={{ textAlign: "center", margin: '2rem' }}>
        <button disabled={isDisabled} onClick={() => applyConstraints(false, [])} style={{ margin: '2px 1rem' }} class="btn btn-primary center" title="Apply All filters">Apply All</button>
        <button
          style={{ margin: '2px 1rem' }}
          className="btn btn-primary"
          onClick={() => setAllHandler(RTinstance)}
        >
          Set All
        </button>
      </div>
    </>
  )
}

const mapStateToProps = ({ constraints }) => ({
  filterLabels: constraints.filterlabels,
  isUpdated: constraints.isUpdated,
  storeError: constraints.storeError,
  loading: constraints?.storeloading,
  storeTotalCount: constraints?.storeTotalCount,
  storeIndex: constraints?.storeIndex,
  storeRequest: constraints?.storeRequest,
  styleIndex: constraints?.styleIndex,
  pageIndex: constraints?.pageIndex,
  out_of_data: constraints?.out_of_data
});


const mapDispatchToProps = (dispatch) => ({
  updateTableData: (payload) => dispatch(updateTableData(payload))
});


export default connect(mapStateToProps, mapDispatchToProps)(Store)
