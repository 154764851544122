import { getLabel } from "../../../utils/commonUtilities";
import { updateArticleName } from "../../../utils/filterLevelMapping";

export const DAILY_SUMMARY_TABLE_HEADERS = {
    ARTICLE_ID: `${updateArticleName(false)} ID`,
    STYLE_DESCRIPTION: "Style Description",
    STYLE_COLOR: "Style Color",
    COLOR: "Color",
    ALLOCATION_ID: "Plan Name",
    INVENTORY_SOURCE: "Inv. Source",
    STORE_ID: "Store Number",
    UNITS_ALLOCATED: "Units Allocated",
    DC_UNITS_OH: "Net Available Inv",
    STYLE_ID: "Style ID",
    COLOR_ID: "Color ID",
    RESERVE_QTY: "Reserved Qty"
};

export const CARDS = {
    CARD1: "# of Allocation",
    CARD2: "# of Style colors Allocation",
    CARD3: "# of Units Allocation",
    CARD4: "Net Available Inv",
    CARD5: "Reserve Quantity",
}