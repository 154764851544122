import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Notify from "../../../components/Notification/Notifications";
import Notification from "../../../components/Notification/Notifications";
import MultiSelect from "../../../components/Filters/MultiSelect";
import Select from "../../../components/Filters/SingleSelect";
import { handleSelectAll } from "../../../utils/SelectAllHandler/SelectAllHander";
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping";
import { getUserPreferenceFilter, shouldHideForCurrentClient, setUserPreferenceFilter, isMandatory, getFilterForClients, getDefaultFiltersCondition, getLevelFiltersForUserPreference } from "../../../utils/commonUtilities";
import { intersectionWith, isEqual } from "lodash";
import { LOST_SALES_FILTER_ARTICLE_ID, LOST_SALES_FILTER_COLOR, LOST_SALES_FILTER_DCS, LOST_SALES_FILTER_GENDER, LOST_SALES_FILTER_SEASON_CODE, LOST_SALES_FILTER_SEASON_CODE_OPEN, LOST_SALES_FILTER_STORE_ID, LOST_SALES_FILTER_STYLE, LOST_SALES_FILTER_SUBCATEGORY } from "../LostSalesReport/LostSalesConstants";
import { fetchFilterData, resetFiltersOptions } from "./DeepDiveAction";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import AsyncMulti from "../../../components/Filters/AsyncMulti";

const DeepDiveFilters = React.memo((props) => {
    const [department, setDepartment] = useState(null);
    const [gender, setGender] = useState(null);
    const [subCat, setSubCat] = useState(null);
    const [dcs, setDCS] = useState(null);
    const [level5, setLevel5] = useState(null);
    const [level6, setLevel6] = useState(null);
    const [level7, setLevel7] = useState(null);
    const [style, setStyle] = useState(null);
    const [color, setColor] = useState(null);
    const [article, setArticle] = useState(null);
    const [store, setStore] = useState(null);
    const [season_code, setSeasonCode] = useState(null);
    const [season_code_opened, setSeasonCodeOpen] = useState(null);
    const [min_date, setStartDate] = useState(null);
    const [max_date, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [request,setRequest] = useState(null);
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [genderOptions, setGenderOptions] = useState(null);
    const [subCatOptions, setSubCatOptions] = useState(null);
    const [dcsOptions, setDCSOptions] = useState(null);
    const [level5Options, setLevel5Options] = useState(null);
    const [level6Options, setLevel6Options] = useState(null);
    const [level7Options, setLevel7Options] = useState(null);

    const [styleOptions, setStyleOptions] = useState(null);
    const [colorOptions, setColourOptions] = useState(null);
    const [articleIdOptions, setArticleIdOptions] = useState(null);
    const [storeIdOptions, setStoreIdOptions] = useState(null);
    const [seasonCodeOptions, setSeasonCodeOptions] = useState(null);
    const [seasonCodeOpenOptions, setSeasonCodeOpenOptions] = useState(null);

    useEffect(() => {
      setDepartmentOptions(() => props.departmentOptions);
    }, [props.departmentOptions]);
    

    useEffect(() => {
      setGenderOptions(() => props.genderOptions);
    }, [props.genderOptions]);
    
    useEffect(() => {
     setSubCatOptions(() => props.subCatOptions);
    }, [props.subCatOptions]);
    
    useEffect(() => {
      setDCSOptions(() => props.dcsOptions);
    }, [props.dcsOptions]);

    useEffect(() => {
        setLevel5Options(() => props.level5Options);
    }, [props.level5Options]);

    useEffect(() => {
        setLevel6Options(() => props.level6Options);
    }, [props.level6Options]);

    useEffect(() => {
        setLevel7Options(() => props.level7Options);
    }, [props.level7Options]);
    
    useEffect(() => {
      setStyleOptions(() => props.styleOptions);
    }, [props.styleOptions]);
    
    useEffect(() => {   
      setColourOptions(() => props.colorOptions);
    }, [ props.colorOptions]);
    
    useEffect(() => {
      setArticleIdOptions(() => props.articleIdOptions);
    }, [props.articleIdOptions]);

    useEffect(() => {
      setStoreIdOptions(() => props.storeIdOptions);
    }, [props.storeIdOptions]);

    useEffect(() => {
      setSeasonCodeOptions(() => props.seasonCodeOptions);
    }, [props.seasonCodeOptions]);

    useEffect(() => {
        setSeasonCodeOpenOptions(() => props.seasonCodeOpenOptions)
    }, [props.seasonCodeOpenOptions])
    
    useEffect(() => {
    if(props.activeTab == "deepDive"){
        getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, {onl1Change:onDepartmentChange,onl2Change:onGenderChange})
        // getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, onDepartmentChange)
        props.fetchFilterData({},{key:'l1'});

        // // props.fetchFilterData({},{key:'l7'},"style");
        // props.fetchFilterData({},{key:'l7'},"store");
        // !shouldHideForCurrentClient("season_code_active") && props.fetchFilterData({},{key:'l7'},"season_code_active");
        // props.fetchFilterData({},{key:'l7'},"season_code_opened");

        setStartDate(null);
        setEndDate(null)
    }
        props.resetFiltersOptions([]);
    }, [props.activeTab]);

    const resetRestFilters = (p_index) => {
        style && setStyle(null);
        styleOptions && setStyleOptions(null);
        store && setStore(null);
        storeIdOptions && setStoreIdOptions(null);
        season_code && setSeasonCode(null);
        seasonCodeOptions && setSeasonCodeOptions(null);
        season_code_opened && setSeasonCodeOpen(null);
        seasonCodeOpenOptions && setSeasonCodeOpenOptions(null);

        if(p_index === 0){
            gender && setGender(null);
            genderOptions && setGenderOptions(null);
        }
        if(p_index === 0 || p_index === 1){
            subCat && setSubCat(null);
            dcs && setDCS(null);
            level5 && setLevel5(null);
            color && setColor(null);
            article && setArticle(null);
            subCatOptions && setSubCatOptions(null);
            dcsOptions && setDCSOptions(null);
            level5Options && setLevel5Options(null);
            colorOptions && setColourOptions(null);
            articleIdOptions && setArticleIdOptions(null);
            level6 && setLevel6(null);
            level6Options && setLevel6Options(null);
        }
        if(p_index === 2){
            dcs && setDCS(null);
            dcsOptions && setDCSOptions(null);
        }
        if(p_index === 5){
            level7 && setLevel7(null);
            level7Options && setLevel7Options(null);
            color && setColor(null);
            colorOptions && setColourOptions(null);
            article && setArticle(null);
            articleIdOptions && setArticleIdOptions(null);
        }
        if(p_index === 6){
            color && setColor(null);
            colorOptions && setColourOptions(null);
            article && setArticle(null);
            articleIdOptions && setArticleIdOptions(null);
        }

        if(p_index === 2 || p_index === 3 ){
            level5 && setLevel5(null);
            level5Options && setLevel5Options(null);
            level6 && setLevel6(null);
            level6Options && setLevel6Options(null);
            color && setColor(null);
            article && setArticle(null);
            colorOptions && setColourOptions(null);
            articleIdOptions && setArticleIdOptions(null);
        }
        if(p_index === 4){
            level6 && setLevel6(null);
            level6Options && setLevel6Options(null);
            level7 && setLevel7(null);
            level7Options && setLevel7Options(null);
            color && setColor(null);
            colorOptions && setColourOptions(null);
            article && setArticle(null);
            articleIdOptions && setArticleIdOptions(null);
        }
        if(p_index === 7){
            article && setArticle(null);
            articleIdOptions && setArticleIdOptions(null);
        }
    };

    const onDepartmentChange = (val,p_userPreference) => {
      setRequest(old => {
      return {
        ...old,
        'level2': null,
        'level3': null,
        'level4': null,
        'level5': null,
        'level6':null,
        'level7':null,
        'style': null,
        'color': null,
        'article': null,
        "store": null,
        "season_code_active": null,
        "season_code_opened": null
      }
      })
      setDepartment(val);
      resetRestFilters(0);
      if(p_userPreference?.user_preference) { 
        props.fetchFilterData({ level1: [val] },{key:'l10'});
      }
      else{
      props.fetchFilterData({ level1: [val] },{key:'l2'});
    //   props.fetchFilterData({ level1: [val] },{key:'l7'},"style");
      }
    };

    const onGenderChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], genderOptions, isEqual);
            setGender(valuesArray[1]);
            if(event?.user_preference) { 
                props.fetchFilterData({ level1: [event?.l1_name], level2: val },{key:"l3"});
              }
              else{
            props.fetchFilterData({ level1: [department], level2: req_val },{key:'l3'});
              }
            // props.fetchFilterData({ level1: [department], level2: [...req_val] },{key:'l7'},"style");
            setRequest(old => {
                return {
                    ...old,
                    'level2': valuesArray[0],
                    'level3': null,
                    'level4': null,
                    'level5': null,
                    'level6':null,
                    'level7':null,
                    'style': null,
                    'color': null,
                    'article': null,
                    "store": null,
                    "season_code_active": null,
                    "season_code_opened": null
                }
            })
     
        }
        else{
            setGender(val)
            if(event?.user_preference) { 
                props.fetchFilterData({ level1: [event?.l1_name], level2: [val] },{key:"l3"});
              }
              else{
            props.fetchFilterData({ level1: [department], level2: [val] },{key:'l3'});
            // props.fetchFilterData({ level1: [department], level2: [val] },{key:'l7'},"style");
              }
              setRequest(old => {
                return {
                    ...old,
                    'level2': val,
                    'level3': null,
                    'level4': null,
                    'level5': null,
                    'level6':null,
                    'level7':null,
                    'style': null,
                    'color': null,
                    'article': null,
                    "store": null,
                    "season_code_active": null,
                    "season_code_opened": null
                }
            })
        }
        resetRestFilters(1);
    };

    const onSubCatChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], subCatOptions, isEqual);
            setSubCat(valuesArray[1]);
           
            let req = {
                level1 : [department],
                level3 : [...req_val]
            }
            if(gender) {
                req['level2'] = [gender]
            }
            props.fetchFilterData(req,{key:'l4'});
            // props.fetchFilterData(req,{key:'l7'},"style");
            setRequest(old => {
                return {
                    ...old,
                    'level3': valuesArray[0],
                    'level4': null,
                    'level5': null,
                    'level6':null,
                    'level7':null,
                    'style': null,
                    'color': null,
                    'article': null,
                    "store": null,
                    "season_code_active": null,
                    "season_code_opened": null
                }
            });
        }
        else{
            setSubCat(val)
            if(event?.user_preference) { 
                props.fetchFilterData({ level1: [event?.l1_name], level2: [event?.l2_name], level3:[val] },{key:"l4"});
              }
              else{
                props.fetchFilterData({level1: [department], level2: [gender], level3:[val]},{key:'l4'});
                // props.fetchFilterData({level1: [department], level2: [gender], level3:[val]},{key:'l7'},"style");
              }
              setRequest(old => {
                return {
                    ...old,
                    'level3': val,
                    'level4': null,
                    'level5': null,
                    'level6':null,
                    'level7':null,
                    'style': null,
                    'color': null,
                    'article': null,
                    "store": null,
                    "season_code_active": null,
                    "season_code_opened": null
                }
            });
        }
        resetRestFilters(2);
    };

    const onDCSChange = (val, event, that) => {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], dcsOptions, isEqual);
            setDCS(valuesArray[1]);
            let req = {
                level1 : [department],
                level4 : [...req_val]
            }
                gender && (req['level2'] = [request?.level2])
                subCat && (req['level3'] = request?.level3)
            props.fetchFilterData(req,{key:'l8'});
            // props.fetchFilterData(req,{key:'l7'},"style");
            setRequest(old => {
                return {
                    ...old,
                    'level4': valuesArray[0],
                    'level5': null,
                    'level6':null,
                    'level7':null,
                    'style': null,
                    'color': null,
                    'article': null,
                    "store": null,
                    "season_code_active": null,
                    "season_code_opened": null
                }
            });
        resetRestFilters(3);
    };

    const onlevel5Change = (val, event, that) => {
        let valuesArray = handleSelectAll(val, event, that);
        let req_val = intersectionWith(valuesArray[0], level5Options, isEqual);
        setLevel5(valuesArray[1]);
        let req = {
            level1 : [department],
            level5 : [...req_val]
        }
            gender && (req['level2'] = [request?.level2])
            subCat && (req['level3'] = request?.level3)
            dcs && (req['level4'] = dcs)
        props.fetchFilterData(req,{key:'l9'});
        // props.fetchFilterData(req,{key:'l7'},"style");
        setRequest(old => {
            return {
                ...old,
                'level5': valuesArray[0],
                'level6':null,
                'level7':null,
                'style': null,
                'color': null,
                'article': null,
                "store": null,
                "season_code_active": null,
                "season_code_opened": null
            }
        });
    resetRestFilters(4);
};

    const onlevel6Change = (val, event, that)=>{
        let valuesArray = handleSelectAll(val, event, that);
        let req_val = intersectionWith(valuesArray[0], level6Options, isEqual);
        setLevel6(valuesArray[1]);
        let req = {
            level1 : [department],
            level6 : [...req_val]
        }
        gender && (req['level2'] = [request?.level2])
        subCat && (req['level3'] = request?.level3)
        dcs && (req['level4'] = dcs)
        level5 && (req['level5'] = level5)
        props.fetchFilterData(req,{key:'l12'});
        // props.fetchFilterData(req,{key:'l7'},"style");
        setRequest(old => {
            return {
                ...old,
                'level6': valuesArray[0],
                'level7':null,
                'style': null,
                'color': null,
                'article': null,
                "store": null,
                "season_code_active": null,
                "season_code_opened": null
            }
        });
    resetRestFilters(5);
    }

    const onlevel7Change = (val, event, that)=>{
        let valuesArray = handleSelectAll(val, event, that);
        let req_val = intersectionWith(valuesArray[0], level7Options, isEqual);
        setLevel7(valuesArray[1]);
        let req = {
            level1 : [department],
            level7 : [...req_val]
        }
        gender && (req['level2'] = [request?.level2])
        subCat && (req['level3'] = request?.level3)
        dcs && (req['level4'] = dcs)
        level5 && (req['level5'] = level5)
        level6 && (req['level6']= level6)
        props.fetchFilterData(req,{key:'l13'});
        setRequest(old => {
            return {
                ...old,
                'level7': valuesArray[0],
                'style': null,
                'color': null,
                'article': null,
                "store": null,
                "season_code_active": null,
                "season_code_opened": null
            }
        });
    resetRestFilters(6);
    }

    const onColorChange = (val, event, that) => {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], colorOptions, isEqual);
            setColor(valuesArray[1]);
            let req = {
                level1 : [department],
                color : [...req_val]
            }
            gender && (req['level2'] = [request?.level2])
        subCat && (req['level3'] = request?.level3)
        dcs && (req['level4'] = dcs)
        level5 && (req['level5'] = level5)
            level6 && (req['level6']= level6)
            level6 && (req['level7']= level7)
            props.fetchFilterData(req,{key:'l6'});
            // props.fetchFilterData(req,{key:'l7'},"style");
            setRequest(old => {
                return {
                    ...old,
                    'color': valuesArray[0],
                    'article': null,
                    "store": null,
                    "season_code_active": null,
                    "season_code_opened": null
                }
            });
        resetRestFilters(7);
    };

    const onArticleIdChange = (val, event, that) => {
            // let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(val, articleIdOptions, isEqual);
            setArticle(req_val);
            let req = {
                level1 : [department],
                article : [...req_val]
            }
            gender && (req['level2'] = [request?.level2])
            subCat && (req['level3'] = request?.level3)
            dcs && (req['level4'] = dcs)
            level5 && (req['level5'] = level5)
            level6 && (req['level6']= level6)
            level6 && (req['level7']= level7)
            style && (req['style'] = [...style])
            color && (req['color'] = [...color])

            props.fetchFilterData(req,{key:'l14'});
            setRequest(old => {
                return {
                    ...old,
                    'article': req_val,
                    "store": null,
                    "season_code_active": null,
                    "season_code_opened": null
                }
            })
        resetRestFilters();
    };

    const onStyleChange = (val, event, that) => {
        // let valuesArray = handleSelectAll(val, event, that);
        let req_val = intersectionWith(val, styleOptions, isEqual);
        setStyle(req_val);
        setRequest(old => {
            return {
                ...old,
                'style': req_val,
                "store": null,
                "season_code_active": null,
                "season_code_opened": null
            }
        });
    };

    const onStoreIdChange = (val, event, that) => {
        let valuesArray = handleSelectAll(val, event, that);
        setStore(valuesArray[1]);
        setRequest(old => {
            return {
                ...old,
                'store': valuesArray[0],
            }
        });
    };

    const onSeasonCodeChange = (val, event, that) => {
        let valuesArray = handleSelectAll(val, event, that);
        setSeasonCode(valuesArray[1]);
        setRequest(old => {
            return {
                ...old,
                'season_code_active': valuesArray[0],
            }
        });
    };

    const onSeasonCodeOpenChange = (val, event, that) => {
        let valuesArray = handleSelectAll(val, event, that);
        setSeasonCodeOpen(valuesArray[1]);
        setRequest(old => {
            return {
                ...old,
                'season_code_opened': valuesArray[0],
            }
        });
    };

    const applyFilters = () => {
      if (getDefaultFiltersCondition({l1_name: department, l2_name: gender}) && min_date && max_date) {
          
        // setUserPreferenceFilter({"user_id":localStorage.getItem("user_id"),"department":department})
        setUserPreferenceFilter({"user_id":localStorage.getItem("user_id")},getLevelFiltersForUserPreference({l1_name:department,l2_name:gender}))
        props.handleFilterRequest({
          l1_name: [department],
          l2_name: gender,
          l3_name: request?.level3,
          l4_name: request?.level4,
          l5_name: request?.level5,
          l6_name:request?.level6,
          l7_name:request?.level7,
          style_no: request?.style,
          color: request?.color,
          article: request?.article,
          store: request?.store,
          season_code_active: request?.season_code_active,
          season_code_opened: request?.season_code_opened,
          start_date: min_date,
          end_date: max_date,
        });
      } else {
        Notify.error("Please Select All Mandatory Options!!");
      }
    };

    const resetFilters = () => {
      setRequest(null)
      setDepartment(null);
      setGender(null);
      setSubCat(null);
      setDCS(null);
      setLevel5(null);
      setLevel6(null);
      setLevel7(null);
      setStyle(null);
      setColor(null);
      setArticle(null);
      setStore(null);
      setSeasonCode(null);
      setSeasonCodeOpen(null);
      setStartDate(null);
      setEndDate(null);
      props.resetFiltersOptions([]);
    };

    const setDates = (p_startDate, p_endDate ) => {
        setStartDate(p_startDate); 
        setEndDate(p_endDate); 
    }

    const isOutsideRange = day => day.isAfter(moment());

    const getDDLabel = (label) => label ? label : ""

    return (
        <>
            <section className="row section">
                {/* <Notification /> */}
                <div className="col-md-3  required">
                    <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1}</label>
                    <Select
                        name="department"
                        value={department}
                        onChange={onDepartmentChange}
                        placeHolder={`Select ${props.filterLabels?.level1}`}
                        options={departmentOptions?.length ? departmentOptions : []}
                    />
                </div>
                <div className={`col-md-3 ${isMandatory("l2_name")}`}>
                {getFilterForClients({
                            label:props.filterLabels?.level2, 
                            name: "gender", 
                            dropdownValue: gender,
                            onDropDownChange: onGenderChange, 
                            placeHolder: `Select `, 
                            optionsArray:  (genderOptions?.length) ? genderOptions : [],
                            level: "l2_name",
                            isStandAlone: true
                        }, "reporting")}
                    {/* <label className="fnt-md fnt-bold">{props.filterLabels?.level2}</label>
                    <MultiSelect
                        name="gender"
                        dropdownValue={gender}
                        onDropDownChange={onGenderChange}
                        placeHolder={`Select ${getDDLabel(props.filterLabels?.level2)}`}
                        optionsArray={genderOptions?.length ? genderOptions : []}
                    /> */}
                </div>
                {
                !shouldHideForCurrentClient("l3_name") &&
                <>
                <div className={`col-md-3 ${isMandatory("l3_name")}`}>
                {getFilterForClients({
                                    label:props.filterLabels?.level3, 
                                    name: "subCat", 
                                    dropdownValue: subCat,
                                    onDropDownChange: onSubCatChange, 
                                    placeHolder: props.filterLabels?.level3 || "", 
                                    optionsArray:  (subCatOptions?.length) ? subCatOptions : [],
                                    level: "l3_name",
                                    isStandAlone: true
                                })}
                    {/* <label className="fnt-md fnt-bold">{props.filterLabels?.level3}</label>
                    <MultiSelect
                        name="subCat"
                        dropdownValue={subCat}
                        onDropDownChange={onSubCatChange}
                        placeHolder={`Select ${getDDLabel(props.filterLabels?.level3)}`}
                        optionsArray={subCatOptions?.length ? subCatOptions : []}
                    /> */}
                </div>
                <div className="col-md-3  fill">
                    <label className="fnt-md fnt-bold">{props.filterLabels?.level4}</label>
                    <MultiSelect
                        name="dcs"
                        dropdownValue={dcs}
                        onDropDownChange={onDCSChange}
                        placeHolder={`Select ${getDDLabel(props.filterLabels?.level4)}`}
                        optionsArray={dcsOptions?.length ? dcsOptions : []}
                    />
                </div>
                </>
                }
                {
                    !shouldHideForCurrentClient("l5_name") &&
                    <div className="col-md-3  fill">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level5}</label>
                        <MultiSelect
                            name="level5"
                            dropdownValue={level5}
                            onDropDownChange={onlevel5Change}
                            placeHolder={`Select ${getDDLabel(props.filterLabels?.level5)}`}
                            optionsArray={level5Options?.length ? level5Options : []}
                        />
                    </div>
                }
                 {
                    !shouldHideForCurrentClient("l6_name") &&
                    <div className="col-md-3  fill">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level6}</label>
                        <MultiSelect
                            name="level6"
                            dropdownValue={level6}
                            onDropDownChange={onlevel6Change}
                            placeHolder={`Select ${getDDLabel(props.filterLabels?.level6)}`}
                            optionsArray={level6Options?.length ? level6Options : []}
                        />
                    </div>
                }
                 {
                    !shouldHideForCurrentClient("l7_name") &&
                    <div className="col-md-3  fill">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level7}</label>
                        <MultiSelect
                            name="level7"
                            dropdownValue={level7}
                            onDropDownChange={onlevel7Change}
                            placeHolder={`Select ${getDDLabel(props.filterLabels?.level7)}`}
                            optionsArray={level7Options?.length ? level7Options : []}
                        />
                    </div>
                }
                <div className="col-md-3  fill">
                    <label className="fnt-md fnt-bold">{LOST_SALES_FILTER_COLOR}</label>
                    <MultiSelect
                        name="color"
                        dropdownValue={color}
                        onDropDownChange={onColorChange}
                        placeHolder={'Select Color'}
                        optionsArray={colorOptions?.length ? colorOptions : []}
                    />
                </div>
                <div className="col-md-3  fill">
                    <label className="fnt-md fnt-bold">{LOST_SALES_FILTER_ARTICLE_ID}</label>
                    <AsyncMulti
                        name="article"
                        dropdownValue={article}
                        onDropDownChange={onArticleIdChange}
                        placeHolder={"Select Article"}
                        optionsArray={articleIdOptions?.length ? articleIdOptions : []}
                    />
                </div>
                <div className="col-md-3  fill">
                    <label className="fnt-md fnt-bold">{LOST_SALES_FILTER_STYLE}</label>
                    <AsyncMulti
                        name="style"
                        dropdownValue={style}
                        onDropDownChange={onStyleChange}
                        placeHolder={'Select Style'}
                        optionsArray={styleOptions?.length ? styleOptions : []}
                    />
                </div>
                <div className="col-md-3  fill">
                    <label className="fnt-md fnt-bold">{LOST_SALES_FILTER_STORE_ID}</label>
                    <MultiSelect
                        name="store"
                        dropdownValue={store}
                        onDropDownChange={onStoreIdChange}
                        placeHolder={"Select Store"}
                        optionsArray={storeIdOptions?.length ? storeIdOptions : []}
                    />
                </div>
                {
                !shouldHideForCurrentClient("season_code_active") &&
                    <div className="col-md-3  fill">
                        <label className="fnt-md fnt-bold">{LOST_SALES_FILTER_SEASON_CODE}</label>
                        <MultiSelect
                            name="seasonCodeOpen"
                            dropdownValue={season_code}
                            onDropDownChange={onSeasonCodeChange}
                            placeHolder={"Select Season Code Active"}
                            optionsArray={seasonCodeOptions?.length ? seasonCodeOptions : []}
                        />
                    </div>
                }
                <div className="col-md-3  fill">
                    <label className="fnt-md fnt-bold">{LOST_SALES_FILTER_SEASON_CODE_OPEN}</label>
                    <MultiSelect
                        name="seasonCodeActive"
                        dropdownValue={season_code_opened}
                        onDropDownChange={onSeasonCodeOpenChange}
                        placeHolder={`Select ${LOST_SALES_FILTER_SEASON_CODE_OPEN}`}
                        optionsArray={seasonCodeOpenOptions?.length ? seasonCodeOpenOptions : []}
                    />
                </div>
                <div className="col-md-3  required">
                    <label className="fnt-md fnt-bold fnt-bold ">Sales Period</label>
                    <DateRangePicker
                        startDate={min_date}
                        startDateId="s_id"
                        endDate={max_date}
                        endDateId="e_id"
                        onDatesChange={({ startDate, endDate }) => {
                        setDates(startDate, endDate);
                        }}
                        focusedInput={focusedInput}
                        onFocusChange={(e) => setFocusedInput(e)}
                        displayFormat="MM-DD-YYYY"
                        isOutsideRange={isOutsideRange}
                        showClearDates
                        withPortal
                        numberOfMonths={3}
                    />
                </div>
                <div className="col-md-3 ">
                    <button
                        onClick={applyFilters}
                        className="btn btn-primary filter-constraints-mode"
                        title="Apply filters"
                    >
                        <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
                    </button>
                    <button
                        onClick={() => resetFilters()}
                        className="btn undo-constraints-mode"
                        title="Reset filters"
                    >
                        <i className="fa fa-undo mr-2"></i>Reset
                    </button>
                </div>
                <div className="col-md-3  required fnt-md pt-5" style={{ color: "red" }}>
                    <label></label>Fields are mandatory
                </div>
            </section>
        </>
    );
  }
);

const mapStateToProps = ({ deepDive }) => ({
  filterLabels: deepDive.filterlabels,
  departmentOptions: deepDive.departmentOptions,
  genderOptions: deepDive.genderOptions,
  subCatOptions: deepDive.subCatOptions,
  dcsOptions: deepDive.dcsOptions,
  level5Options: deepDive.level5Options,
  level6Options: deepDive.level6Options,
  level7Options: deepDive.level7Options,
  styleOptions: deepDive.styleOptions,
  colorOptions: deepDive.colorOptions,
  articleIdOptions: deepDive.articleIdOptions,
  storeIdOptions : deepDive.storeIdOptions,
  seasonCodeOpenOptions: deepDive.seasonCodeOpenOptions,
  seasonCodeOptions: deepDive.seasonCodeOptions,
});

const mapDispatchToProps = (dispatch) => ({
  resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  fetchFilterData: (payload,filterKey,filterType) =>
    dispatch(fetchFilterData(payload,filterKey,filterType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeepDiveFilters);
