import React, { useState, useEffect } from "react";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import "../../ProductStoreStatus.css"
import PageLoader from '../../../../components/Loader/PageLoader';
import { getStoreGroupMappingData, getProductProfiles, createConfig, resetAll, getStoreSizeContribution, resetCreateConfigData } from "../Actions/CreateConfigAction"
import StoreGroupMappingTable from "./StoreGroupMappingTable"
import ProductProfileMapping from "../Configuration/ProductProfileMapping";
import * as Notify from '../../../../components/Notification/Notifications'
import Notification from '../../../../components/Notification/Notifications'

const AddStoreGroupModal = (props) => {

    const [dataLoading, setDataLoading] = useState(false)
    const [ppTableData, setPPTableData] = useState([])
    const [RTInstance, setRTinstance] = useState({})
    const [tableData, setTableData] = useState([])
    const [initialSelectedRows, setInitialSelectedRows] = useState({})
    const [ppRTInstance, setPPRTInstance] = useState({})
    const [allPP, setAllPP] = useState([])
    const [text, setText] = useState(props.isProductProfileCall ? "Product Profile" : "Store Group")
    // const [isEdited, setIsEdited] = useState(false)

    const closeModal = (isEdited) => {
        props.closeModal(false, isEdited)
    }

    const resetTableData = () => {
        setTableData([])
    }

    useEffect(() => {
        setDataLoading(true)
        if (props.isProductProfileCall) {
            let req = {}
            if (props.selectedArticle?.article) {
                req.article = props.selectedArticle?.article
            }
            if (props.configuration_id) {
                req.configuration_id = props.configuration_id
            }
            // setRequest(req)
            props.getProductProfiles({article: req.article, configuration_id: req.configuration_id, ...props.filters})
        }
        // else {
        //     props.getStoreGroupMappingData({})
        // }
    }, [])

    useEffect(() => {
        // let data = props.data ? props.data : (
        //     (Object.keys(props.productProfilesData).length) ? props.productProfilesData?.product_profiles
        //         : []
        // )
        // setTable1Data(data)
        // if (props.productProfilesData?.product_profiles) {
        //     let initialSelectedRows = {}
        //     data.forEach((item, index) => {
        //         initialSelectedRows[`${index}`] = true
        //     })
        //     setInitialSelectedRows(initialSelectedRows)
        // }

        if ((Object.keys(props.productProfilesData).length) && props.productProfilesData?.selected_profiles) {
            let data = []
            let remaining = []
            let selected = props.productProfilesData?.selected_profiles?.length ? props.productProfilesData?.selected_profiles : []

            props.productProfilesData.product_profiles.map(pro => {
                if (selected.includes(pro.product_profile_code)) {
                    let getSalesAttribute = val => {
                        let l_salesattribute = ""
                        if(Number(val?.clearance)){
                          l_salesattribute+='Clearance, '
                        }
                        if(Number(val?.regular)){
                          l_salesattribute+='Regular, '
                        }
                        if(Number(val?.promo)){
                          l_salesattribute+='Promo'
                        }
                        return l_salesattribute
                      }

                     data.push({
                         ...pro,
                         'sales_attribute':getSalesAttribute(pro)
                     })
                    //   let responseWithSalesAttribute
                    //      responseWithSalesAttribute = res.data.data.map(val => {
                    //       return {
                    //         ...val,
                    //         'sales_attribute': getSalesAttribute(val),
                    //       }
                    //     })
                
                }
                else {
                    let getSalesAttribute = val => {
                        let l_salesattribute = ""
                        if(Number(val?.clearance)){
                          l_salesattribute+='Clearance, '
                        }
                        if(Number(val?.regular)){
                          l_salesattribute+='Regular, '
                        }
                        if(Number(val?.promo)){
                          l_salesattribute+='Promo'
                        }
                        return l_salesattribute
                      }
                    remaining.push({
                        ...pro,
                        'sales_attribute':getSalesAttribute(pro)
                    })
                }
            });
            console.log('sss',data)
            let initialSelectedRows = {}
            for (let i = 0; i < data.length; i++) {
                initialSelectedRows[i] = true
            }
            setInitialSelectedRows(initialSelectedRows)
            remaining = data.concat(remaining)
            setPPTableData(data)
            setAllPP(remaining)
        }

    }, [props.productProfilesData])


    useEffect(() => {
        setDataLoading(props.dataLoading)
    }, [props.dataLoading])

    useEffect(() => {
        setDataLoading(props.productProfilesDataLoading)
    }, [props.productProfilesDataLoading])

    // const getStoreSizeConfigData = (value) => {
    //     props.get
    // }

    const getTableComponent = () => {
        if (props.isProductProfileCall) {
            return <ProductProfileMapping {...props} data={allPP} setRTinstance={setPPRTInstance}
                isAddActionColumn={true} initialSelectedRows={initialSelectedRows}
                getStoreSizeContribution={props.getStoreSizeContribution} />
        }
        else {
            return <StoreGroupMappingTable data={props.data} setRTinstance={setRTinstance}
                isAddActionColumn={true} initialSelectedRows={props.initialSelectedRows} />
        }
    }

    const addStoreGroups = () => {
        let tableInstance = props.isProductProfileCall ? ppRTInstance : RTInstance
        if (props.isProductProfileCall && tableInstance?.selectedFlatRows?.length > 1) {
            Notify.error("Please Select Exactly One Product Profile!!")
            return;
        }
        if (tableInstance?.selectedFlatRows?.length) {

            setDataLoading(true)
            let req = {
                ...props.request,
                filters: {...props.filters},
                // article:[props.request.article]
            }
            
            if (props.isProductProfileCall) {
                req.pp = tableInstance.selectedFlatRows
            }
            else {
                req.storeGroups = tableInstance.selectedFlatRows
            }
            props.createConfig(req)
        }
        else {
            Notify.error("Select Atleaset one row!!")
        }
    }

    useEffect(() => {
        // setDataLoading(false)
        if(props.isProductProfileCall && props.productCreateConfigDataSuccess)
        {
            props.resetCreateConfigData()
            Notify.success(`${text}s Added Successfully!!`)
            setTimeout(() => {
                closeModal(true)
            }, 2000)

        }
       else if (props.createConfigData?.configuration_id || props.createConfigDataSuccess && !props.isProductProfileCall) {
           props.resetCreateConfigData()
            // if (props.isProductProfileCall) {
            Notify.success(`${text}s Added Successfully!!`)
            setTimeout(() => {
                closeModal(true)
            }, 2000)
            // }
            // else {
            //     closeModal(true)
            //     Notify.success(`${text}s Added Successfully!!`)
            // }
        }
        else if (props.createConfigDataError) {
            Notify.error("Something Went Wrong!!")
        }

    }, [props.createConfigData, props.createConfigDataError,props.createConfigDataSuccess,props.productCreateConfigDataSuccess])

    useEffect(() => {
        // return () => {
        //     props.resetAll()
        // }
    }, [])


    // return (
    //     <div className="modal__div">
    //         <Modal
    //             visible={props.showModal}
    //             width="85%"
    //             // height="95%"
    //             effect="fadeInDown"
    //             onClickAway={closeModal}
    //         >

    //             <div className="modal-container">
    //                 <div className="modal-header">
    //                     <h5 className="page-heading text-center m-0">
    //                         Add Store Group
    //                     </h5>
    //                     <button type="button" className="close" data-dismiss="modal">
    //                         <span style={{ cursor: "pointer" }}>
    //                             <i
    //                                 className="fa fa-times text-right m-2 "
    //                                 aria-hidden="true"
    //                                 onClick={closeModal}
    //                             />
    //                         </span>
    //                     </button>
    //                 </div>
    //                 <div className="modal-middle-container modal-body">
    //                     {/* <div className="mx-9 p-2">
    //                         <div style={{ display: "flex", flexWrap: "wrap" }}>
    //                             <ClusterFiltersInModal
    //                                 storeFilterData={filterData}
    //                                 disableAll={false}
    //                                 getStores={props.getStoreGroupMappingData}
    //                                 resetTableData={resetTableData}
    //                                 filterValue={{ l1_name: [{ label: "Accessories", value: "Accessories" }] }}
    //                             // filterValues={}
    //                             />
    //                         </div>
    //                     </div> */}
    //                     <PageLoader loader={props.dataLoading} gridLoader={true}>

    //                         <div className="mx-2 p-2">
    //                             <ReactTableWithPlugins
    //                                 shouldPagination
    //                                 totalRecordsLen={props.data ? props.data.length : 0}
    //                                 data={tableData}
    //                                 columns={tableColumns}
    //                                 features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
    //                                 renderMarkup='TableMarkup'
    //                                 keyRT="finalize"
    //                                 containSubRow={true}
    //                                 tableWrapperStyle={{ height: 'fit-content', maxHeight: '30rem' }}
    //                                 headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: 4 }}
    //                                 getInstanceOnMount={(instance) => {
    //                                     setRTinstance(instance)
    //                                 }}
    //                                 initialSelectedRows={initialSelectedRows}
    //                             />
    //                         </div>
    //                     </PageLoader>
    //                 </div>

    //                 <div className="text-center" style={{ padding: "1rem" }}>
    //                     <div className="center">
    //                         <button
    //                             className="btn btn-primary store-grp-modal__btn"
    //                             onClick={closeModal}
    //                         >
    //                             Close
    //                         </button>
    //                         <button
    //                             className="btn btn-primary store-grp-modal__btn fn__btn"
    //                         >
    //                             Save
    //                         </button>

    //                     </div>
    //                 </div>
    //             </div>
    //         </Modal>
    //     </div>
    // )

    return (
        <div className="modal__div">
            <Modal
                visible={props.showModal}
                width="85%"
                // height="95%"
                effect="fadeInDown"
                onClickAway={closeModal}
            >
                {/* <Notification /> */}
                <div className="modal-container">
                    <div className="modal-header">
                        <h1 className="fnt-lg fnt-bold">
                            {props.isProductProfileCall ? "Edit Product Profile Mapping" :
                                "Edit Store Group Mapping"}
                        </h1>
                        <button type="button" className="close" data-dismiss="modal">
                            <span style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-times text-right m-2 "
                                    aria-hidden="true"
                                    onClick={props.closeModal}
                                />
                            </span>
                        </button>
                    </div>
                    <div className="modal-middle-container modal-body">

                        <div className="product__store__status__container">

                            {/* <section className="section"> */}
                            <div className="container__header m-0">
                                <h1 className="fnt-md ml-2 fnt-bold">User Created</h1>
                            </div>
                            <hr />
                            <PageLoader loader={dataLoading} gridLoader={true}>
                                {getTableComponent()}
                            </PageLoader>
                            {/* </section> */}

                            {/* <section className="section"> */}
                            {/* <div className="container__header m-0">
                                <h1 className="fnt-md ml-2 fnt-bold">IA Recommended</h1>
                            </div>
                            <hr />
                            <PageLoader loader={dataLoading} gridLoader={true}>
                                {getTableComponent()}
                            </PageLoader> */}
                            {/* </section> */}
                        </div>
                    </div>
                    <div className="text-center" style={{ padding: "1rem" }}>
                        <div className="center">
                            <button
                                className="btn btn-primary store-grp-modal__btn"
                                onClick={() => { closeModal(false) }}
                            >
                                Close
                            </button>
                            <button
                                className="btn btn-primary store-grp-modal__btn fn__btn"
                                onClick={addStoreGroups}
                            >
                               Save
                            </button>

                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

const mapStateToProps = ({ createConfig ,productStatus}) => {
    return {
        // data: createConfig.storeGroupData,
        // dataLoading: createConfig.storeGroupDataLoading,
        // dataError: createConfig.storeGroupDataError,
        productProfilesData: createConfig.productProfilesData,
        productProfilesDataLoading: createConfig.productProfilesDataLoading,
        productProfilesDataError: createConfig.productProfilesDataError,
        createConfigData: createConfig.createConfigData,
        createConfigDataSuccess: createConfig.createConfigDataSuccess,
        productCreateConfigDataSuccess: productStatus.createConfigDataSuccess,
        createConfigDataLoading: createConfig.createConfigDataLoading,
        createConfigDataError: createConfig.createConfigDataError,
        storeSizeContributionData: createConfig.storeSizeContributionData,
        storeSizeContributionDataLoading: createConfig.storeSizeContributionDataLoading,
        storeSizeContributionDataError: createConfig.storeSizeContributionDataError,
    }
}

const mapDispatchToProps = (dispatch) => ({
    getStoreGroupMappingData: (payload) => dispatch(getStoreGroupMappingData(payload)),
    getProductProfiles: (payload) => dispatch(getProductProfiles(payload)),
    createConfig: (payload) => dispatch(createConfig(payload)),
    resetAll: () => dispatch(resetAll()),
    getStoreSizeContribution: (payload) => dispatch(getStoreSizeContribution(payload)),
    resetCreateConfigData: () => dispatch(resetCreateConfigData()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddStoreGroupModal)