import { takeLatest, put, all, call } from "redux-saga/effects";
import * as Api from "../../routes/api";
import moment from "moment";
import {
  fetchFilterDataError,
  fetchFilterDataSuccesss,
  fetchStoreStatusDataError,
  fetchStoreStatusDataSuccesss,
  FETCH_FILTER_DATA,
  FETCH_STORE_STATUS_DATA,
  updateStoreStatusDataError,
  updateStoreStatusDataSuccesss,
  UPDATE_STORE_STATUS_DATA,
  GET_STORE_CONFIG_DC_DATA,
  GET_STORE_CONFIG_DC_DATA_SUCCESS,
  GET_STORE_CONFIG_DC_DATA_ERROR
} from "./ProductStoreAction";
import {
  createStoreGroupFiltersActionError,
  createStoreGroupFiltersActionSuccess,
} from "../StoreGroup/StoreGroupAction";

const options = [
  {
    label: "Open",
    value: "open",
  },
  {
    label: "Closed",
    value: "closed",
  },
  {
    label: "Under Renovation",
    value: "under_renovation",
  },
];

// Worker Saga

function* fetchFilterWorkerSaga(action) {
  try {
    const response = yield call(Api.getStoreLevelFilterApi, action.payload);
    if (response.status) {
      let reponse_data = response?.data?.data[0];
      Object.keys(reponse_data).forEach(
        (key) =>
          (reponse_data[key] = reponse_data[key].filter((val) => val != null))
      );
      if (action?.payload?.country) {
        delete response?.data?.data[0]?.country;
      }
      yield put(
        createStoreGroupFiltersActionSuccess({ data: response.data?.data })
      );
    } else {
      yield put(
        createStoreGroupFiltersActionError({ error: response.message })
      );
    }
  } catch (error) {
    yield put(createStoreGroupFiltersActionError(error));
  }
}

function* fetchStoreStatusWorkerSaga(action) {
    try {
        const response = yield call(Api.getStoreStatus,action.payload)
        console.log(action.payload)
        if (response.status) {
            let responseWithOptions = response?.data?.data?.map(val => {
                return{
                    ...val,
                    'store_titles': (val.store_titles?.join(' , ')),
                    'options': options,
                    'store_status': val?.under_renovation ? "under_renovation" : val.store_status,
                    'disable_status': val?.store_status === 'closed' ? true : false,
                    'primary_dc': val.primary_dc?.split(","),
                    'secondary_dc': val.secondary_dc?.split(",") 
                }
            })
            yield put(fetchStoreStatusDataSuccesss({'data':responseWithOptions}))
        }
        else{
          yield put(fetchStoreStatusDataError({'error':response.message}));
        }
    
      } catch (error) {
          yield put(fetchStoreStatusDataError(error));
      }
}

function* updateStoreStatusWorkerSaga(action) {
  let req = action?.payload;
  let req_body = req.map((val) => {
    return {
      store_code: val.store_code,
      store_status: val.store_status,
      inactive_start:
        val.store_status === "under_renovation"
          ? moment(val.inactive_start).format("MM-DD-YYYY")
          : val.inactive_start,
      inactive_end:
        val.store_status === "under_renovation"
          ? moment(val.inactive_end).format("MM-DD-YYYY")
          : val.inactive_end,
      primary_dc: val.primary_dc?.map((dc) => dc.value),
      secondary_dc: val.secondary_dc?.map((dc) => dc.value)
    };
  });
  try {
    const response = yield call(Api.updateStoreStatus, {
      changes: req_body,
      updated_by: localStorage?.getItem("email"),
    });
    if (response.status) {
      yield put(updateStoreStatusDataSuccesss({ data: response.data }));
    } else {
      yield put(updateStoreStatusDataError({ error: response.message }));
    }
  } catch (error) {
    yield put(updateStoreStatusDataError(error));
  }
}

function* fetchStoreConfigDCData(action) {
  try {
    const response = yield call(Api.getStoreConfigDcList, action.payload);
    if (response?.data?.status) {
      yield put({ type: GET_STORE_CONFIG_DC_DATA_SUCCESS, data: response?.data?.data});
    } else {
      yield put({ type: GET_STORE_CONFIG_DC_DATA_ERROR, error: response.message || "Something went wrong!!" });
    }
  } catch (error) {
    yield put({ type: GET_STORE_CONFIG_DC_DATA_ERROR, error  });
  }
}

// Watcher Saga

function* fetchFilterWatcherSaga() {
  yield takeLatest(FETCH_FILTER_DATA, fetchFilterWorkerSaga);
}

function* fetchStoreStatusWatcherSaga() {
  yield takeLatest(FETCH_STORE_STATUS_DATA, fetchStoreStatusWorkerSaga);
}

function* updateStoreStatusWatcherSaga() {
  yield takeLatest(UPDATE_STORE_STATUS_DATA, updateStoreStatusWorkerSaga);
}

function* getStoreConfigDCListWatcher() {
  yield takeLatest(GET_STORE_CONFIG_DC_DATA, fetchStoreConfigDCData);
}

export function* productStoreStatusSaga() {
  yield all([
    fetchFilterWatcherSaga(),
    fetchStoreStatusWatcherSaga(),
    updateStoreStatusWatcherSaga(),
    getStoreConfigDCListWatcher()
  ]);
}
