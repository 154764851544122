import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import PageLoader from "../../../components/Loader/PageLoader";
import ContractPOAllocationTable from "./ContractPOAllocationTable";
import { getStrategyTableDataFromDashboard } from "../../Strategy/actions";
import { getContractPoData,getAutoApprovalreleaseContractPoData } from "./ContractPOAllocationAction/ContractPOAllocationAction";
import { POS_VALUES } from "../RecommendedAction/constantRecommendedAction";

const ContractPOAllocation = (props) => {
  const {
    contractPOAllocationDataLoading,
    contractPOAllocationData,
    contractPOAllocationDataError,
    getContractPoData,
    getAutoApprovalreleaseContractPoData,
    filters,
    getCreateAllocationData,
    type,
    status,
    storeFilters,
    rowData,
  } = props;

  const poRef = useRef();
  const history = useHistory();

  const [selectedFilters, setFilters] = useState({});
  const [tableInstance, setTableInstance] = useState({});

  useEffect(() => {
    const poRequest = {
      articles: rowData.articles,
      po_ids: rowData.po_ids,
      allocation_codes:
        rowData.pos === POS_VALUES.AUTO_APPROVE_RELEASE_CONTRACT_PO
          ? rowData.allocation_codes
          : null,
    };
    setFilters(filters);
    if(rowData.pos === POS_VALUES.AUTO_APPROVE_RELEASE_CONTRACT_PO)
    {
      setTimeout(() => {
        poRef?.current?.scrollIntoView();
        getAutoApprovalreleaseContractPoData(poRequest);
      }, 200);
    }
    else{
    setTimeout(() => {
      poRef?.current?.scrollIntoView();
      getContractPoData(poRequest);
    }, 200);
  }
  }, []);

  return (
    <section className="section mr-4 mt-4" ref={poRef}>
      <PageLoader loader={contractPOAllocationDataLoading} gridLoader={true}>
        {contractPOAllocationDataError ? (
          <div className="error">{contractPOAllocationDataError}</div>
        ) : contractPOAllocationData ? (
          <ContractPOAllocationTable
            data={contractPOAllocationData}
            setTableInstance={setTableInstance}
            type={type}
            status={status}
            rowData={rowData}
            selectedFilters={selectedFilters}
            getCreateAllocationData={getCreateAllocationData}
          />
        ) : null}
      </PageLoader>
    </section>
  );
};

const mapStateToProps = ({ contractPOAllocation }) => {
  return {
    contractPOAllocationDataLoading:
      contractPOAllocation.contractPOAllocationDataLoading,
    contractPOAllocationData: contractPOAllocation.contractPOAllocationData,
    contractPOAllocationDataError: contractPOAllocation.contractPOAllocationDataError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getContractPoData: (payload) => dispatch(getContractPoData(payload)),
  getAutoApprovalreleaseContractPoData: (payload) => dispatch(getAutoApprovalreleaseContractPoData(payload)),
  getCreateAllocationData: (payload) =>
    dispatch(getStrategyTableDataFromDashboard(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractPOAllocation);
