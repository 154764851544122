import React, { useState, useEffect, createRef } from "react";
import Modal from "react-awesome-modal";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable"
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import InputCell from "../../../components/Table/Cellrenderer/InputCell";
import { isNull } from "lodash-es";
import { connect } from "react-redux";
import PageLoader from "../../../components/Loader/PageLoader";
import { updateArticleName } from "../../../utils/filterLevelMapping";
import { editAllocatedUnits, resetEditAllocationError, setDcAvailableError } from "../FinalizeAction";
import { compareLowerCase } from "../../../utils/commonUtilities";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const EditPackDetails = (props) => {
    const [RTInstance, setRTInstance] = useState(null);
    const [data, setData] = useState([])
    const [maxSizeMapping, setMaxSizeMapping] = useState({})
    const [columns, setColumns] = useState([])
    const [request, setRequest] = useState({})
    const [dcPackMap, setDCPackMapping] = useState({})


const columnTemplate = React.useMemo(() => [
        {
          Header: "# Packs Allocated",
          id: 'allocated_packs',
          accessor: (data) => !isNull(data?.["allocated_packs"]) ? data?.["allocated_packs"]: '-',
          Cell: (instance) => (
           instance.row?.original?.['pack_id'] !== 'eaches' && instance.row?.original?.['pack_id'] !== 'eaches available' ? (
               <InputCell {...instance} min={0} type="number"
               max={ instance.row.original['max_pack']}
               changeHandler={(rowIdx, columnId, values) => {
                   let l_allocated_units = Number(instance.row.original['units_in_pack']) * Number(values)
                   let l_available_units = Number(instance.row.original['max_pack']) - Number(values)
                   let l_available_qty = Number(instance.row.original['max_pack_available']) - l_allocated_units
                   let dc = instance?.row?.original?.dc || ""

                   instance.updateMyData(rowIdx, 'allocated_units', l_allocated_units);
                   instance.updateMyData(rowIdx, 'packs_available', l_available_units);
                   instance.updateMyData(rowIdx, 'units_available', l_available_qty);

                   setRequest((old) => {
                       return {
                           ...old,
                           "updated_packs" : {
                               ...old.updated_packs,
                               [dc]: {...old?.updated_packs?.[dc], [instance.row.original['pack_id']] : Number(values)}, 
                           },
                           "available_packs" : {
                               ...old.available_packs,
                               [dc] : {...old?.available_packs?.[dc], [instance.row.original['pack_id']] : l_available_units}, 
                           } 
                       }
                   })
               }} />
           ) : (
               <div>
                   {instance.row?.original?.['allocated_packs']}
               </div>
           )
          ),
          disableSortBy: true,
        },
        {
          Header: "# Packs available",
          id: "packs_available",
          accessor: (data) => !isNull(data?.["packs_available"]) ? data?.["packs_available"]: '-',
          disableSortBy: true,
        },
        {
          Header: "Allocated total quantity",
          accessor: (data) => !isNull(data?.["allocated_units"]) ? data?.["allocated_units"]: '-',
          disableSortBy: true,
        },
        {
          Header: "Available total quantity",
          accessor: (data) => !isNull(data?.["units_available"]) ? data?.["units_available"]: '-',
          disableSortBy: true,
        },
   ], [])

   useEffect(() => {
     let initialReq = {};
     if (props.packData?.length) {
       let isPacks = false;
       props.packData.forEach((item) => {
         if (item.col_name !== "eaches" && item.col_name !== "eaches available") isPacks = true;
       });
       if (!isPacks) {
         let DC;
         props.packData.forEach((row) => {
           if (row.pack_id == "eaches") {
             DC = row.dc;
             props.packColumns.forEach((size) => {
               initialReq["updated_eaches"] = {
                 ...initialReq["updated_eaches"],
                 [DC]: {
                   ...initialReq["updated_eaches"]?.[DC],
                   [size]: row[size],
                 },
               };
             });
           } else {
             props.packColumns.forEach((size) => {
               initialReq["available_eaches"] = {
                 ...initialReq["available_eaches"],
                 [DC]: {
                   ...initialReq["available_eaches"]?.[DC],
                   [size]: row[size],
                 },
               };
             });
           }
         });
         setRequest(initialReq);
       }
     }
   }, [props.packData]);

    const onSave = () => {
       let {allocationcode, store_code, article} = props.data
       let {originalAllocationCode} = props
       let l_original_allocation_code = allocationcode === originalAllocationCode ? "" : originalAllocationCode
       let req_body = {
            "allocation_code": allocationcode,
            "original_allocation_code": l_original_allocation_code,
            "store": store_code,
            "article": article,
           ...request
        }
        if(props?.articles?.length) {
            req_body["articles"] = props?.articles
        }
        props.editAllocatedUnits(req_body)

    }

    const closeAlert = () => {
        props.setDcAvailableError(false)
        props.closeAlert()
    }

    useEffect(() => {
        if (!props.editAllocatedUnitError || !props.dcAvailableError) {
            props.resetEditAllocationError()
        }
    }, [props.editAllocatedUnitError, props.dcAvailableError])

    useEffect(() => {
        if (props.editAllocatedUnitSuccess) {
            props.setDcAvailableError(false)
            props.closeAlert(true);
            Notify.success("Allocated Units Edited Sucessfully!!")
        }
    }, [props.editAllocatedUnitSuccess])

    useEffect(() => {
        if (props.editAllocatedUnitError) {
            props.setDcAvailableError(false)
            Notify.error("Error in Editing Allocated Units")
        }
    }, [props.editAllocatedUnitError])

    useEffect(() => {
        let dcObj = {}

        if(props.packData.length){
            // let { is_pack, is_eaches } = props.details
            var isEaches = false
            var isPacks = false
            props.packData.forEach(item => {
                if(item.col_name == "eaches") isEaches = true
                else if(item.col_name !== "eaches available") isPacks = true
            })
            if(isEaches, isPacks) {
                let dc;
                props.packData.forEach(item => {
                    if(item.col_name == "eaches") dc = item.dc
                    if(item.col_name == "eaches available") {
                        dcObj[dc].push(item)
                        dc = ""
                    }

                    else if(dcObj[item.dc]) {
                        dcObj[item.dc].push(item)
                    }
                    else {
                        dcObj[item.dc] = [item]
                    }
                })
                setDCPackMapping(dcObj)
                setData(Object.values(dcObj).flat())
            }
            else {
                setData(props.packData)
            }
            setMaxSizeMapping(props.max_size_mapping)
        }

        if(props.packColumns.length) {
            // let {is_pack, is_eaches} = props.details
            let columns = [{
                Header: "DC",
                // id: "packs_available",
                accessor: "dc",
                disableSortBy: true,
                // Cell: (instance) => (instance?.value ? <td className="row-span2__column">{instance.value}</td> : <td className="row-span2__column border-bottom__td"></td>),
                Cell: (instance) => isEaches ? (instance?.value && (instance?.row?.original?.col_name == "eaches" || dcObj?.[instance?.row?.original?.dc]?.length == 1) ? <td className="row-span2__column" style={{marginTop: "-10px"}}>{instance.value}</td> : instance?.row?.original?.col_name == "eaches available" && <td className="row-span2__column border-bottom__td"></td>) : <td>{instance?.value}</td>,
                rowSpan: isPacks && isEaches ? 3 : (isEaches ? 2 : ""),
                width: 200
            },{
                Header: ' ',
                accessor: 'pack_id',
                sortType: (rowA, rowB, columnId) => compareLowerCase(rowA, rowB, columnId)
            }]
            props.packColumns.forEach(val => {
                columns.push(
                    {
                        Header: val,
                        accessor: (data) => data[val],
                        Cell: (instance) => (
                             instance.row?.original?.['pack_id'] === 'eaches' ? (
                                 <InputCell {...instance} min={0} type="number"
                                 max={ props.max_size_mapping?.[instance?.row?.original?.dc]?.[val]}
                                 changeHandler={(rowIdx, columnId, values) => {
                                     let l_allocated_units = Number(instance.row.original['allocated_units']) - Number(instance.data[rowIdx][columnId]) + Number(values)
                                     let l_available_units = props.max_size_mapping?.[instance?.row?.original?.dc]?.[val] - Number(values)
                                     let l_available_qty =  Number(instance.data[rowIdx+1]['units_available']) - Number(instance.data[rowIdx+1][columnId]) + l_available_units
                                     let dc = instance?.row?.original?.dc || ""
                                     instance.updateMyData(rowIdx, 'allocated_units', l_allocated_units);
                                     instance.updateMyData(rowIdx+1, val, l_available_units);
                                     instance.updateMyData(rowIdx+1, 'units_available', l_available_qty);
                                     setRequest((old) => {
                                        return {
                                            ...old,
                                            "updated_eaches" : {
                                                ...old?.updated_eaches,
                                                [dc]: {...old?.updated_eaches?.[dc], [columnId] : Number(values)}, 
                                            },
                                            "available_eaches" : {
                                                ...old.available_eaches,
                                                [dc] : {...old?.available_eaches?.[dc], [columnId] : l_available_units}, 
                                            } 
                                        }
                                    })                                 
                                }} />
                             ) : (
                                 <div>
                                     {instance.row?.original?.[val]}
                                 </div>
                             )
                          ),
                          disableSortBy: true,
                    }
                )
            })
            let finalColumns = [...columns,...columnTemplate]
            setColumns(finalColumns)
        }
    },[props.packColumns, props.packData, props.max_size_mapping])

    return (
        <Modal
            visible={props.showAlert}
            width="80%"
            // height="90%"
            effect="fadeInDown"
            onClickAway={closeAlert}
            alignItems="flexStart"
        >
            {/* <Notification /> */}
            <div className="modal-container">
                <div className="modal-header">
                    <h5 className="page-heading text-center m-0">
                        Edit Allocated Units
                    </h5>
                    <button type="button" className="close" data-dismiss="modal">
                        <span style={{ cursor: "pointer" }}>
                            <i
                                className="fa fa-times text-right m-2 "
                                aria-hidden="true"
                                onClick={closeAlert}
                            />
                        </span>
                    </button>
                </div>
                <div className="modal-middle-container">
                    <div className="mx-2 pr-4 pl-4 row justify-content-center mb-4">
                        <div className="detail__div mr-4">
                            <span className="fnt-bold mr-2">Store :</span>
                            <span>{props.data.store_code}</span>
                        </div>

                        <div className="detail__div mr-4">
                            <span className="fnt-bold mr-2">{updateArticleName(false)} : </span>
                            <span>{props.data.article}</span>
                        </div>
                    </div>
                    <div className="mx-2 p-2 row justify-content-center">
                        <PageLoader loader={props.loading} gridLoader={true}>
                            {(props.packError) ?  (<div className="error">Something Went Wrong!!</div>) :
                            <ReactTableWithPlugins
                                hideColumnsFilter
                                getInstanceOnMount={(instance) => {
                                    setRTInstance(instance)
                                }}
                                style={{maxWidth:'100%', margin:'auto', display: "flex", justifyContent: "center"}}
                                data={data}
                                columns={columns}
                                loading={props.loading}
                                renderMarkup='TableMarkup'
                                // shouldPagination
                                tableId="edit_packs"
                                />}
                        </PageLoader>                    
                    </div>
                </div>
                {/* <div className="modal-footer" style={{ padding: "10px" }}> */}
                    <div className="text-center">
                        <button
                            style={{ margin: '2.5rem 1.5rem 1.5rem' }}
                            onClick={onSave}
                            className="btn btn-primary"
                        // disabled={isDisabled}
                        >
                            Save
                        </button>

                    </div>
                {/* </div> */}
            </div>
        </Modal>
    )
}

const mapStateToProps = ({ finalize }) => {
    return {
        packData: finalize.packData,
        packColumns: finalize.packColumns,
        max_size_mapping: finalize.max_size_mapping,
        loading: finalize.packLoading,
        packError: finalize.packError,
        editAllocatedUnitSuccess: finalize.editAllocatedUnitSuccess,
        editAllocatedUnitError: finalize.editAllocatedUnitError,
        dcAvailableError: finalize.dcAvailableError
    }
}

const mapDispatchToProps = (dispatch) => ({
    editAllocatedUnits: (reqBody) => dispatch(editAllocatedUnits(reqBody)),
    setDcAvailableError: (payload) => dispatch(setDcAvailableError(payload)),
    resetEditAllocationError: () => dispatch(resetEditAllocationError())
})

export default connect(mapStateToProps, mapDispatchToProps)(EditPackDetails)