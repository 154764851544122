import React, { useState } from "react";
import { useHistory } from "react-router";
import ContractPOAllocationTable from "./ContractPOAllocationTable";
import Modal from "react-awesome-modal";
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import {
  FINALIZE_PAGE,
  STRATEGY_PAGE,
} from "../../../constants/routeConstants";
import { POS_VALUES } from "../RecommendedAction/constantRecommendedAction";
import { isEmpty } from "lodash";

const StylesPopup = (props) => {
  const {
    showModal,
    closeModal,
    poData,
    rowData,
    selectedFilters,
    getCreateAllocationData,
  } = props;
  const history = useHistory();
  const pos = rowData?.pos;
  const buttonLabel =
    pos === POS_VALUES.AUTO_APPROVE_RELEASE_CONTRACT_PO ? "Review" : "Allocate";

  const [tableInstance, setTableInstance] = useState({});

  const processAutoApprovalContract = () => {
    // For Auto Approve contract send the flow to Finalize page.
    if (tableInstance?.selectedFlatRows.length) {
      const { po_id } = tableInstance?.selectedFlatRows[0].original;
      let articles = tableInstance?.selectedFlatRows.map(
        (row) => row.original.article
      );

      history.push(FINALIZE_PAGE, {
        planDetails: {
          isPOASNFlow: true,
          contractPO: true,
          articles,
          filters: selectedFilters,
          allocationCode: poData?.allocation_code,
          po_number: po_id,
          allocationName: poData?.allocation_name,
        },
      });
    } else {
      Notify.error("Please select atleast one article to Review!!");
    }
  };

  const processOpenContract = () => {
    // For Open contract send the flow to Strategy page.
    if (tableInstance?.selectedFlatRows?.length) {
      const { po_id } = tableInstance?.selectedFlatRows[0].original;
      const articles = tableInstance?.selectedFlatRows.map(
        (row) => row.original.article
      );
      let request = {};
      const l1_data = selectedFilters?.l1_name?.map((val) => val.value);
      if (!isEmpty(selectedFilters)) {
        request["article"] = articles;
        request["po_id"] = [po_id];
        request["l1_name"] = l1_data;
        request["styleIndex"] = 0;
        request["inventory_source"] = "po";
      }

      getCreateAllocationData(request);
      history.push(STRATEGY_PAGE, {
        styles: articles,
        isPOASNFlow: true,
        contractPO: true,
        poDetails: { po_id, inventory_source: "PO", status: "Pending" },
        filters: selectedFilters,
      });
    } else {
      Notify.error("Please select atleast one article to continue!!");
    }
  };

  const processContract = () => {
    if (pos === POS_VALUES.AUTO_APPROVE_RELEASE_CONTRACT_PO) {
      processAutoApprovalContract();
    } else if (pos === POS_VALUES.OPEN_CONTRACT_PO || pos === POS_VALUES.ABOUT_TO_EXPIRE_CONTRACT_PO) {
      processOpenContract();
    }
  };

  return (
    <div className="modal__div modal_opacity">
      <Modal
        visible={showModal}
        width="80%"
        effect="fadeInDown"
        onClickAway={closeModal}
      >
        {/* <Notification /> */}
        <div className="modal-container">
          <div className="modal-header">
            <div className="row w-100">
              {process.env.REACT_APP_CLIENT == "ashley" && (
                <h5 className="page-heading text-center m-0">
                  PO ID: {poData.po}
                </h5>
              )}
            </div>
            <button type="button" className="close" data-dismiss="modal">
              <span style={{ cursor: "pointer" }}>
                <i
                  className="fa fa-times text-right m-2 "
                  aria-hidden="true"
                  onClick={closeModal}
                />
              </span>
            </button>
          </div>
          <div className="modal-middle-container modal-body">
            <div className="mx-2 pr-4 pl-4 row justify-content-center mb-4">
              {/* <div className="detail__div mr-4">
                <span className="fnt-bold mr-2">Allocation Name :</span>
                <span>{poData.allocation_name}</span>
              </div> */}
            </div>
            <div className="mx-2 p-2">
              <ContractPOAllocationTable
                isPopup={true}
                data={poData.data}
                setTableInstance={setTableInstance}
                PO={poData.po}
                rowData={rowData}
                selectedFilters={selectedFilters}
                getCreateAllocationData={getCreateAllocationData}
              />
            </div>
          </div>

          <div className="text-center" style={{ padding: "1rem" }}>
            <div className="center">
              <button
                className="btn btn-primary store-grp-modal__btn"
                onClick={closeModal}
              >
                Close
              </button>

              <button
                className="btn btn-primary fn__btn"
                onClick={processContract}
              >
                {buttonLabel}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StylesPopup;
