import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import * as lostSalesActionsTypes from '../actions/actionTypes';
import {
    getLostSalesFilterData, getLostSalesReportAll,
    getLostSalesReportDetailsTable
} from "../../../../routes/api";
import { updateArticleName } from "../../../../utils/filterLevelMapping";
import { addDollar } from "../../../../utils/formatters/valueFormatters";
import { flattenArray } from "../../../../utils/commonUtilities";

const g_get_table_mapping = {
    "level1": "l1_name",
    "level2": "l2_name",
    "level3": "l3_name",
    "level4": "l4_name",
    "level5": "l5_name",
    "level6":"l6_name",
    "level7":"l7_name",
    "year":"year",
    "style":"style_name",
    "color":"color",
    "article":"article",
    "store":"store_code",
    "week": "week",
  }

/**
 * Sagas to fetch Lost Sales Filters Data
 * @param {*} action 
 */
function* fetchLostSalesFilters(action) {
    const level_ln_map = {
        "level1": "l1_name",
        "level2": "l2_name",
        "level3": "l3_name",
        "level4": "l4_name",
        "level5": "l5_name",
        "level6":"l6_name",
        // "level7":"l7_name",
        "article":"article",
        "style":"style_name",
        "store":"store_code",
        "color":"color",
      }
    try {
        const { payload, filterType } = action;
        let req = {};
        if (payload) {
            for (const key in payload) {
                if (payload[key] && payload[key].length > 0)
                    // req[key] = payload[key]?.map((ele) => ele.value);
                payload[key] && (req[level_ln_map[key]] = flattenArray(payload[key]).map((ele) => ele.value))
            }
        }

        // if (filterType) {
        //     req["filter_type"] = filterType;
        // }

        const res = yield call(getLostSalesFilterData, req);

        if (res.status) {
            const data = {};
            data["filterMapping"] = res.data?.filterMapping;
            // let topObject = Object.keys(res.data.data[0])[0];
            for (const key in res.data.data[0]) {
                let k = "";
                if (key === "l1_name") {
                    k = "departmentOptions";
                } else if (key === "l2_name") {
                    k = "genderOptions";
                } else if (key === "l3_name") {
                    k = "subCatOptions";
                } else if (key === "l4_name") {
                    k = "dcsOptions";
                } else if (key === "l5_name") {
                    k = "level5Options";
                } else if (key === "l6_name") {
                    k = "level6Options";
                } else if (key === "l7_name") {
                    k = "level7Options";
                } else if (key === "style_name") {
                    k = "styleOptions";
                } else if (key === "color") {
                    k = "colorOptions";
                } else if (key === "article") {
                    k = "articleIdOptions";
                } else if (key === "store_code") {
                    k = "storeIdOptions";
                }

                data[k] = res.data.data[0][key]?.filter(val => val).map((element) => ({
                    value: element,
                    label: element,
                }));
            }

            let payload = {
                data: data,
                key: filterType,
            };

            yield put({ type: lostSalesActionsTypes.GET_LOST_SALES_FILTER_SUCCESS, payload });
        }
    } catch (error) {
        yield put({ type: lostSalesActionsTypes.GET_LOST_SALES_FILTER_FAILED, error: "Something went wrong!" });
    }
}

/**
 * Sagas to fetch Lost Sales Table Details Data
 * @param {*} action 
 */
function* fetchLostSalesReportDetailsData(action) {
    const { payload } = action;

    const req = {}

    try {

        if (payload) {
            for (const key in payload) {
                if (payload[key] && payload[key].length > 0) {
                    payload[key] && (req[g_get_table_mapping[key]] = payload[key])
                }
            }
        }

        req['row_index'] = payload['row_index']
        req['row_count'] = payload['row_count']
        req["searchColumns"] = payload["searchColumns"]
        req["sortColumn"] = payload["sortColumn"]

        const res = yield call(getLostSalesReportDetailsTable, req);
        if (res.data.status) {
            const { totalCount, row_index } = res.data

            let response = res.data;
            response['total_lost_units'] = res.data?.total_lost_units;
            response['total_lost_revenue'] = res.data?.total_lost_sales;
            response['excel_data'] = res.data.lost_sales_details.map(detail => ({
                "Store": detail.store,
                "Store Name": detail.store_name,
                "Fiscal Week": detail.fiscal_week,
                "Fiscal Year": detail.year,
                [`${updateArticleName(false)} ID`]: detail.article,
                "Color": detail.color,
                "Style Color": detail.style_color,
                "Units Sold": detail.units,
                "Avg Store Cluster Units": detail.cluster_avg_sales,
                "Opening Inventory": detail.opening_inventory,
                "Lost Opportunity (Units)": detail.lost_units,
                "Lost Opportunity (Revenue)": detail.lost_sales,
                "Style Description": detail.style_name,
            }));
            response["totalCount"] = totalCount
            response["row_index"] = row_index

            yield put({ type: lostSalesActionsTypes.GET_LOST_SALES_REPORT_DETAILS_SUCCESS, response });
        }
    } catch (error) {
        yield put(({ type: lostSalesActionsTypes.GET_LOST_SALES_REPORT_DETAILS_FAILED, error: "Something went wrong!" }));
    }
}

/**
 * Sagas to fetch Lost Sales All Data
 * @param {*} action 
 */
function* fetchLostSalesReportAllData(action) {
    const { payload } = action;
    try {
        let req = {};
        if (payload) {
            for (const key in payload) {
                if (payload[key] && payload[key].length > 0)
                    // req[key] = payload[key]?.map((ele) => ele.value);
                 payload[key] && (req[g_get_table_mapping[key]] = payload[key])
            }
        }
        req['row_index'] = payload['row_index']
        req['row_count'] = payload['row_count']

        const res = yield call(getLostSalesReportAll, req);
        if (res.data.status) {
            let response = res.data;
            response['total_lost_units'] = res.data?.total_lost_units;
            response['total_lost_revenue'] = res.data?.total_lost_sales;
            response['fiscal_weeks_data'] = res.data.weeks_available.sort((one, two) => two.year - one.year).map((element) => ({
                value: element.week,
                label: `${element.week} (${element.year})`,
            }));
            response['latest_week_option'] =  response['fiscal_weeks_data'].length > 0 ? response['fiscal_weeks_data'][0]: null

            response["totalCount"] = res?.data?.totalCount

            yield put({ type: lostSalesActionsTypes.GET_LOST_SALES_REPORT_ALL_SUCCESS, response });
        }
    } catch (error) {
        yield put(({ type: lostSalesActionsTypes.GET_LOST_SALES_REPORT_ALL_FAILED, error: "Something went wrong!" }));
    }
}

    function* generateExcelWorker(action) {
        try {
            const { payload } = action;
            let req = {}
            
            for (const key in payload) {
                if (payload[key] && payload[key].length > 0) {
                    payload[key] && (req[g_get_table_mapping[key]] = payload[key])
                }
            }
    
            req["row_index"] = payload.row_index
            req["row_count"] = payload.row_count
            req["searchColumns"] = payload["searchColumns"]
            req["sortColumn"] = payload["sortColumn"]
            
            const res = yield call(getLostSalesReportDetailsTable, req);
            if (res.data.status) {
                const { totalCount } = res.data
                yield put({ type: lostSalesActionsTypes.GENERATE_EXCEL_SUCCESS, excel_data: res?.data?.lost_sales_details, totalCount, nextIndex: res.data.row_index } );
            }
            else {
                yield put({ type: lostSalesActionsTypes.GENERATE_EXCEL_ERROR, error: "Something went wrong!" });
            }
        }
        catch (error) {
            yield put({ type: lostSalesActionsTypes.GENERATE_EXCEL_ERROR, error: "Something went wrong!" });
        }
    }

/**
 * Watcher for getting lost sales filters
 */
function* watchLostSalesFiltersRequest() {
    yield takeEvery(lostSalesActionsTypes.GET_LOST_SALES_FILTER_REQUEST, fetchLostSalesFilters);
}

/**
 * Watcher for getting latest lost sales table details
 */
function* watchLostSalesReportTableDetailsRequest() {
    yield takeLatest(lostSalesActionsTypes.GET_LOST_SALES_REPORT_DETAILS_REQUEST, fetchLostSalesReportDetailsData);
}

/**
 * Watcher for getting latest lost sales all data
 * 
 */
function* watchLostSalesReportAllRequest() {
    yield takeLatest(lostSalesActionsTypes.GET_LOST_SALES_REPORT_ALL_REQUEST, fetchLostSalesReportAllData);
}

function* generateExcelWatcher() {
    yield takeLatest(lostSalesActionsTypes.GENERATE_EXCEL, generateExcelWorker)
}

/**
 * Root saga
 */
export function* lostSalesReportSagas() {
    yield all([
        watchLostSalesReportAllRequest(),
        watchLostSalesReportTableDetailsRequest(),
        watchLostSalesFiltersRequest(),
        generateExcelWatcher()
    ]);
}