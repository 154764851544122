import { cloneDeep } from 'lodash';
import React, { useState, useEffect } from 'react';
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

export default function AvailableTable(props) {
  const { data, columns, buttonLabel, is_eaches, is_pack } = props;
  
  const[packConfigData, setPackConfigData] = useState([]);
  const[eachesData, setEachesData] = useState([]);
  const[availableColumns, setAvailableColumns] = useState([]);

  useEffect(() => {
    if (data?.length) {
      //To fetch the eaches data from the array which containts both packs and eaches records
      let allColumns = cloneDeep(columns);
      if (is_eaches && is_pack) {
        setEachesData(data.slice(0, data.length - 1));
        setPackConfigData([data[data.length - 1]]);
      }
      //Setting the entire array when it's just the eaches case
      else if (is_eaches) {
        setEachesData(data);
      }
      //Setting the packs data
      else {
        allColumns.splice(1, 1);
        setPackConfigData([data[data.length - 1]]);
        setEachesData(data.slice(0, data.length - 1));
      }
      setAvailableColumns(allColumns);
    }
  }, [data]);

  return (
    <div style={{ margin: "3rem 0" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h1 className="fnt-md fnt-bold">Available</h1>
      </div>
      <ReactTableWithPlugins
        style={{
          minWidth: "auto",
          maxWidth: "fit-content",
          margin: "auto",
        }}
        hideColumnsFilter
        data={eachesData.length ? eachesData : []}
        columns={availableColumns.length ? availableColumns : []}
        renderMarkup="TableMarkup"
      />
        
      {is_pack && (
        <ReactTableWithPlugins
          style={{
            minWidth: "auto",
            maxWidth: "fit-content",
            margin: "auto",
            marginTop: "3rem"
          }}
          hideColumnsFilter
          data={packConfigData?.length ? packConfigData : []}
          columns={columns.length ? [{...columns[1], Header: "Pack Configuration"}] : []}
          renderMarkup="TableMarkup"
        />
      )}
    </div>
  );
}
