import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { lostSalesInitialState } from './initialState';
import { cloneDeep } from 'lodash';
import * as lostSalesActionTypes from "../actions/actionTypes";

const lostSalesReportReducer = {
    lostSalesReport: handleActions(
        {
            [lostSalesActionTypes.GET_LOST_SALES_REPORT_ALL_REQUEST]: (state) => {
                return immutable(state, {
                    isLostSalesAllDataLoading: { $set: true },
                    isLostSalesAllDataFailed: { $set: false },
                    isLostSalesAllDataSuccess: { $set: false },
                    nextIndex: {$set: 0},
                    totalCount: { $set: 0 },
                });
            },
            [lostSalesActionTypes.GET_LOST_SALES_REPORT_ALL_FAILED]: (state) => {
                return immutable(state, {
                    isLostSalesAllDataLoading: { $set: false },
                    isLostSalesAllDataFailed: { $set: true },
                    isLostSalesAllDataSuccess: { $set: false },
                })
            },
            [lostSalesActionTypes.GET_LOST_SALES_REPORT_ALL_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    isLostSalesAllDataLoading: { $set: false },
                    isLostSalesAllDataFailed: { $set: false },
                    isLostSalesAllDataSuccess: { $set: true },
                    lostSalesRevenueValue: { $set: response.total_lost_revenue },
                    lostSalesDetailsTableData: { $set: response.lost_sales_details },
                    lostSalesGraphData: { $set: response.lost_sales_graph },
                    lostSalesUnitsValue: { $set: response.total_lost_units },
                    fiscalWeekOptions: { $set: response.fiscal_weeks_data },
                    latestWeek: { $set: response.latest_week_option },
                    lostSalesExcelData: { $set: response.excel_data },
                    nextIndex: {$set: response.row_index},
                    totalCount: {$set: response.totalCount}
                });
            },
            [lostSalesActionTypes.RESET_LOST_SALES_REPORT]: (state) => {
                return immutable(state, {
                    isLostSalesAllDataLoading: { $set: false },
                    isLostSalesAllDataFailed: { $set: false },
                    isLostSalesAllDataSuccess: { $set: false },
                    lostSalesRevenueValue: { $set: null },
                    lostSalesDetailsTableData: { $set: [] },
                    lostSalesGraphData: { $set: [] },
                    fiscalWeekOptions: { $set: [] },
                    lostSalesUnitsValue: { $set: null },
                    sLostSalesFilterLoading: { $set: false },
                    isLostSalesFilterFailed: { $set: false },
                    lostSalesRequestBody: { $set: {} },
                    latestWeek: { $set: null },
                    lostSalesExcelData: { $set: [] },
                });
            },
            [lostSalesActionTypes.GET_LOST_SALES_REPORT_DETAILS_REQUEST]: (state) => {
                return immutable(state, {
                    isLostSalesDetailsDataLoading: { $set: true },
                    isLostSalesDetailsDataFailed: { $set: false },
                    isLostSalesDetailsDataSuccess: { $set: false },
                    nextIndex: { $set: 0 },
                    totalCount: { $set: 0 },
                });
            },
            [lostSalesActionTypes.GET_LOST_SALES_REPORT_DETAILS_FAILED]: (state) => {
                return immutable(state, {
                    isLostSalesDetailsDataLoading: { $set: false },
                    isLostSalesDetailsDataFailed: { $set: true },
                    isLostSalesDetailsDataSuccess: { $set: false },
                })
            },
            [lostSalesActionTypes.GET_LOST_SALES_REPORT_DETAILS_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    isLostSalesDetailsDataLoading: { $set: false },
                    isLostSalesDetailsDataFailed: { $set: false },
                    isLostSalesDetailsDataSuccess: { $set: true },
                    lostSalesRevenueValue: { $set: response.total_lost_revenue },
                    lostSalesDetailsTableData: { $set: response.lost_sales_details },
                    lostSalesUnitsValue: { $set: response.total_lost_units },
                    lostSalesExcelData: { $set: response.excel_data },
                    nextIndex: {$set: response.row_index},
                    totalCount: {$set: response.totalCount}
                });
            },
            [lostSalesActionTypes.GET_LOST_SALES_FILTER_REQUEST]: (state) => {
                return immutable(state, {
                    isLostSalesFilterLoading: { $set: true },
                    isLostSalesFilterFailed: { $set: false },
                })
            },
            [lostSalesActionTypes.GET_LOST_SALES_FILTER_SUCCESS]: (state, { payload }) => {
                switch (payload.key) {
                    case "department":
                        return immutable(state, {
                            departmentOptions: {
                                $set:
                                    payload && payload.data.departmentOptions
                                        ? payload.data.departmentOptions
                                        : state.departmentOptions,
                            },
                            genderOptions: [],
                            subCatOptions: [],
                            dcsOptions: [],
                            level5Options: [],
                            level6Options: [],
                            level7Options: [],
                            styleOptions: [],
                            colorOptions: [],
                            articleIdOptions: [],
                            storeIdOptions: [],
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "gender":
                        return immutable(state, {
                            genderOptions: {
                                $set:
                                    payload && payload.data.genderOptions
                                        ? payload.data.genderOptions
                                        : state.genderOptions,
                            },
                            subCatOptions: {
                                $set:
                                    payload && payload.data.subCatOptions
                                        ? payload.data.subCatOptions
                                        : state.subCatOptions,
                            },
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeIdOptions: {
                                $set:
                                    payload && payload.data.storeIdOptions
                                        ? payload.data.storeIdOptions
                                        : state.storeIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "sub_category":
                        return immutable(state, {
                            subCatOptions: {
                                $set:
                                    payload && payload.data.subCatOptions
                                        ? payload.data.subCatOptions
                                        : state.subCatOptions,
                            },
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeIdOptions: {
                                $set:
                                    payload && payload.data.storeIdOptions
                                        ? payload.data.storeIdOptions
                                        : state.storeIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "dcs":
                        return immutable(state, {
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeIdOptions: {
                                $set:
                                    payload && payload.data.storeIdOptions
                                        ? payload.data.storeIdOptions
                                        : state.storeIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                        case "l10":
                            return immutable(state, {
                                genderOptions: {
                                    $set:
                                        payload && payload.data.genderOptions
                                            ? payload.data.genderOptions
                                            : state.genderOptions,
                                },
                                filterlabels: {
                                    $set:
                                        payload && payload.data.filterMapping
                                            ? payload.data.filterMapping
                                            : state.filterMapping
                                }
                            });
                        case "l11":
                            return immutable(state, {
                                subCatOptions: {
                                    $set:
                                        payload && payload.data.subCatOptions
                                            ? payload.data.subCatOptions
                                            : state.subCatOptions,
                                },
                                filterlabels: {
                                    $set:
                                        payload && payload.data.filterMapping
                                            ? payload.data.filterMapping
                                            : state.filterMapping
                                }
                            });
                        case "level5":
                        return immutable(state, {
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeIdOptions: {
                                $set:
                                    payload && payload.data.storeIdOptions
                                        ? payload.data.storeIdOptions
                                        : state.storeIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "level6":
                        return immutable(state, {
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeIdOptions: {
                                $set:
                                    payload && payload.data.storeIdOptions
                                        ? payload.data.storeIdOptions
                                        : state.storeIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "level7":
                        return immutable(state, {
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeIdOptions: {
                                $set:
                                    payload && payload.data.storeIdOptions
                                        ? payload.data.storeIdOptions
                                        : state.storeIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "style":
                        return immutable(state, {
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeIdOptions: {
                                $set:
                                    payload && payload.data.storeIdOptions
                                        ? payload.data.storeIdOptions
                                        : state.storeIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "color":
                        return immutable(state, {
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeIdOptions: {
                                $set:
                                    payload && payload.data.storeIdOptions
                                        ? payload.data.storeIdOptions
                                        : state.storeIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "article":
                        return immutable(state, {
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeIdOptions: {
                                $set:
                                    payload && payload.data.storeIdOptions
                                        ? payload.data.storeIdOptions
                                        : state.storeIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "store":
                        return immutable(state, {
                            storeIdOptions: {
                                $set:
                                    payload && payload.data.storeIdOptions
                                        ? payload.data.storeIdOptions
                                        : state.storeIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    default:
                        return immutable(state, {
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                };
            },
            [lostSalesActionTypes.GET_LOST_SALES_FILTER_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isLostSalesFilterLoading: { $set: false },
                    isLostSalesFilterFailed: { $set: true },
                })
            },
            [lostSalesActionTypes.SET_LOST_SALES_REQ_BODY]: (state, { payload }) => {
                return immutable(state, {
                    lostSalesRequestBody: { $set: payload },
                });
            },
            [lostSalesActionTypes.GENERATE_EXCEL]: (state, { payload }) => {
                return immutable(state, {
                    // tableDataLoading: { $set: true },
                    lostSalesExcelData: { $set: [] },
                    downloadExcelError: { $set: null },
                    downloadLoading: { $set: true }
                });
            },
            [lostSalesActionTypes.GENERATE_EXCEL_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    // tableDataLoading: { $set: false },
                    lostSalesExcelData: { $set: (payload && payload.excel_data) || [] },
                    tableDataSuccess: { $set: true },
                    downloadNextIndex: { $set: (payload && state.downloadLoading && payload.nextIndex) || 0 },
                    downloadTotalCount: { $set: (payload && state.downloadLoading && payload.totalCount) || 0 },
                    downloadExcelError: { $set: null }
                });
            },
            [lostSalesActionTypes.GENERATE_EXCEL_ERROR]: (state, payload) => {
                return immutable(state, {
                    lostSalesExcelData: { $set: [] },
                    downloadNextIndex: { $set: 0 },
                    downloadTotalCount: { $set: 0 },
                    downloadExcelError: { $set: payload.error }
                });
            },
            [lostSalesActionTypes.RESET_LOST_SALES_FILTER]: (state) => {
                return immutable(state, {
                    departmentOptions: { $set: [] },
                    genderOptions: { $set: [] },
                    subCatOptions: { $set: [] },
                    dcsOptions: { $set: [] },
                    level5Options: { $set: [] },
                    level6Options: { $set: [] },
                    level7Options: { $set: [] },
                    styleOptions: { $set: [] },
                    colorOptions: { $set: [] },
                    articleIdOptions: { $set: [] },
                    storeIdOptions: { $set: [] },
                    filterLabels: { $set: {} },
                    downloadNextIndex: {$set: 0},
                    downloadTotalCount: {$set: 0},
                    downloadExcelError: {$set: null},
                    downloadLoading: {$set: false},
                    lostSalesExcelData: {$set: []}
                });
            },
            [lostSalesActionTypes.RESET_DOWNLOAD_EXCEL]: (state, { payload }) => {
                return immutable(state, {
                  lostSalesExcelData: { $set: [] },
                  downloadNextIndex: { $set: 0 },
                  downloadTotalCount: { $set: 0 },
                  downloadExcelError: { $set: null },
                });
            },
        },
        lostSalesInitialState
    ),
};

export default lostSalesReportReducer;