import { takeLatest, put, call, all } from "redux-saga/effects";
import * as actionTypes from "./ContractPOAllocationAction/actionTypes";
import {
  getAutoApprovalDetails,
  getContractPODetails,
  getPOASNDetails,
  getAutoApprovalReleaseContractPoDetails
} from "../../../routes/api";
import { dateFormat } from "../../../utils/commonUtilities";
import { cloneDeep } from "lodash";

function* getContractPOAllocationDataWorker(action) {
  try {
    const { payload } = action;
    const res = yield call(getContractPODetails, payload);
    let { data, message, status } = res?.data;

    if (status) {
      let poData = cloneDeep(data);
      // for (let index = 0; index < poData.length; index++) {
      //     poData[index].inventory_date = dateFormat(poData[index].inventory_date)
      // }
      yield put({
        type: actionTypes.GET_CONTRACT_PO_DATA_SUCCESS,
        data: poData,
      });
    } else {
      yield put({
        type: actionTypes.GET_CONTRACT_PO_DATA_ERROR,
        error: message ? message : "Something went wrong!",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_CONTRACT_PO_DATA_ERROR,
      error: "Something went wrong!",
    });
  }
}

function* getAutoApprovalReleaseContractPOAllocationDataWorker(action) {
  try {
    const { payload } = action;
    const res = yield call(getAutoApprovalReleaseContractPoDetails, payload);
    let { data, message, status } = res?.data;

    if (status) {
      let poData = cloneDeep(data);
      // for (let index = 0; index < poData.length; index++) {
      //     poData[index].inventory_date = dateFormat(poData[index].inventory_date)
      // }
      yield put({
        type: actionTypes.GET_CONTRACT_PO_DATA_SUCCESS,
        data: poData,
      });
    } else {
      yield put({
        type: actionTypes.GET_CONTRACT_PO_DATA_ERROR,
        error: message ? message : "Something went wrong!",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_CONTRACT_PO_DATA_ERROR,
      error: "Something went wrong!",
    });
  }
}

function* getPOAllocationDataWatcher() {
  yield takeLatest(
    actionTypes.GET_CONTRACT_PO_DATA,
    getContractPOAllocationDataWorker
  );
}

function* getAutoApprovalReleasePOAllocationDataWatcher() {
  yield takeLatest(
    actionTypes.GET_AUTO_APPROVAL_RELEASE_CONTRACT_PO_DATA,
    getAutoApprovalReleaseContractPOAllocationDataWorker
  );
}

export function* contractPOAllocationSaga() {
  yield all([getPOAllocationDataWatcher(),getAutoApprovalReleasePOAllocationDataWatcher()]);
}
