import * as actionTypes from "../ContractPOAllocationAction/actionTypes"
import { contractPOAllocationInitialState } from "./InitialState"
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export default {
    contractPOAllocation: handleActions(
        {
            [actionTypes.GET_CONTRACT_PO_DATA]: (state, payload) => {
                return immutable(state, {
                    contractPOAllocationData: { $set: [] },
                    contractPOAllocationDataLoading: { $set: true },
                    contractPOAllocationDataError: { $set: null },
                });
            },
            [actionTypes.GET_CONTRACT_PO_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    contractPOAllocationData: { $set: (payload && payload.data) || [] },
                    contractPOAllocationDataLoading: { $set: false },
                    contractPOAllocationDataError: { $set: null }
                });
            },
            [actionTypes.GET_CONTRACT_PO_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    contractPOAllocationData: { $set: [] },
                    contractPOAllocationDataLoading: { $set: false },
                    contractPOAllocationDataError: { $set: (payload && payload.error) || null }
                });
            },
            [actionTypes.GET_AUTO_APPROVAL_RELEASE_CONTRACT_PO_DATA]: (state, payload) => {
                return immutable(state, {
                    contractPOAllocationData: { $set: [] },
                    contractPOAllocationDataLoading: { $set: true },
                    contractPOAllocationDataError: { $set: null },
                });
            },
        },
        contractPOAllocationInitialState
    )
}
            