import React, { useState } from "react";
import Filter from "../../ProductProfile/ProductProfileTable/Filter";
import { handleSelectAll } from '../../../utils/SelectAllHandler/SelectAllHander'
import { useEffect } from "react";

const CountrySplitFilter = (props) => {

    const { applyFilters, dcOptions, dcCountryMap } = props;

    const [selectedColumn, setSelectedColumn] = useState(null);
    const [columnOptions, setColumnOptions] = useState([]);

    // const columnOptions = [{ label: "All", value: "agg" }, { label: "USA", value: "usa" },
    // { label: "CAN", value: "can" }, { label: "MEX", value: "mx" }]
    useEffect(() => {
        const options = (Array.isArray(dcOptions?.[0]) ? dcOptions[0] : dcOptions)?.map(item => {
            const label = dcCountryMap[item.value];
            return {
                label,
                value: item.value
            }
        });
        setColumnOptions(options);
        setSelectedColumn(null);
    }, [dcOptions]);

    const onChangeHandler = (val, event, that) => {
        let valuesArray = handleSelectAll(val, event, that)
        setSelectedColumn(valuesArray[1])
    }

    const filterObj = [{
        id: 1,
        label: "Select Country Split",
        name: "county-split",
        dropdownValue: selectedColumn,
        onDropDownChange: onChangeHandler,
        placeHolder: `Select`,
        optionsArray: columnOptions,
        isMultiSelect: true,
        className: "fill col-md-3 p-0",
        labelClass: "show_columns__label"
    }]

    const handleFilter = () => {
        applyFilters(selectedColumn)
    }

    return (
        <div className="col_selection country-split-filter">
            <div className="d-flex align-items-end">
                <Filter data={filterObj} />
                <div className="text-center">
                    <button disabled={!selectedColumn?.length} className="btn btn-primary px-2 ml-4" onClick={handleFilter}>
                        {/* {
                            showSaveLoader ?
                                <i class="fa fa-spinner fa-pulse fa-2x"></i>
                                : <span> */}
                        Apply
                        {/* </span>
                        } */}

                    </button>
                </div>
            </div>
        </div>
    );
}

export default CountrySplitFilter