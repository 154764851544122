import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
// import { DEMAND_HEADER } from "./DemandConstants"
import PageLoader from "../../../components/Loader/PageLoader";
import { STRATEGY_PAGE } from "../../../constants/routeConstants";
import { getPoData } from "./POAllocationAction/POAllocationAction";
import POAllocationTable from "./POAllocationTable";
import * as Notify from "../../../components/Notification/Notifications";
import { getStrategyTableDataFromDashboard } from "../../Strategy/actions";
import { isEmpty } from "lodash";

const POAllocation = (props) => {
  const {
    poAllocationDataLoading,
    poAllocationData,
    poAllocationDataError,
    getPoData,
    filters,
    getCreateAllocationData,
    type,
    status,
    storeFilters,
  } = props;

  const poRef = useRef();
  const history = useHistory();

  const [selectedFilters, setFilters] = useState({});
  const [tableInstance, setTableInstance] = useState({});

  useEffect(() => {
    const filterRequest = {
      l1_name: filters?.l1_name?.map((val) => val.value),
      l2_name: Array.isArray(filters?.l2_name?.[0])
        ? filters?.l2_name?.[0]?.map((val) => val.value)
        : filters?.l2_name?.map((val) => val.value),
      l3_name: Array.isArray(filters?.l3_name?.[0])
        ? filters?.l3_name?.[0]?.map((val) => val.value)
        : filters?.l3_name?.map((val) => val.value),
      l4_name: Array.isArray(filters?.l4_name?.[0])
        ? filters?.l4_name?.[0]?.map((val) => val.value)
        : filters?.l4_name?.map((val) => val.value),
      l5_name: Array.isArray(filters?.l5_name?.[0])
        ? filters?.l5_name?.[0]?.map((val) => val.value)
        : filters?.l5_name?.map((val) => val.value),
      l6_name: Array.isArray(filters?.l6_name?.[0])
        ? filters?.l6_name?.[0]?.map((val) => val.value)
        : filters?.l6_name?.map((val) => val.value),
      dc: Array.isArray(filters?.dc?.[0])
        ? filters.dc[0].map((val) => val.value)
        : filters.dc.map((val) => val.value),
      start_date: filters?.start_date && filters?.start_date[0],
      end_date: filters?.end_date && filters?.end_date[0],
      start_date_last_allocated: filters?.start_date_last_allocated && filters?.start_date_last_allocated[0],
      end_date_last_allocated: filters?.end_date_last_allocated && filters?.end_date_last_allocated[0],
      stock_status: storeFilters,
    };
    // filterRequest.inventory_source = (type === "RECEIPT" || type === "ERROR") ? "POASN" : type
    // filterRequest.status = status
    setFilters(filterRequest);
    setTimeout(() => {
      poRef?.current?.scrollIntoView();
      getPoData(filterRequest);
    }, 200);
  }, []);

  const allocatePO = () => {
    if (tableInstance?.selectedFlatRows?.length) {
      const { po_id, inventory_source, asn_id, status } =
        tableInstance?.selectedFlatRows[0].original;
      const articles = tableInstance?.selectedFlatRows[0].originalSubRows.map(
        (item) => item.article
      );
      let request = {};
      if (!isEmpty(selectedFilters)) {
        request.Attributes = {};
        request.Operators = { NonAttributeColumn: [] };
        for (const key in selectedFilters) {
          if (!isEmpty(selectedFilters[key])) {
            request[key] = selectedFilters[key];
            request.Attributes[key] = request[key];
            request.Attributes["style"] = articles;
            request.Operators.NonAttributeColumn.push(key);
          }
        }
      }
      request["dc"] = selectedFilters.dc;
      request["style"] = articles;
      request["po_asn_flow"] = true;
      request["po_id"] = [po_id];
      request["styleIndex"] = 0;
      if (asn_id) {
        request["asn_id"] = [asn_id];
      }
      request.Operators.NonAttributeColumn.push("style");
      getCreateAllocationData(request);
      history.push(STRATEGY_PAGE, {
        styles: articles,
        isPOASNFlow: true,
        filters,
        poDetails: { po_id, inventory_source, asn_id, status },
      });
    } else {
      Notify.error("Please select one PO to continue!!");
    }
  };

  return (
    <section className="section mr-4 mt-4" ref={poRef}>
      <PageLoader loader={poAllocationDataLoading} gridLoader={true}>
        {poAllocationDataError ? (
          <div className="error">{poAllocationDataError}</div>
        ) : poAllocationData ? (
          <POAllocationTable
            data={poAllocationData}
            setTableInstance={setTableInstance}
            type={type}
            status={status}
            toRelease={status === "Processed"}
            allocatePO={allocatePO}
            buttonLabel={
              status === "Mismatch" || status === "Error"
                ? "Re Allocate"
                : "Allocate"
            }
            // toRelease={type === "POASN"} allocatePO={allocatePO} buttonLabel={type === "RECEIPT" || type === "ERROR" ? "Re Allocate" : "Allocate"}
          />
        ) : null}
      </PageLoader>
    </section>
  );
};

const mapStateToProps = ({ poAllocation }) => {
  return {
    poAllocationDataLoading: poAllocation.poAllocationDataLoading,
    poAllocationData: poAllocation.poAllocationData,
    poAllocationDataError: poAllocation.poAllocationDataError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPoData: (payload) => dispatch(getPoData(payload)),
  getCreateAllocationData: (payload) =>
    dispatch(getStrategyTableDataFromDashboard(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(POAllocation);
