// import React from "react";
// import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
// import "./Filters.css";

// const MultiSelect = (props) => {
//   console.log("asfjasufn;as", props);
//   let dropdownValue = props.dropdownValue || {
//     value: props.placeHolder,
//     label: props.placeHolder,
//     options: [],
//   };
//   let dropdownDisabled =
//     !props.optionsArray || !props.optionsArray.length || props.isDisabled
//       ? true
//       : false;
//   console.log("asfdas", dropdownDisabled);
//   return (
//     <>
//       {
//         <ReactMultiSelectCheckboxes
//           placeholderButtonLabel={props.placeHolder}
//           placeHolder = {props.placeHolder}
//           options={props.optionsArray}
//           isDisabled={dropdownDisabled}
//           onChange={props.onDropDownChange}
//           onBlur={props.onBlur}
//           onFocus={props.onFocus}
//           onKeyDown={props.onKeyDown}
//           value={dropdownValue}
//           className={props.className || ""}
//         />
//       }
//     </>
//   );
// };

// export default MultiSelect;

import { isEmpty } from "lodash";
import React from "react";
import { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "./Filters.css";

const MultiSelect = (props) => {
  let dropdownValue = props.dropdownValue || {
    value: props.placeHolder,
    label: props.placeHolder,
    options: [],
  };
  let dropdownDisabled =
    !props.optionsArray || !props.optionsArray.length || props.isDisabled
      ? true
      : false;

  const [optionsArray, setOptionArray] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchArray, setSearchArray] = useState([])

  useEffect(() => {
    if(!props.optionsArray || !props.optionsArray.length || props.isDisabled){
      setOptionArray(props.optionsArray)
    }
  },[props.isDisabled,props.optionsArray])

  useEffect(()=> {
    if(isEmpty(props.optionsArray))
      setOptionArray(props.optionsArray)
    if(!isEmpty(props.dropdownValue))
      setSelectedOptions(props.dropdownValue)
    else
      setSelectedOptions([])
  },[
    props.optionsArray, 
    props.dropdownValue])

  let onFocus = () => {
    const { splitBy, isBulkSearch } = props;
    if(isBulkSearch) {
      let placeHolder = splitBy && splitBy !== " " ? `Search in bulk by '${splitBy}'` : "Search in bulk.."
      document.getElementById("bulk_search__input").setAttribute("placeholder", placeHolder)
    }
    if(props.dropdownValue?.length && props.optionsArray?.length){
      let optionsArray
      if(props.hideSelectAll){
        optionsArray = [...new Set([...props.dropdownValue,...props.optionsArray])]
      } 
      else{
        optionsArray = [...new Set([{ label: "Select All", value: "*" },...props.dropdownValue,...props.optionsArray])]
      }
      const uniqueObjects = [...new Map(optionsArray.map(item => [item.value, item])).values()]

      setOptionArray(uniqueObjects)
    }
    else{
      let optionsArray
      if(props.hideSelectAll){
        optionsArray = [...props.optionsArray]
      }
      else {
        optionsArray = [{ label: "Select All", value: "*" },...props.optionsArray]
      }
      setOptionArray(optionsArray)
    }
    if(props.dropdownValue?.length < props.optionsArray?.length) {
      let values = [...props.dropdownValue]
      if(values[0]?.value === "*") {
        values.shift()
      }
      setSelectedOptions(values)
    }
  }


  function onChange(value, event) {
    let options;
    if(searchArray?.length) {
      options = this.options.filter(option => searchArray.includes(option.label.toLowerCase()) || searchArray.includes(option.value.toLowerCase()))
      options = [{ label: "Select All", value: "*" },...options]
    }
    else {
      options = this.options
    }
    props.onDropDownChange(value,event, {...this, options})
  }

  const defaultFilter = (option, searchText) => {
    if (
      option.data.label?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.value?.toString().toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  const bulkSearchFilter = (option, searchText) => {
    let splitBy = props.splitBy ? props.splitBy: " "
    searchText = searchText?.length && searchText.split(splitBy).map((item) => item.toLowerCase().trim());
    setSearchArray(searchText?.length ? searchText : [])
    let allOptions = {labels: optionsArray.map(item => item.label.toLowerCase()), values: optionsArray.map(item => item.value.toLowerCase())}
    if (searchText?.length) {
      if (
        searchText.indexOf(option.data.label.toLowerCase()) >= 0 ||
        searchText.indexOf(option.data.value.toLowerCase()) >= 0 ) {
        return true;
      } 
      else if(option.data.value === "*") {
        if(allOptions.labels.filter(option => searchText.includes(option))?.length || allOptions.values.filter(option => searchText.includes(option))?.length) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      {
        <ReactMultiSelectCheckboxes
          placeholderButtonLabel={props.placeHolder}
          placeHolder = {props.placeHolder}
          // options={optionsArray?.length ? optionsArray : props?.optionsArray}
          options = {optionsArray}
          isDisabled={dropdownDisabled}
          onChange={onChange}
          onBlur={props.onBlur}
          onFocus={onFocus}
          onKeyDown={props.onKeyDown}
          value={selectedOptions}
          className={props.className || ""}
          setState={setSelectedOptions}
          filterOption={props.isBulkSearch ? bulkSearchFilter : defaultFilter}
          styles={{input: (provided, state) => ({
            ...provided,
            maxWidth: "200px"
          })}}
          classNamePrefix={props.isBulkSearch ? "bulk_search": ""}
          inputId={props.isBulkSearch ? "bulk_search__input" : ""}
        />
      }
    </>
  );
};

export default MultiSelect;


