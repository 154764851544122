import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PageLoader from "../../../../components/Loader/PageLoader";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import {
  getDailySummaryStoreView,
  getDailySummaryStoreDetails,
} from "./Actions/StoreViewActions";
import "../../Reporting.css";
import { STORE_VIEW_TABLE_HEADERS } from "./StoreViewConstants";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import NumberRangeColumnFilter from "../../../../components/Filters/NumberRangeColumnFilter";
import { numberUSFormatting } from "../../../../utils/formatters/valueFormatters";
import "../DailySummary.css";
import StyleColorTable from "../StyleColorTable";
import { isEmpty } from "lodash";
import SKUStoreLevelDetails from "../SKUStoreLevelDetails";
import Card from "../../../../components/Card/Card";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const StoreView = (props) => {
  const {
    getDailySummaryStores,
    storesData,
    storesLoading,
    storesDataError,
    getStoreDetails,
    storeDetails,
    storeDetailsLoading,
    storeDetailsError,
    selectedFilters,
    isStoreView,
  } = props;

  const [showStoreDetails, setShowDetails] = useState(false);
  const [clickedArticle, setClickedArticle] = useState({});
  const [selectedStore, setSelectedStore] = useState(null);
  const [req, setReq] = useState({});

  useEffect(() => {
    getDailySummaryStores({ request: selectedFilters });
  }, []);

  const storeColumns = React.useMemo(() => [
    {
      Header: STORE_VIEW_TABLE_HEADERS.STORE_NUMBER,
      accessor: "store",
      Filter: ColumnFilter,
      Cell: (instance) => (
        <div
          className="clickable"
          onClick={() => getStoreArticles(instance.row?.original?.store)}
        >
          {instance.row?.original?.store}
        </div>
      ),
    },
    {
      Header: STORE_VIEW_TABLE_HEADERS.STORE_NAME,
      accessor: "store_name",
      Filter: ColumnFilter,
    },
    {
      Header: STORE_VIEW_TABLE_HEADERS.UNITS_ALLOCATED,
      accessor: "units_allocated",
      Cell: (inst) => numberUSFormatting(inst?.value),
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: STORE_VIEW_TABLE_HEADERS.OH,
      accessor: "oh",
      Cell: (inst) => numberUSFormatting(inst?.value),
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: STORE_VIEW_TABLE_HEADERS.OO,
      accessor: "oo",
      Cell: (inst) => numberUSFormatting(inst?.value),
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
    {
      Header: STORE_VIEW_TABLE_HEADERS.IT,
      accessor: "it",
      Cell: (inst) => numberUSFormatting(inst?.value),
      Filter: NumberRangeColumnFilter,
      filter: "between",
    },
  ]);

  const getStoreArticles = (store_code) => {
    setClickedArticle({});
    setSelectedStore(store_code);
    setShowDetails(true);
    getStoreDetails({ ...selectedFilters, store: [{ value: store_code }] });
  };

  const getSizeDetails = (p_article) => {
    let l_req = {
      article: [{ value: p_article }],
      store: [{ value: selectedStore }],
      is_store_view: true,
    };
    setClickedArticle(l_req);
  };

  return (
    <div>
      <PageLoader loader={storesLoading} gridLoader={true}>
        {storesDataError ? (
          <div className="error">
            {storesDataError || "Something Went Wrong!!"}
          </div>
        ) : (
          <></>
        )}
        <div className="card__wrapper">
          {props?.cardData?.map((val, ind) => (
            <Card key={ind} title={val.title} value={val.value} />
          ))}
        </div>
        <ReactTableWithPlugins
          columns={storeColumns}
          data={storesData ? storesData : []}
          renderMarkup="TableMarkup"
          keyRT="sortAndSearch"
          shouldPagination
          tableId="dailySummary-storeView"
          hideColumnsFilter
          tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
          headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
        />
      </PageLoader>
      {showStoreDetails && (
        <PageLoader loader={storeDetailsLoading} gridLoader={true}>
          {storeDetailsError ? (
            <div className="error">
              {storeDetailsError || "Something Went Wrong!!"}
            </div>
          ) : (
            <>
              <div className="container__header m-0">
                <h3 className="fnt-bold">Store - {selectedStore}</h3>
              </div>
              <hr />
              <StyleColorTable
                data={storeDetails}
                getDetails={getSizeDetails}
                tableId={"daily-storeDetails"}
                isStoreView={isStoreView}
              />
            </>
          )}
        </PageLoader>
      )}
      <div>
        {!isEmpty(clickedArticle) ? (
          <>
            <div className="storesStore__heading mt-4">
              <h3 className="fnt-md fnt-bold mr-4">Store - {selectedStore}</h3>
              <h3 className="fnt-md fnt-bold ml-4">
                Style Color ID - {clickedArticle?.article[0]?.value}
              </h3>
              <span></span>
            </div>
            <hr />
            <SKUStoreLevelDetails
              req={{ ...selectedFilters, ...clickedArticle }}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({ dailyStoreView }) => ({
  storesData: dailyStoreView.storesData?.tableData,
  cardData: dailyStoreView.storesData?.cardData,
  storesLoading: dailyStoreView.storesDataLoading,
  storesError: dailyStoreView.storesDataError,
  storeDetails: dailyStoreView.storeDetails,
  storeDetailsError: dailyStoreView.storeDetailsError,
  storeDetailsLoading: dailyStoreView.storeDetailsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getDailySummaryStores: (payload) =>
    dispatch(getDailySummaryStoreView(payload)),
  getStoreDetails: (payload) => dispatch(getDailySummaryStoreDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreView);
