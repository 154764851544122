import React from "react";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import ReactTable from "../../components/Table/ReactTable";
import WithDynamicPlugins from "../../components/Table/WithPlugins";
import { addDollar, addPer, oneDecimal, numberUSFormatting } from "../../utils/formatters/valueFormatters";
import ColumnFilter from "../UserManagement/ColumnFilter";
import { updateArticleName } from "../../utils/filterLevelMapping"
import NumberRangeColumnFilter from "../../components/Filters/NumberRangeColumnFilter";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

export const DashboardModal = ({
  openStyleInvPopup,
  closeAlert,
  popupTitle,
  articleId,
  styleInvPopupData,
}) => {
  const styleInvPopupCols = [
    {
      Header: "Store Number",
      accessor: "store_credited",
      Filter:(instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
      filter: 'bulkFilterSpaceSeperated',
    },
    {
      Header: "Store Name",
      accessor: "location_name",
      width: 200,
      Filter: ColumnFilter
    },
    {
      Header: "On Hand",
      accessor: "oh", 
      Cell: (inst) => numberUSFormatting(inst?.value),
      Filter: NumberRangeColumnFilter,
      filter: 'between',

    },
    {
      Header: "On Order",
      accessor: "oo",
      Cell: (inst) => numberUSFormatting(inst?.value),
      Filter: NumberRangeColumnFilter,
      filter: 'between',

    },
    {
      Header: "In Transit",
      accessor: "it",
      Cell: (inst) => numberUSFormatting(inst?.value),
      Filter: NumberRangeColumnFilter,
      filter: 'between',

    },
    {
      Header: "WOS",
      accessor: "wos",
      Cell: (inst) => (
        <div>
          {inst.value || inst.value === 0 ? (inst.value) : null}
        </div>
      ),
      Filter: NumberRangeColumnFilter,
      filter: 'between',

    },
    {
      Header: "Size Integrity (On Hand)",
      accessor: "si",
      Cell: (inst) => (
          <div>
              {inst.value || inst.value === 0
                  ? addPer((inst.value))
                  : null}
          </div>
      ),
      Filter: NumberRangeColumnFilter,
      filter: 'between',

  },
    {
      Header: "LW Sales Units",
      accessor: "lw_qty",
      Cell: (inst) => (
          <div>
              {inst.value || inst.value === 0
                  ? (inst.value)
                  : null}
          </div>
      ),
      Filter: NumberRangeColumnFilter,
      filter: 'between',
      defaultCanSort: true

  },
    {
      Header: "LW Revenue",
      accessor: "lw_revenue",
      Cell: (inst) => (
        <div>
          {inst.value || inst.value === 0
            ? addDollar(numberUSFormatting(inst.value))
            : null}
        </div>
      ),
      Filter: NumberRangeColumnFilter,
      filter: 'between',

    },
    {
      Header: "LW Margin",
      accessor: "lw_margin",
      Cell: (inst) => (
        <div>
          {inst.value || inst.value === 0
            ? addDollar(numberUSFormatting(inst.value))
            : null}
        </div>
      ),
      Filter: NumberRangeColumnFilter,
      filter: 'between',
    },
    {
      Header: "WTD Sales Units",
      accessor: "tw_qty",
      Cell: (inst) => (
          <div>
              {inst.value || inst.value === 0
                  ? (inst.value)
                  : null}
          </div>
      ),
      Filter: NumberRangeColumnFilter,
      filter: 'between',
      defaultCanSort: true

  },
    {
      Header: "WTD Revenue",
      accessor: "tw_revenue",
      Cell: (inst) => (
        <div>
          {inst.value || inst.value === 0
            ? addDollar(numberUSFormatting(inst.value))
            : null}
        </div>
      ),
      Filter: NumberRangeColumnFilter,
      filter: 'between',

    },
    {
      Header: "WTD Margin",
      accessor: "tw_margin",
      Cell: (inst) => (
        <div>
          {inst.value || inst.value === 0
            ? addDollar(numberUSFormatting(inst.value))
            : null}
        </div>
      ),
      Filter: NumberRangeColumnFilter,
      filter: 'between',
    },
    
  ];
  return (
    <ModalComponent
      showAlert={openStyleInvPopup}
      closeAlert={closeAlert}
      //remove height, model will fit-content by default
      //height="60%"
      width="67%"
    >
      <div className="modal-container">
        <div className="modal-header">
          <div
            className="d-flex justify-content-between"
            style={{ width: "100%" }}
          >
            <div>
              <h5 className="page-heading m-0">{popupTitle}</h5>
            </div>
            <div style={{ fontSize: "1.6rem" }}>
              <span>{updateArticleName(false)} ID:</span>
              {articleId}
            </div>
            <div>
              <button type="button" className="close" data-dismiss="modal">
                <i
                  className="fa fa-times text-right m-2 "
                  aria-hidden="true"
                  onClick={closeAlert}
                  style={{ cursor: "pointer" }}
                />
              </button>
            </div>
          </div>
        </div>
        <div
          className="modal-middle-container modal-body"
          style={{ padding: "auto" }}
        >
          <ReactTableWithPlugins
            columns={styleInvPopupCols}
            data={styleInvPopupData}
            sortBy = {[{ id: 'lw_qty', desc: true }]}
            renderMarkup="TableMarkup"
            keyRT="sortAndSearch"
            shouldPagination
            totalRecordsLen={styleInvPopupData.length}
            tableId="dashboard_modal"
            tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
            headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
          />
        </div>
      </div>
    </ModalComponent>
  );
};
