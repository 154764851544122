import React from "react";

const PaginationMarkup = ({
  page,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  loadingRT,
  tabledata,
  // Get the state from the instance
  state: { pageIndex, pageSize },
  totalRecordsLen,
  fetchData,
  isDataLoading,
  containSubRow,
  globalFilteredFlatRows,
  flatRows,
  totalCount,
  isHideCount,
  hidebutton,
  hideTotalRecords,
  hideTotalPages,
}) => {

  if (
    // pageIndex ===
    //   parseInt(totalRecordsLen / pageSize) +
    //     (totalRecordsLen % pageSize !== 0 ? 1 : 0) -
    //     1 &&
    !canNextPage &&
    pageIndex !== 0 &&
    !isDataLoading &&
    fetchData
  ) {
    fetchData((pageIndex + 1) * pageSize);
  }

  

  return (
    <>
    <div className="pagination d-flex justify-content-between mt-3">
      
      <span>
        {
          !isHideCount &&
          <span >
            {" "}
            Records {pageSize * pageIndex + 1} to{" "}
            {pageCount > 1 ? pageSize * pageIndex + page.length : page.length} {hideTotalRecords ?  `of many` :
            `of ${totalCount || flatRows?.length}`}
          </span>
        }
      </span>
      <span>
        <span className="mr-2">
          Page {pageIndex + 1} {hideTotalPages  ? `of many` : `of ${(totalCount && Math.ceil(totalCount / pageSize)) || (pageOptions && pageOptions.length)}`}
        </span>
        <button
          className="btn pagination-btn rt-btn btn-primary"
          //onClick={() => navigation('first')}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage || !!loadingRT}
        >
          {"<<"}
        </button>{" "}
        <button
          className="btn pagination-btn rt-btn-prev  btn-primary"
          //onClick={() => navigation('prev')}
          onClick={() => previousPage()}
          disabled={!canPreviousPage || !!loadingRT}
        >
          {/* {'<'} */} Prev
        </button>{" "}
        {!!loadingRT && (
          <i className="fa fa-spinner fa-spin" ariaHidden="true"></i>
        )}{" "}
        <button
          className="btn pagination-btn rt-btn-next btn-primary"
          //onClick={() => navigation('next')}
          onClick={() => nextPage()}
          disabled={!canNextPage || !!loadingRT}
        >
          {/* {'>'} */}
          Next
        </button>{" "}
        <button
          className="btn pagination-btn rt-btn btn-primary"
          //onClick={() => navigation('last')}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage || !!loadingRT}
        >
          {">>"}
        </button>{" "}
      </span>
     
    </div>
    {((canPreviousPage || canNextPage) || ((totalCount || flatRows?.length) >= 10 && pageCount <= 1 ) ) && hidebutton==="No" && 
      <select
      //  style={{position:"absolute",zIndex:"100",top:"-5%"}}
      style={{backgroundColor:"rgb(104 132 148)",cursor:"pointer",color:"white",borderColor:"rgb(70 142 254)",borderRadius:"3px",minWidth:"8rem",textAlign:"center",height:"30px"}}
        value={pageSize}
        onChange={(e) => {setPageSize(Number(e.target.value))}}
      >
        {[10, 20, 30, 40, 50].map(page => (
          <option key={page} value={page}>
            Show {page}
          </option>
        ))}
      </select>
}
    </>
    
  );
};

export default PaginationMarkup;
